import { Grid, Tab, Tabs, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Layout from 'components/Layout'
import dayjs, { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import React, { useState } from 'react'

import InvestmentStats from './stats/InvestmentStats'
import RewardStats from './stats/RewardStats'
import UsersStats from './stats/UsersStats'

export default function Stats() {
	const defaultStartDate: Date = new Date(Date.now())
	defaultStartDate.setDate(defaultStartDate.getDate() - 31)
	const defaultEndDate: Date = new Date(Date.now())
	defaultEndDate.setDate(defaultEndDate.getDate() - 1)
	const [tabIndex, setTabIndex] = useState<number>(0)
	const [startDate, setStartDate] = useState<Dayjs>(dayjs(defaultStartDate))
	const [endDate, setEndDate] = useState<Dayjs>(dayjs(defaultEndDate))
	const handleTabChange = ((event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue)
	})

	function bottom() {
		switch (tabIndex) {
			case 0: return (<UsersStats startDate={startDate} endDate={endDate} />)
			case 1: return (<InvestmentStats startDate={startDate} endDate={endDate} />)
			case 2: return (<RewardStats startDate={startDate} endDate={endDate} />)
			default:
				return (<>
				</>)
		}
	}
	return <>
		<Layout id={'stats'}>
			<Grid>
				<div
					style={{
						height: 'calc(100vh - 170px)',
						width: '100%'
					}}>

					<div style={{
						backgroundColor: Colors.neutrals.lead200,
						// width: "100vw",
						height: '100vh',
					}}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} />
							<Grid item xs={12} sm={7} />
							<Grid item xs={12} sm={1} >
								<Typography variant="body1" >
									Range
								</Typography>
							</Grid>
							<Grid container xs={12} sm={2} alignItems="center" justifyContent="center">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Start Date"
										views={['month', 'year', 'day']}
										value={startDate}
										onChange={(newValue: any) => {
											setStartDate(newValue)
										}}
										maxDate={endDate}
										format="DD/MM/YYYY"
									/>
								</LocalizationProvider>
							</Grid>
							<Grid container xs={12} sm={2} alignItems="center" justifyContent="center">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="End Date"
										views={['month', 'year', 'day']}
										value={endDate}
										minDate={startDate}
										maxDate={dayjs(defaultEndDate)}
										onChange={(newValue: any) => {
											setEndDate(newValue)
										}}
										format="DD/MM/YYYY"
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={12} >
								<Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
									<Tab label="Users" />
									<Tab label="Investment" />
									<Tab label="Rewards" />
								</Tabs>
							</Grid>
							{bottom()}
						</Grid >
					</div>
				</div>
			</Grid>
		</Layout >
	</>
}