import APIController from './APIController'
import { FundType } from './TransactionController'




export interface UserBalanceDashboardFundData {
	fundType: FundType,
	amount: number,
	runRate: number,
	interestRate: number,
	interestRateText: string,
	interestRateColor?: string,
	fundName: string,
	isActiveFund: boolean,
	title: string,
	showInterestRate: boolean,
}

export interface UserBalancePortfolioTotal {
	balance: number,
	invested: number,
	uninvestedAmount: number,
	totalMandates: number,
}

export interface UserBalancePortfolioFundDataImage {
	location: string,
	locationType: 'local' | 'url'
}

export interface UserBalancePortfolioFundDataValueData {
	title: string,
	value: string,
	isNumber?: boolean,
	color?: string
}

export interface UserBalancePortfolioFundDataRoundup {
	roundup: number,
	roundupSpends: number,
	show: boolean
}

export interface UserBalancePortfolioFundDataDailyDeposit {
	dailyDeposit: number,
	dailyDepositDays: number,
	show: boolean
}

export interface UserBalancePortfolioFundDataDirectInvested {
	directInvested: number,
	show: boolean
}

export interface UserBalancePortfolioFundDataUninvestedAmount {
	uninvestedAmount: number,
	show: boolean
}

export interface UserBalancePortfolioFundData {
	fundType: FundType,
	fundName: string,
	backgroundColor: string,
	allowCollapse: boolean,
	image: UserBalancePortfolioFundDataImage,
	values: UserBalancePortfolioFundDataValueData[],
	roundup: UserBalancePortfolioFundDataRoundup,
	dailyDeposit: UserBalancePortfolioFundDataDailyDeposit,
	directInvested: UserBalancePortfolioFundDataDirectInvested,
	uninvestedAmount: UserBalancePortfolioFundDataUninvestedAmount,
}

export interface UserBalancePortfolio {
	total: UserBalancePortfolioTotal
	funds: UserBalancePortfolioFundData[]
}

export interface UserBalanceWithdrawalFundFolio {
	balance: number,
	availableToWithdraw: number,
}

export interface UserBalanceWithdrawalFund {
	fundName: string,
	balance: number,
	availableToWithdraw: number,
	folioData: {
		[key: string]: UserBalanceWithdrawalFundFolio
	}
	folioList: string[]
}

export interface UserBalanceWithdrawal {
	fundData: {
		[key: string]: UserBalanceWithdrawalFund
	}
	fundList: string[]
}

export interface UserBalance {
	dashboard?: UserBalanceDashboardFundData[],
	portfolio?: UserBalancePortfolio,
	withdrawal?: UserBalanceWithdrawal
}


export default class BalanceController {
	async getBalance(uid: string): Promise<UserBalance> {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/balance'
		const response = await APIController.post(url, { uid: uid })
		return response.data.data
	}
}