import APIController from './APIController'

export default class GenerateStatementController {
	static async downloadStatement(params: { uid: string, fundType?: string, allFunds?: boolean }) {
		const { uid, fundType, allFunds } = params
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/lendbox/statement'
		const response = await APIController.post(url, { uid: uid, fundType: fundType, allFunds: allFunds })
		const statementFile = response.data.data.statementFile
		const fileName = response.data.data.fileName ?? response.data.data.filename
		// if (res.data?.data?.statement_file) {
		// 	// To Download the pdf
		await fetch(statementFile).then((response) => {
			response.blob().then((blob) => {
				const fileURL = window.URL.createObjectURL(blob)
				const alink = document.createElement('a')
				alink.href = fileURL
				alink.download = fileName
				alink.click()
			})
		})
		// }
	}
}
