import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import TransactionController, { Transaction } from 'services/controllers/TransactionController'

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}
export default function Transactions(props: { goBackFunction: any, month: string }) {
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const month = props.month
	const [transactionsList, setTransactionsList] = useState<Transaction[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const transactionController = new TransactionController()

	useEffect(() => {
		const fetchData = () => {
			getTransactions.current()
		}
		return fetchData()
	}, [])

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'Timestamp', width: 300 },
		{ field: 'type', headerName: 'Type', width: 150 },
		{ field: 'amount', headerName: 'Amount', width: 150 },
		{ field: 'total', headerName: 'Total', width: 150 },
		{ field: 'status', headerName: 'Status', width: 150 },
		{ field: 'txnType', headerName: 'Transaction Type', width: 150 },
		{ field: 'accountType', headerName: 'Account Type', width: 150 },
		{ field: 'bankName', headerName: 'Bank Name', width: 150 },
		{ field: 'due', headerName: 'Due', width: 150 },
	]

	const getTransactions = useRef(() => {
		// 
	})

	getTransactions.current = () => {
		if (uid !== undefined && uid !== null) {
			transactionController.getTransactions(uid, month, (data) => {
				setTransactionsList(data)
				if (loading)
					setLoading(false)
			})
		}
	}

	return (
		<div
			style={{
				height: 'calc(100vh - 160px)',
				width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
			}}>
			<DataGrid
				components={{
					Toolbar: CustomToolbar
				}}
				componentsProps={{
					toolbar: {
						goBackFunction: props.goBackFunction
					}
				}}
				rows={transactionsList}
				columns={columns}
				loading={loading} />
		</div>
	)
}
