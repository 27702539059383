import { Refresh as RefreshIcon } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid, IconButton, SxProps } from '@mui/material'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import { useEffect, useState } from 'react'
import StatsController from 'services/controllers/StatsController'

import StatsBarGraphV2 from './stats/mini-components/StatsBarGraphV2'


const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		height: 400,
		background: Colors.neutrals.lead100,
		borderRadius: 2
	},
	mainCard: {
		backgroundColor: Colors.neutrals.lead100,
		height: 90,
		borderRadius: 2
	},
	cardContent: {
		alignContent: 'center',
		textAlign: 'center'
	},
	gridList: {
		height: 360,
	},
	total: {
		color: Colors.secondary.mint400
	},
	kyc: {
		color: Colors.secondary.orange300
	},
	investments: {
		color: Colors.primary.violet400
	},
	userStats: {
		color: Colors.primary.lime400
	},
	rewards: {
		color: Colors.primary.lime400
	}
}
)


export default function Dashboard() {
	const [dashboardData, setDashboardData] = useState<any>([])
	const classes = useStyles()

	useEffect(() => {
		const fetchData = () => {
			getData()
		}
		return fetchData()
	}, [])

	const getData = async () => {
		const data = await new StatsController().getDashboardStats()
		setDashboardData(data)
	}

	const dataTypes: { [key: string]: { title: string, graph: 'bar' } } = {
		cumulativeInstalledDevices: {
			title: 'Cumulative Installed Devices',
			graph: 'bar'
		},
		mau: {
			title: 'Monthly Active Users',
			graph: 'bar'
		},
		activationRate: {
			title: 'Activation Rate',
			graph: 'bar'
		},
		d30RetentionRate: {
			title: 'D30 Retention Rate',
			graph: 'bar'
		},
		averageInvestmentPerUserPerDay: {
			title: 'Average Investment/User/Day',
			graph: 'bar'
		},
		cumulativeAUM: {
			title: 'Total Gross AUM (Cumulative)',
			graph: 'bar'
		},
		totalNetRevenue: {
			title: 'Total Net Revenue',
			graph: 'bar'
		},
	}

	return (
		<Layout id={'dashboard'}>
			<Grid>
				<div
					style={{
						height: 'calc(100vh - 170px)',
						width: '100%'
					}}>

					<div style={{
						backgroundColor: Colors.neutrals.lead200,
						// width: "100vw",
						height: '100vh',
					}}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={11} direction="row-reverse">
							</Grid>
							<Grid item xs={12} sm={1} direction="row-reverse">
								<IconButton onClick={getData} size="large">
									<RefreshIcon />
								</IconButton>
							</Grid>
							{Object.keys(dataTypes).map((dataType: any) => {
								const data = dataTypes[dataType]
								return (
									<Grid item xs={12} sm={6}>
										<Card elevation={0} sx={classes.card}>
											<CardHeader
												sx={classes.userStats}
												subheader={data.title}
											/>
											<CardContent sx={classes.cardContent}>
												< StatsBarGraphV2 data={dashboardData} color={Colors.primary.lime400} dataKey={dataType} />
											</CardContent>
										</Card>
									</Grid>
								)
							})}
						</Grid >
					</div>
				</div>
			</Grid>
		</Layout >
	)
}
