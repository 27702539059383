import { Close as CloseIcon } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CircularProgress, CssBaseline, Grid, IconButton, SxProps, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Center from 'components/Center'
import dayjs, { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import TransactionsController, { getTransactionType, TransactionData } from 'services/controllers/TransactionController'



const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})


export default function TransactionView() {
	const currentMonth = new Date(Date.now())
	const lastMonth = new Date(Date.now())
	lastMonth.setMonth(lastMonth.getMonth() - 1)
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const [loading, setLoading] = useState<boolean>(false)
	const [start, setStart] = useState<Dayjs | null>(dayjs(lastMonth))
	const [end, setEnd] = useState<Dayjs | null>(dayjs(currentMonth))
	const [data, setData] = useState<TransactionData[] | null>(null)
	const classes = useStyles()

	const handleSubmit = useCallback(
		async function (event: any) {
			event.preventDefault()
			if (start?.isBefore(end)) {
				setLoading(true)
				if (start != null && end != null && uid != null && uid != undefined) {
					const data = await new TransactionsController().getTransactionData(uid, { month: start.month() + 1, year: start.year() }, { month: end.month() + 1, year: end.year() })
					setData(data)
					setLoading(false)
				} else {
					setLoading(false)
					alert('Set Valid Date')
				}
			}
		}
		, [start, end]
	)

	const formattedDate = (date: string | Date | null | undefined): string => {
		if (date == null || date == undefined) {
			return ''
		}
		const d = new Date(date)
		let formattedString = ''
		if (d.getDate() < 10) {
			formattedString += '0'
		}
		formattedString += d.getDate() + ' ' + ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		][d.getMonth()] + ' | '
		let hours = d.getHours()
		const isPM = hours >= 12
		if (hours > 12) {
			hours -= 12
		}
		if (hours < 10) {
			formattedString += '0'
		}
		formattedString += hours + ':'
		if (d.getMinutes() < 10) {
			formattedString += '0'
		}
		formattedString += d.getMinutes() + ' '
		formattedString += isPM ? 'pm' : 'am'
		return formattedString
	}

	return (<div
		style={{
			height: 'calc(100vh - 160px)',
			width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
		}}>
		{(data == null || data == undefined) && !loading ?
			<Center>
				<div>
					<div
						style={{
							backgroundColor: Colors.neutrals.lead200,
							padding: 20,
							textAlign: 'center',
						}}
					>
						<CssBaseline />
						<Card elevation={0} sx={classes.card}>
							<Typography variant="h5">Select Range</Typography>
							<form onSubmit={handleSubmit}>
								<div style={{ padding: 10 }} />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Start Date"
										views={['month', 'year']}
										value={start}
										onChange={(newValue: any) => {
											setStart(newValue)
										}}
										format="MM/YYYY"
									/>
								</LocalizationProvider>
								<div style={{ padding: 10 }} />
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="End Date"
										views={['month', 'year']}
										value={end}
										onChange={(newValue: any) => {
											setEnd(newValue)
										}}
										format="MM/YYYY"
									/>
								</LocalizationProvider>
								<div style={{ padding: 10 }} />
								<Button disabled={false} type="submit" fullWidth={true}>
									Search
								</Button>
							</form>
						</Card>
					</div>
				</div>
			</Center> : loading ? <>
				<Center>
					<CircularProgress />
				</Center> </> :
				<>
					<div style={{ width: '100%' }}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} />
							<Grid item xs={12} sm={0.25} />
							<Grid item xs={12} sm={10}>
								Date/Status
							</Grid>
							<Grid item xs={12} sm={1}>
								Amount
							</Grid>
							<Grid item xs={12} sm={0.5}>
								<IconButton onClick={() => {
									setData(null)
								}}>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</div>
					<div style={{ width: '100%', whiteSpace: 'nowrap' }}>
						<Box component="div" sx={{ overflow: 'visible' }}>
							{
								data?.map(transaction => (
									transaction.cardType == 'mandate' ?
										<>
											<Accordion style={{ width: '98%', margin: '1%', }}>
												<AccordionSummary expandIcon={
													<ExpandMoreIcon />}>
													<Grid item xs={12} sm={11}>
														<Typography variant="subtitle1" color={transaction.titleColored ? transaction.color : Colors.type.type100}>{transaction.title}</Typography>
														<Typography variant="subtitle2" color={Colors.type.type60}>{transaction.fundType ?? ''}</Typography>
													</Grid>
													<Grid item xs={12} sm={1}>
														<Typography variant="subtitle1" color={transaction.color ?? Colors.type.type100}>+ {transaction.amount}{(transaction.uninvestedAmount ?? 0) > 0 ? ('(' + String(transaction.uninvestedAmount) + ')') : ''}</Typography>
														<Typography variant="subtitle2" color={Colors.type.type60}>{transaction.statusText ?? ''}</Typography>
													</Grid>
												</AccordionSummary>
												<AccordionDetails
													style={{ width: '100%' }}
												>
													<Typography variant="subtitle2" color={Colors.type.type60}>
    Notification: Error code: <strong>{transaction.notificationErrorCode ?? 'NA'}</strong> Response code: <strong>{transaction.notificationResponseCode ?? 'NA'}</strong>
													</Typography>

													<Typography variant="subtitle2" color={Colors.type.type60} style={{ paddingBottom: '8px' }}>
    Mandate: Error code: <strong>{transaction.mandateErrorCode ?? 'NA'}</strong> Response code: <strong>{transaction.mandateResponseCode ?? 'NA'}</strong>
													</Typography>

													{(transaction.transactions ?? []).map(
														transactionData => (
															<div style={{ width: '100%' }}>
																<Grid container spacing={3}>
																	<Grid item xs={12} sm={10.75}>
																		<Typography variant="subtitle1" color={Colors.type.type80}>{getTransactionType(transactionData.type, transactionData.category, transactionData.txnCategory, transactionData.merchant, transactionData.details)}</Typography>
																		<Typography variant="subtitle2" color={Colors.type.type60}>{formattedDate(transactionData.timestamp)}</Typography>
																	</Grid>
																	<Grid item xs={12} sm={1}>
																		<Typography variant="subtitle1" color={Colors.type.type100}>+ {transactionData.total}{(transaction.uninvestedAmount ?? 0) > 0 ? ('(' + String(transaction.uninvestedAmount) + ')') : ''}</Typography>
																		<Typography variant="subtitle2" color={Colors.type.type60}>{transactionData.type == 0 ? 'Round-up' : ''}</Typography>
																	</Grid>
																	<Grid item xs={12} sm={0.25} />
																	<Grid item xs={12} sm={12} />
																</Grid>
															</div>
														)
													)
													}
												</AccordionDetails>
											</Accordion >
										</>
										: <>
										</>
								))}
						</Box>
					</div>
				</>
		}
	</div >
	)
}
