import { FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, Card, CardHeader, Grid, IconButton, SxProps, Typography } from '@mui/material'
import { Colors } from 'models/Colors'
import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TransactionController, { TransactionDetails } from 'services/controllers/TransactionController'
import copyToClipboard from 'services/util/copyToClipboard'

import Layout from './Layout'
const CustomIconButton = (props: {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
	icon: any,
}) => {
	return (
		<IconButton onClick={props.onClick} size="large">
			{props.icon}
		</IconButton>
	)
}
const DataCard = (props: {
	title: string,
	value: string,
	classes: any,
	color?: string,
	actions?: any,
}) => {
	const className = props.classes.card
	const color = props.color ?? Colors.primary.lime400
	return (
		<Grid item xs={12} sm={5}>
			<Card
				elevation={0}
				sx={className}
				style={{ backgroundColor: color + '1A' }}
			>
				<CardHeader
					title={
						<Typography variant="body1" color={Colors.type.type100}>
							{props.title}
						</Typography>
					}
					subheader={
						<Typography variant="subtitle1" color={color}>
							{props.value}
						</Typography>
					}
					action={<>{props.actions}</>}
				></CardHeader>
			</Card>
		</Grid>
	)
}
const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		borderRadius: 8,
	},
})

const getUID = (TxnId: string) => {
	return TxnId.substring(1, 29)
}

export default function TransactionCustomerReferenceView() {
	let { custRefNo } = useParams()
	custRefNo = custRefNo ?? ''

	const classes = useStyles()

	const [data, setData] = useState<TransactionDetails | null>(null)
	const transactionsController = new TransactionController()
	// const data = transactionsController.getTransactionsForCustRefNo(custRefNo)

	useEffect(() => {
		const fetchData = () => {
			getTransactionData.current()
		}
		return fetchData()
	}, [])

	const UID = getUID(data?.transactionId ?? '')
	const navigate = useNavigate()

	const getTransactionData = useRef(() => {
		// 
	})

	getTransactionData.current = async () => {
		if (custRefNo != null || custRefNo !== undefined) {
			const data = await transactionsController.getTransactionsForCustRefNo(custRefNo)
			setData(data)
		}
	}

	return (
		<Layout id={'services'}>
			<Grid xs={12} sm={6}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<div style={{ marginTop: 20 }}>
							<DataCard
								classes={classes}
								title={'UPI Transaction ID'}
								value={data?.UPITxnId ?? ''}
								color={Colors.primary.lime400}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={12}>
						<div style={{ marginTop: 20 }}>
							<DataCard
								classes={classes}
								title={'pspRefNo'}
								value={data?.pspRefNo ?? ''}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={12}>
						<div style={{ marginTop: 20 }}>
							<DataCard
								classes={classes}
								title={'Transaction ID'}
								value={data?.transactionId ?? ''}
								actions={
									data?.transactionId != null ? (
										<>
											<CustomIconButton
												onClick={() => copyToClipboard(data?.transactionId ?? '')}
												icon={<FileCopyIcon />}
											/>
										</>
									) : (
										<></>
									)
								}
							/>
						</div>
					</Grid>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Button
						style={{ marginTop: 20 }}
						variant="contained"
						onClick={() => {
							navigate(`/users/${UID}`)
						}}
					>
						User Data
					</Button>
				</Grid>
			</Grid>
		</Layout>
	)

}