
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from 'services/firebase'

export interface Timestamps {
	id: string,
	value?: any
}

export default class TimeStampsController {
	getPausedTimeStamps(uid: string, observer: (timestamps: any[]) => void) {
		const accountPausedCollectionRef = collection(db, `users/${uid}/accountPausedSnapshots`)

		onSnapshot(accountPausedCollectionRef, (querySnapshot: any) => {
			const timestamps: any[] = []

			querySnapshot.forEach((doc: any) => {

				const data = doc.data()
				data.id = doc.id
				timestamps.push(data)
			})

			observer(timestamps)
		})
	}
}
