import { Button, Card, CircularProgress, CssBaseline, SxProps } from '@mui/material'
import { Colors } from 'models/Colors'
import { useNavigate } from 'react-router'
import { useAuth } from 'services/contexts/AuthContext'


const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})

export default function AdminDataLoading() {
	const navigate = useNavigate()
	const classes = useStyles()
	const { logout } = useAuth()

	async function handleLogout() {
		try {
			await logout()
			navigate('/login')
		} catch {
			alert('Failed to log out')
		}
	}

	return (
		<div style={{
			backgroundColor: Colors.neutrals.lead200,
			width: '100vw',
			height: '100vh',
			textAlign: 'center',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<CssBaseline />

			<Card elevation={0} sx={classes.card} style={{
				width: 400,
				padding: 20
			}} >
				{/* <Center> */}
				<CircularProgress />
				<div style={{
					padding: 10
				}} />
				<Button onClick={handleLogout} fullWidth={true}>Log Out</Button>
				{/* </Center> */}
			</Card>
		</div>
	)
}

