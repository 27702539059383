import React, { forwardRef, useImperativeHandle, useState } from 'react'

import StatsBarGraph from './mini-components/StatsBarGraph'
import StatsLineGraph from './mini-components/StatsLineGraph'

export const UserStatGraph = forwardRef((props: { data: any[], dataKey: string, color: string }, ref) => {
	const color = props.color
	const data = props.data
	const dataKey = props.dataKey
	const [isLineGraph, setIsLineGraph] = useState<boolean>(true)


	useImperativeHandle(
		ref,
		() => ({
			setLineGraph() {
				setIsLineGraph(true)
			},
			setBarGraph() {
				setIsLineGraph(false)
			}
		}),
	)

	if (isLineGraph)
		return (
			<>
				<StatsLineGraph color={color} data={data} dataKey={dataKey} />
			</>
		)
	else
		return (
			<>
				<StatsBarGraph color={color} data={data} dataKey={dataKey} />
			</>
		)
}
)
