import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from 'services/firebase'

export default class StorageController {
	async uploadFile(file: any, location: string) {
		const reference = ref(storage, location + file.name)
		await uploadBytes(reference, file)
		const url = await getDownloadURL(reference)
		return { url: url, path: reference.fullPath }
	}

	async deleteFile(path: string) {
		await deleteObject(ref(storage, path))
	}
}