import axios from 'axios'
import { auth } from 'services/firebase'

export default class APIController {

	private static async updateToken() {
		const token = await auth.currentUser?.getIdToken(true)
		if (token != null) {
			sessionStorage.setItem('token', token)
			sessionStorage.setItem('tokenExpiry', JSON.stringify(new Date(Date.now() + 1800000)))
		}
		return token
	}

	private static async getToken() {
		const now = new Date(Date.now())
		const tokenExpiryValue = JSON.parse((sessionStorage.getItem('tokenExpiry')) || 'null')
		let tokenExpiry: Date | null = null
		if (tokenExpiryValue != null) {
			tokenExpiry = new Date(tokenExpiryValue)
		}
		if (tokenExpiry == null || now >= tokenExpiry) {
			const token = await this.updateToken()
			return token
		} else {
			let token: string | null | undefined = sessionStorage.getItem('token')
			if (token == null || token == undefined) {
				token = await this.updateToken()
			}
			return token
		}
	}

	private static async getHeaders() {
		const headers: any = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		}
		const token = await this.getToken()
		if (token != null) {
			headers['Authorization'] = `Bearer ${token}`
		}
		return headers
	}

	static async post(url: string, data: any) {
		const headers = await this.getHeaders()
		const response = await axios.post(url, data, { headers })
		return response
	}

	static async get(url: string) {
		const headers = await this.getHeaders()
		const response = await axios.get(url, { headers })
		return response
	}
}
