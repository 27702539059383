import { ArrowForwardIos as ArrowForwardIosIcon, Person as PersonIcon, Refresh as RefreshIcon } from '@mui/icons-material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { Button, Card, CssBaseline, IconButton, SxProps, TextField, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Center from 'components/Center'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import LendboxController from 'services/controllers/LendboxController'
import UsersController, { UserData } from 'services/controllers/UsersController'
import { FieldPath } from 'services/firebase'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})

const CustomToolbar = (props: any) => {
	const { cancelFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={cancelFunction} size="large">
				<CancelIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

export default function UsersSearch() {
	const [usersList, setUsersList] = useState<UserData[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const classes = useStyles()
	const navigate = useNavigate()
	const handleSubmit = useCallback(
		async function (event: any) {
			event.preventDefault()
			const { uid, email, phone, firstName, lastName, uuid, lendboxUserId, pan, folio, transactionId } = event.target.elements
			try {
				const usersController = new UsersController()
				setLoading(true)
				const queries: any[] = []

				if (uid.value !== '') {
					queries.push({ 'field': FieldPath.documentId(), 'operator': '==', 'value': uid.value })
				}
				if (email.value !== '') {
					queries.push({ 'field': 'email', 'operator': '==', 'value': email.value })
				}
				if (phone.value !== '') {
					let _phone: string = phone.value
					if (_phone.length === 10) {
						_phone = '+91' + _phone
					} else if (_phone.length === 12) {
						_phone = '+' + _phone
					}
					queries.push({ 'field': 'phone', 'operator': '==', 'value': _phone })
				}
				if (firstName.value !== '') {
					queries.push({ 'field': 'firstName', 'operator': '==', 'value': firstName.value })
				}
				if (lastName.value !== '') {
					queries.push({ 'field': 'lastName', 'operator': '==', 'value': lastName.value })
				}
				if (uuid.value !== '') {
					queries.push({ 'field': 'uuid', 'operator': '==', 'value': uuid.value })
				}
				if (lendboxUserId.value !== '') {
					const _uid = await usersController.getUidFromLendboxUserId(lendboxUserId.value)
					if (_uid !== null && _uid !== undefined) {
						queries.push({ 'field': FieldPath.documentId(), 'operator': '==', 'value': _uid })
					}
				}

				if (pan.value !== '') {
					const _uid = await usersController.getUidFromPan(pan.value)
					if (_uid !== null && _uid !== undefined) {
						queries.push({ 'field': FieldPath.documentId(), 'operator': '==', 'value': _uid })
					}
				}
				if (folio.value !== '') {
					const _uid = await usersController.getUidFromFolio(folio.value)
					if (_uid !== null && _uid !== undefined) {
						queries.push({ 'field': FieldPath.documentId(), 'operator': '==', 'value': _uid })
					}
				}
				if (transactionId.value !== '') {
					queries.push({ 'field': FieldPath.documentId(), 'operator': '==', 'value': transactionId.value.substring(1, 29) })
				}
				if (queries.length > 0) {
					usersController.getUsersByQuery((data) => {
						if (data.length === 1) {
							window.open(`/users/${data[0].uid}`, '_blank')
						} else {
							setUsersList(data)
						}
						setLoading(false)
					}, queries)
				} else {
					setLoading(false)
				}
			} catch (e) {
				setLoading(false)
			}
		}, [navigate]
	)
	const columns: GridColDef[] = [
		{
			field: 'view profile',
			headerName: 'View Profile	',
			width: 80,
			renderCell: (params: any) => {
				const onClick = () => {
					window.open(`/users/${params.id}`, '_blank')
				}
				return (
					<IconButton onClick={onClick} title={'View Profile'} size="large">
						<PersonIcon />
					</IconButton>
				)
			}
		},
		{ field: 'id', headerName: 'uid', width: 300 },
		{ field: 'phone', headerName: 'Phone', width: 150 },
		{ field: 'email', headerName: 'Email', width: 300 },
		{ field: 'firstName', headerName: 'First Name', width: 200 },
		{ field: 'lastName', headerName: 'Last Name', width: 200 },
		{ field: 'lendboxBalance', headerName: 'Lendbox Balance', width: 120 },
		{ field: 'lendboxInvested', headerName: 'Lendbox Invested', width: 120 },
		{ field: 'lendboxInProgress', headerName: 'Lendbox in Progress', width: 200 },
		{ field: 'lendboxAvailableToWithdraw', headerName: 'lendbox Available To Withdraw', width: 200 },
		{ field: 'lendboxTotalInterestEarning', headerName: 'Lendbox Gains', width: 200 },
		{ field: 'lendboxInterestPerDay', headerName: 'Lendbox Gains Per Day', width: 200 },
		{ field: 'lendboxTotalWithdrawal', headerName: 'Lendbox Total Withdrawal', width: 200 },
		{ field: 'lendboxWithdrawalInProgress', headerName: 'Lendbox Withdrawal in Progress', width: 200 },
		{ field: 'onboardingLendbox', headerName: 'Lendbox Registration', width: 125 },
		{ field: 'onboardingMandate', headerName: 'Mandate', width: 125 },
		{ field: 'rewardsCounterRoundup', headerName: 'Reward', width: 125 },
		{ field: 'rewardClaim', headerName: 'Reward Claim', width: 125 },
		{ field: 'roundup', headerName: 'Round up', width: 125 },
		{ field: 'recurring', headerName: 'Daily Deposit', width: 125 },
		{ field: 'accountPaused', headerName: 'Account Paused', width: 125 },
		{ field: 'smsPermission', headerName: 'SMS', width: 125 },
		{ field: 'os', headerName: 'OS', width: 125 },
		{ field: 'version', headerName: 'Version', width: 125 },
		{
			field: 'referesh lendbox ',
			headerName: 'Refresh Lendbox Balances',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					const lendboxController = new LendboxController()
					lendboxController.refreshLendbox(params.id)
				}
				return (
					<IconButton onClick={onClick} title={'Refresh Lendbox Balances'} size="large">
						<RefreshIcon />
					</IconButton>
				)
			}
		},
		{
			field: 'Transactions',
			headerName: 'Transaction',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					navigate(`/users/${params.id}/transactions`)
				}
				return (
					<IconButton onClick={onClick} title={'Transaction'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
		{
			field: 'Portfolio',
			headerName: 'Portfolio',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					navigate(`/users/${params.id}/portfolio`)
				}
				return (
					<IconButton onClick={onClick} title={'Portfolio'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
		{
			field: 'Lendbox',
			headerName: 'Lendbox',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					navigate(`/users/${params.id}/lendbox`)
				}
				return (
					<IconButton onClick={onClick} title={'Transaction'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
		{
			field: 'Mutual Fund',
			headerName: 'Mutual Fund',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					navigate(`/users/${params.id}/mutualFund`)
				}
				return (
					<IconButton onClick={onClick} title={'Transaction'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
		{ field: 'updatedAt', headerName: 'Updated At', width: 200 },
		{ field: 'createdAt', headerName: 'Created At', width: 200 },
		{ field: 'uuid', headerName: 'UUID', width: 400 },
		{ field: 'fcmToken', headerName: 'FCM Token', width: 400 },
	]

	return (
		<Layout id={'users'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					width: '100%'
				}}>
				{usersList.length === 0 && !loading ?

					<div style={{
						backgroundColor: Colors.neutrals.lead200,
						width: '100vw',
						height: '100vh',
						textAlign: 'center',
					}}>
						<CssBaseline />
						<Center>
							<Card elevation={0} sx={classes.card} style={{
								width: 400,
								padding: 20
							}} >
								<Typography variant="h5">
									Users Search
								</Typography>
								<form onSubmit={handleSubmit}>
									<div style={{
										padding: 10
									}} />
									<TextField id="uid" type="uid" label="UID" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="email" type="email" label="Email" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="phone" type="tel" label="Phone" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="firstName" type="text" label="First Name" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="lastName" type="text" label="Last Name" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="uuid" type="text" label="UUID" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="lendboxUserId" type="text" label="Lendbox User Id" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="transactionId" type="text" label="Transaction Id" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="pan" type="text" label="PAN" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="folio" type="number" label="Folio" fullWidth />
									<div style={{
										padding: 10
									}} />
									<Button disabled={loading} type="submit" fullWidth={true}>Submit</Button >
								</form>
							</Card>
						</Center>
					</div>
					:
					<DataGrid
						components={{
							Toolbar: CustomToolbar,
						}}
						componentsProps={{
							toolbar: {
								cancelFunction: () => {
									setUsersList([])
								},
							}
						}}
						rows={usersList}
						columns={columns}
						loading={loading} />
				}
			</div>
		</Layout>
	)
}