import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import TimeStampsController from 'services/controllers/PausedAccTimeStampsController'

const CustomToolbar = (_props: any) => {
	const navigate = useNavigate()

	const goBackFunction = () => {
		navigate(-1)
	}

	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon />
			</IconButton>
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

export default function AccountPausedTimestamps() {
	const { uid } = useParams()
	const [timestamps, setTimestamps] = useState<any[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const navigate = useNavigate()
	const timestampsController = new TimeStampsController()

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'Timestamp', width: 300 },
		{ field: 'value', headerName: 'Value', width: 300 },

	]

	useEffect(() => {
		const fetchData = () => {
			fetchTimestamps.current()
		}
		return fetchData()
	}, [])

	const fetchTimestamps = useRef(() => {
		// 
	})

	fetchTimestamps.current = async () => {
		if (uid != null && uid != undefined) {
			await timestampsController.getPausedTimeStamps(uid, (data) => {
				setTimestamps(data)
				if (loading) setLoading(false)
			})
		}
	}

	return (
		<Layout id={'users'}>
			<div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar,
					}}
					componentsProps={{
						toolbar: {
							goBackFunction: () => {
								navigate('/users')
							},
						},
					}}
					rows={timestamps}
					columns={columns}
					loading={loading}

				/>
			</div>
		</Layout>
	)
}
