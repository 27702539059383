export default function getMonth(monthCode: string) {
	try {
		let month = months[Number(monthCode.substring(5, 7))] + ' '
		month += monthCode.substring(0, 4)
		return month
	} catch (error) {
		return monthCode
	}
}

const months: any = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December'
}