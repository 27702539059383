import APIController from './APIController'
import { FundType } from './TransactionController'

export default class UserRequestController {

	getStatusString(status: string) {
		switch (status) {
			case 'PENDING': return 'Pending'
			case 'SENT_TO_LENDBOX': return 'Sent To Lendbox'
			case 'SENT_TO_CAMS': return 'Sent To Cams'
			case 'COMPLETED': return 'Completed'
			default: return status
		}
	}

	async getBankChangeRequests(provider: 'LENDBOX' | 'CAMS') {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/bank/change-requests'
		const response = await APIController.post(url, { provider: provider })
		const data = response.data.data
		const newData: any[] = []
		for (const element of data) {
			const history = element.history ?? []
			history.sort((a: any, b: any) => Number(a.id) - Number(b.id))
			history.forEach((element: any) => {
				element.statusString = this.getStatusString(element.status)
				element.userName = (element.historyUpdatedByUser.firstName ?? '') + ' ' + (element.historyUpdatedByUser.lastName ?? '')
				element.isAdmin = element.historyUpdatedByUser.isAdmin
			})
			const pushData: any = {
				id: element.id,
				userUid: element.user.uid,
				userName: element.user.firstName + ' ' + element.user.lastName,
				provider: element.provider,
				fundType: element.fund.fundCode,
				bankAccountId: element.bankDetails.id,
				bankAccountNumber: element.bankDetails.accountNumber,
				bankAccountHolderName: element.bankDetails.accountHolderName,
				bankAccountIfsc: element.bankDetails.ifsc,
				bankAccountAccountType: element.bankDetails.accountType,
				status: element.status,
				statusString: this.getStatusString(element.status),
				nextStatus: element.nextStatus,
				nextStatusString: this.getStatusString(element.nextStatus),
				createdByUser: element.createdByUser,
				updatedByUser: element.updatedByUser,
				history: history,
				createdAt: element.createdAt,
				updatedAt: element.updatedAt,
			}
			switch (provider) {
				case 'LENDBOX':
					pushData.lendboxUserId = element.lendboxUserId
					break
				case 'CAMS':
					pushData.folioNumber = element.folioNumber
					break
			}
			newData.push(pushData)
		}
		return newData
	}

	async updateBankChangeRequestStatus(id: string, status: string) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/bank/change-requests/status/update'
		const response = await APIController.post(url, { id: id, status: status })
		return response.data
	}

	async createBankChangeRequest(userId: number, fundType: FundType, folioNumbers: string[], accountNumber: string, accountHolderName: string, ifsc: string, accountType: string,) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/bank/change-requests/create'
		const response = await APIController.post(url, {
			userId: userId,
			accountNumber: accountNumber,
			accountHolderName: accountHolderName,
			ifsc: ifsc,
			accountType: accountType,
			fundCode: fundType,
			folioNumbers: folioNumbers
		})
		return response.data
	}
}