import {
	Cancel as CancelIcon,
	Check as CheckIcon
} from '@mui/icons-material'
import {
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField
} from '@mui/material'
import axios from 'axios'
import { memo, useState } from 'react'
import { FundType } from 'services/controllers/TransactionController'
import UserRequestController from 'services/controllers/UserRequestController'
import UsersController from 'services/controllers/UsersController'

function UserBankAccountUpdateDialog(props: { id: number, showAddBankDialog: boolean, onClose: () => void }) {
	const [selectedFolios, setSelectedFolios] = useState<string[]>([])
	const [folios, setFolios] = useState<string[]>([])
	const [selectedFundType, setSelectedFundType] = useState<FundType | null>(null)
	const [holderName, setHolderName] = useState<string>()
	const [accountNumber, setAccountNumber] = useState<string>()
	const [ifsc, setIfsc] = useState<string>()
	const [branches, setBranches] = useState<any>({})
	const [branch, setBranch] = useState<string>()
	const [accountType, setAccountType] = useState<string>()
	const [loading, setLoading] = useState<boolean>(false)
	const usersController = new UsersController()
	const showAddBankDialog = props.showAddBankDialog
	const id = props.id

	const handleClose = () => {
		setSelectedFolios([])
		setFolios([])
		props.onClose()
	}

	async function getBranch(ifsc: string) {
		if (ifsc != null && ifsc != undefined && ifsc.length == 11) {
			if (branches[ifsc] == null || branches[ifsc] == undefined) {
				const ifscData = await axios.get(`https://ifsc.razorpay.com/${ifsc}`)
				branches[ifsc] = ifscData.data.BRANCH
				setBranches(branches)
			}
			setBranch(branches[ifsc])
		} else {
			setBranch(undefined)
		}
	}

	async function getFolios(fundType: FundType) {
		setFolios([])
		setSelectedFolios([])
		if (id != null && id != undefined) {
			const folios = await usersController.getFolios(id, fundType)
			setFolios(folios)
		}
	}

	return <>
		<Dialog
			open={showAddBankDialog}
			onClose={handleClose}
			fullWidth
			PaperProps={{
				component: 'form',
				onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
					event.preventDefault()
					// const formData = new FormData(event.currentTarget)
					// const formJson = Object.fromEntries((formData as any).entries())
					handleClose()
				},
			}}
		>
			<DialogTitle>Update Mutual Bank Account</DialogTitle>
			<DialogContent >
				Select Fund
				<Select
					fullWidth
					disabled={loading}
					value={selectedFundType as string}
					onChange={async (event: SelectChangeEvent) => {
						const fundType = event.target.value
						if (fundType != null && fundType != undefined && fundType != selectedFundType) {
							setSelectedFundType(fundType as FundType)
							setSelectedFolios([])
							await getFolios(fundType as FundType)
						}
					}}>
					<MenuItem value={FundType.PLF_EHGPG}>PLF_EHGPG</MenuItem>
					<MenuItem value={FundType.PLF_MCGPG}>PLF_MCGPG</MenuItem>
				</Select>
				Select Folios
				<Select
					fullWidth
					disabled={loading || selectedFundType == null}
					multiple
					margin="dense"
					value={selectedFolios}
					onChange={(e) => setSelectedFolios(e.target.value as string[])}
					renderValue={(selected) => (
						<Stack gap={1} direction="row" flexWrap="wrap">
							{selected.map((value) => (
								<Chip
									key={value}
									label={value}
									onDelete={() =>
										setSelectedFolios(
											selectedFolios.filter((item) => item !== value)
										)
									}
									deleteIcon={
										<CancelIcon
											onMouseDown={(event) => event.stopPropagation()}
										/>
									}
								/>
							))}
						</Stack>
					)}
				>
					{folios.map((folio) => (
						<MenuItem
							key={folio}
							value={folio}
							sx={{ justifyContent: 'space-between' }}
						>
							{folio}
							{selectedFolios.includes(folio) ? <CheckIcon /> : null}
						</MenuItem>
					))}
				</Select>
				Holder Name
				<TextField
					autoFocus
					required
					disabled={loading || selectedFolios.length === 0}
					margin="dense"
					id="holderName"
					name="holderName"
					type="string"
					fullWidth
					variant="standard"
					value={holderName}
					onChange={(e) => setHolderName(e.target.value)}
				/>
				Account Number
				<TextField
					autoFocus
					required
					disabled={loading || selectedFolios.length === 0}
					margin="dense"
					id="accountNumber"
					name="accountNumber"
					type="number"
					fullWidth
					variant="standard"
					value={accountNumber}
					onChange={(e) => setAccountNumber(e.target.value)}
				/>
				IFSC
				<TextField
					autoFocus
					required
					disabled={loading || selectedFolios.length === 0}
					margin="dense"
					id="ifsc"
					name="ifsc"
					type="string"
					fullWidth
					variant="standard"
					inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 11, pattern: '[A-Z]{4}[0][A-Z0-9]{6}' }}
					value={ifsc}
					onChange={async (e) => {
						setIfsc(e.target.value)
						await getBranch(e.target.value)
					}}
				/>
				Branch
				<TextField
					autoFocus
					disabled={true}
					margin="dense"
					id="branch"
					name="branch"
					type="string"
					fullWidth
					variant="standard"
					value={branch ?? ''}
				/>
				Account Type
				<Select
					fullWidth
					value={accountType}
					onChange={(event: SelectChangeEvent) => {
						setAccountType(event.target.value)
					}}>
					<MenuItem value={'SAVINGS'}>SAVINGS</MenuItem>
					<MenuItem value={'CURRENT'}>CURRENT</MenuItem>
				</Select>
				{loading ?
					<CircularProgress />
					:
					<Button
						fullWidth
						disabled={selectedFolios.length === 0
							|| selectedFundType == null
							|| selectedFundType == undefined
							|| accountNumber == undefined
							|| accountNumber == null
							|| holderName == undefined
							|| holderName == null
							|| ifsc == undefined
							|| ifsc == null
							|| ifsc.length != 11
							|| accountType == undefined
							|| accountType == null
						}
						onClick={async (_props: any) => {
							if (selectedFolios.length === 0 || selectedFundType == null || selectedFundType == undefined || accountNumber == undefined || accountNumber == null || holderName == undefined || holderName == null || ifsc == undefined || ifsc == null || ifsc.length != 11 || accountType == undefined || accountType == null) {
								return
							}
							setLoading(true)
							await new UserRequestController().createBankChangeRequest(id, selectedFundType, selectedFolios, accountNumber, holderName, ifsc, accountType)
							setLoading(false)
							handleClose()
						}}
					>
						Submit
					</Button>
				}
			</DialogContent>
		</Dialog>
	</>
}

export default memo(UserBankAccountUpdateDialog)