import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import LendboxController, { Lendbox } from 'services/controllers/LendboxController'

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

export default function LendboxView() {
	const { uid } = useParams()
	const [lendboxList, setLendboxList] = useState<Lendbox[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const navigate = useNavigate()
	const lendboxController = new LendboxController()


	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 300 },
		{ field: 'value', headerName: 'Value', width: 200 },
	]

	const getLendbox = useRef(() => {
		// 
	})

	useEffect(() => {
		const fetchData = () => {
			getLendbox.current()
		}
		return fetchData()
	}, [])

	getLendbox.current = () => {
		if (uid != null && uid != undefined) {
			lendboxController.getLendbox(uid, (data) => {
				setLendboxList(data)
				if (loading)
					setLoading(false)
			})
		}
	}
	return (
		<Layout id={'users'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					width: '100%'
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar,
					}}
					componentsProps={{
						toolbar: {
							goBackFunction: () => {
								navigate('/users')
							},
						}
					}}
					rows={lendboxList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
