export default function monthConvert(id: string) {
	try {
		if (id.length === 5) {
			return id.substring(0, 4) + '/0' + id.substring(4, 5)
		} else if (id.length === 6) {
			return id.substring(0, 4) + '/' + id.substring(4, 6)
		} else {
			return id
		}
	} catch (error) {
		return id
	}
}