import 'fontsource-roboto'

import { ThemeProvider, } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import Dashboard from 'components/Dashboard'
// import Developer from "components/Developer"
import Login from 'components/Login'
import NotLoggedInRoute from 'components/routes/NotLoggedInRoute'
import PrivateRoute from 'components/routes/PrivateRoute'
import { useParams } from 'react-router'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { AdminUserDataProvider } from 'services/contexts/AdminUserDataContext'
import { AuthProvider } from 'services/contexts/AuthContext'
import { FundDataProvider } from 'services/contexts/FundDataContext'
import { LayoutProvider } from 'services/contexts/LayoutContext'

import AccountPausedTimestamps from './AccountPausedTimeStamps'
// import GenerateStatement from './GenerateStatement'
import KeysView from './KeysView'
import LendboxView from './Lendbox'
import ManageUsers from './ManageUsers'
import MutualFunds from './MutualFund'
import ExternalUserRoute from './routes/ExternalUserRoute'
import Services from './Services'
import Stats from './stats/Stats'
import StatsSearch from './StatsSearch'
import theme from './theme'
import TransactionCustomerReferenceView from './TransactionCustomerReferenceView'
import TransactionsSearch from './TransactionsSearch'
import UsersSearch from './user/UsersSearch'
import UsersView from './user/UserView'
import UserBankAccountChangeRequests from './UserBankAccountChangeRequests'
import UserRequests from './UserRequests'

export default function App() {
	function UserViewRedirect(props: { index: string }) {
		const { uid, month } = useParams()
		let to = '/users/' + uid + '?index=' + props.index
		if (month != null) {
			to += '&month=' + month
		}
		return (
			<Navigate to={to} />)
	}
	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<Router>
					<AuthProvider>
						<AdminUserDataProvider>
							<FundDataProvider>
								<LayoutProvider>
									<Routes>
										<Route path='/' element={< PrivateRoute />}>
											<Route path="/" element={<Navigate to={'/stats'} />} />
											<Route path="/transactions" element={<TransactionsSearch />} />
											{/* <Route path="/statement-generation" element={<GenerateStatement />} /> */}
											<Route path="/transactions/:custRefNo" element={<TransactionCustomerReferenceView />} />
											<Route path="/keysView/:id" element={<KeysView />} />
											<Route path="/users" element={<UsersSearch />} />
											<Route path="/users/:uid" element={<UsersView />} />
											<Route path="/users/:uid/mutualFund" element={<MutualFunds />} />
											<Route path="/users/:uid/lendbox" element={<LendboxView />} />
											<Route path="/users/:uid/timestamps" element={<AccountPausedTimestamps />} />
											<Route path="/users/:uid/transactions" element={<UserViewRedirect index="1" />} />
											<Route path="/users/:uid/transactions/:month" element={<UserViewRedirect index="1" />} />
											<Route path="/users/:uid/portfolio" element={<UserViewRedirect index="2" />} />
											<Route path="/users/:uid/portfolio/:month" element={<UserViewRedirect index="2" />} />
											<Route path="/stats/" element={<Stats />} />
											<Route path="/stats/search/:type" element={<StatsSearch />} />
											<Route path="/services" element={<Services />} />
											<Route path="/manage-users" element={<ManageUsers />} />
											<Route path="/user-requests" element={<UserRequests />} />
											<Route path="/user-requests/bank-account/:provider" element={<UserBankAccountChangeRequests />} />
										</Route>
										<Route path='/login' element={< NotLoggedInRoute />}>
											< Route path="/login" element={<Login />} />
										</Route>
										<Route path='/dashboard' element={< ExternalUserRoute />}>
											< Route path="/dashboard" element={<Dashboard />} />
										</Route>
									</Routes>
								</LayoutProvider>
							</FundDataProvider>
						</AdminUserDataProvider>
					</AuthProvider>
				</Router>
			</StyledEngineProvider>
		</ThemeProvider >
	)
}