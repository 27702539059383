import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { db, FieldValue } from 'services/firebase'

const KeysContext = createContext<any>(null)

export function useKeys() {
	return useContext(KeysContext)
}

export function KeysProvider(props: { children: any }) {

	const [keys, setKeys] = useState<KeysDoc[]>([])
	/**
	 * Add new key
	 * @param key Keys id 
	 * @param value Keys Value
	 */
	async function setKeyInDB(key: string, value: string, documentId: string) {
		await setDoc(doc(db, `keys/${documentId}`), { [key]: value }, { merge: true })
	}

	/**
	 * Delete key
	 * @param key Keys id
	 */
	async function deletekeyFromDB(key: string, documentId: string) {
		await setDoc(doc(db, `keys/${documentId}`), { [key]: FieldValue.delete() }, { merge: true })
	}

	function getKeys(key: string) {
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i]
			if (element.id === key) {
				return element.keys
			}
		}
		return []
	}

	useEffect(() => {
		const fetchData = () => {
			getKeysRef.current()
		}
		return fetchData()
	}, [])

	const getKeysRef = useRef(() => {
		// 
	})

	getKeysRef.current = async () => {
		const snapshot = await getDocs(collection(db, 'keys'))

		const keysData: KeysDoc[] = snapshot.docs.map((doc: any) => {
			const data = doc.data()
			const keyDocData: Keys[] = []
			let id = 1
			Object.keys(data).sort().forEach((key: string) => {
				keyDocData.push({ id: id++, key: key, value: data[key] })
			})
			const retVal: KeysDoc = { 'id': doc.id, 'keys': keyDocData }
			return retVal

		})
		setKeys(keysData)
	}
	const value = {
		setKeyInDB,
		deletekeyFromDB,
		getKeys,
		keys
	}

	return (
		<KeysContext.Provider value={value}>
			{props.children}
		</KeysContext.Provider>
	)
}

/**
 * Interface for Keys
 */
export interface Keys {
	id: number,
	key: string,
	value: string,
}
/**
 * Interface for Keys
 */
export interface KeysDoc {
	id: string,
	keys: Keys[],
}

