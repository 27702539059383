import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'services/contexts/AuthContext'

const NotLoggedIn = () => {

	const { currentUser } = useAuth()

	if (currentUser) {
		return <Navigate to="/" />
	}
	else {
		return <Outlet />
	}
}
export default NotLoggedIn
