import { BarChart as BarChartIcon, Refresh as RefreshIcon, SearchRounded, ShowChart as ShowChartIcon } from '@mui/icons-material'
import { ArrowForwardIos as ArrowForwardIosIcon, FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Icon, IconButton, SxProps } from '@mui/material'
import { UserDailyGraph } from 'components/stats/UserDailyGraph'
import { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import StatsController from 'services/controllers/StatsController'
import copyToClipboard from 'services/util/copyToClipboard'
import currencyFormat from 'services/util/currencyFormat'
import getMonth from 'services/util/getMonth'
import monthConvert from 'services/util/monthConvert'
import numberFormat from 'services/util/numberFormat'
import statsDataHandler from 'services/util/statsDataHandler'

import StatsBarGraph from '../mini-components/StatsBarGraph'
import StatsAreaGraphCard from '../StatsAreaGraphCard'
import { UserStatGraph } from '../UserStatGraph'
import JuspayUserStats from './users/JuspayUserStats'
import LendboxPausedStats from './users/LendboxPausedStats'

const statsController = new StatsController()

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		height: 400,
		background: Colors.neutrals.lead100,
		borderRadius: 2
	},
	mainCard: {
		backgroundColor: Colors.neutrals.lead100,
		height: 90,
		borderRadius: 2
	},
	cardContent: {
		alignContent: 'center',
		textAlign: 'center'
	},
	gridList: {
		height: 360,
	},
	total: {
		color: Colors.secondary.mint400
	},
	kyc: {
		color: Colors.secondary.orange300
	},
	investments: {
		color: Colors.primary.violet400
	},
	userStats: {
		color: Colors.primary.lime400
	},
	rewards: {
		color: Colors.primary.lime400
	}
}
)

function UsersStats(props: { startDate: Dayjs, endDate: Dayjs }) {
	const classes = useStyles()
	const [usersStats, setUsersStats] = useState<any>({})
	const [usersLendboxGraphData, setUsersLendboxGraphData] = useState<any[]>([])
	const [usersTotalGraphData, setUsersTotalGraphData] = useState<any[]>([])
	const userTotalGraphRef = useRef<any>()
	const userLendboxGraphRef = useRef<any>()
	const userTotalMonthlyGraphRef = useRef<any>()
	const userLendboxMonthlyGraphRef = useRef<any>()
	const [userMonthlyData, setUserMonthlyData] = useState<any>([])
	const [usersPausedGraphData, setUsersPausedGraphData] = useState<any[]>([])
	const [usersOnboardedGraphData, setUsersOnboardedGraphData] = useState<any[]>([])
	const [usersPreDebitNotificationsGraphData, setUsersPreDebitNotificationsGraphData] = useState<any[]>([])
	const [usersPreDebitNotificationsAmountGraphData, setUsersPreDebitNotificationsAmountGraphData] = useState<any[]>([])
	const [usersMandateExecutionsGraphData, setUsersMandateExecutionsGraphData] = useState<any[]>([])
	const [usersMandateExecutionsAmountGraphData, setUsersMandateExecutionsAmountGraphData] = useState<any[]>([])
	const [usersActiveGraphData, setUsersActiveGraphData] = useState<any[]>([])
	const [usersRoundUpGraphData, setUsersRoundUpGraphData] = useState<any[]>([])
	const [usersDailyDepositGraphData, setUsersDailyDepositGraphData] = useState<any[]>([])
	const [usersContactGraphData, setUsersContactGraphData] = useState<any[]>([])
	const [JuspayFirstExecutionAmountGraphData, setJuspayFirstExecutionAmountGraphData] = useState<any[]>([])
	const [JuspayFirstExecutionGraphData, setJuspayFirstExecutionGraphData] = useState<any[]>([])
	const [JuspayMandateExecutionGraphData, setJuspayMandateExecutionGraphData] = useState<any[]>([])
	const [JuspayMandateExecutionAmountGraphData, setJuspayMandateExecutionAmountGraphData] = useState<any[]>([])
	const [JuspayNotificationGraphData, setJuspayNotificationGraphData] = useState<any[]>([])
	const [JuspayNotificationAmountGraphData, setJuspayNotificationAmountGraphData] = useState<any[]>([])
	const [JuspayLumpsumGraphData, setJuspayLumpsumGraphData] = useState<any[]>([])
	const [JuspayLumpsumAmountGraphData, setJuspayLumpsumAmountGraphData] = useState<any[]>([])

	useEffect(() => {
		const fetchData = () => {
			getUserStatsData.current()
		}
		return fetchData()
	}, [])

	useEffect(() => {
		const fetchData = () => {
			getData()
		}
		return fetchData()
	}, [props.startDate, props.endDate])

	const getData = async () => {
		await getUserTotalGraphData.current()
		await getUserActiveGraphData.current()
		await getUserOnboardedGraphData.current()
		await getUserLendboxGraphData.current()
		await getUserPausedGraphData.current()
		await getUserPreDebitNotificationsGraphData.current()
		await getUserPreDebitNotificationsAmountGraphData.current()
		await getUserMandateExecutionsGraphData.current()
		await getUserMandateExecutionsAmountGraphData.current()
		await getUserRoundupGraphData.current()
		await getUserDailyDepositGraphData.current()
		await getUserContactGraphData.current()
		await getJuspayMandateExecutionAmountGraphData.current()
		await getJuspayFirstExecutionsAmountGraphData.current()
		await getJuspayFirstExecutionGraphData.current()
		await getJuspayMandateExecutionGraphData.current()
		await getJuspayNotificationGraphData.current()
		await getJuspayNotificationAmountGraphData.current()
		await getJuspayLumpsumGraphData.current()
		await getJuspayLumpsumAmountGraphData.current()
	}

	const getUserStatsData = useRef(() => {
		// 
	})

	getUserStatsData.current = () => {
		statsController.getUserStatsData(
			(data: any) => {
				if (usersStats.total != null && data.total !== usersStats.total) {
					getUserTotalGraphData.current()
				}
				if (usersStats.lendbox != null && data.lendbox !== usersStats.lendbox) {
					getUserLendboxGraphData.current()
				}
				setUsersStats(data)
				const userMonthlyDataList: any[] = []
				for (const i in data.data) {
					const d: any = {}
					d.monthCode = monthConvert(i)
					d.month = getMonth(d.monthCode)
					d.showDate = d.month
					d.date = d.month
					d.total = data?.data[i]?.total ?? 0
					d.kyc = data?.data[i]?.kyc ?? 0
					d.active = data?.data[i]?.active ?? 0
					d.lendbox = data?.data[i]?.lendbox ?? 0
					userMonthlyDataList.push(d)
				}
				userMonthlyDataList.sort((a: any, b: any) => {
					if (a.monthCode > b.monthCode) { return 1 }
					if (a.monthCode < b.monthCode) { return -1 }
					return 0
				})
				let totalTotal = 0
				let kycTotal = 0
				let lendboxTotal = 0
				for (let x = 0; x < userMonthlyDataList.length; x++) {
					userMonthlyDataList[x].totalString = (userMonthlyDataList[x].total ?? 0) === 0 ? 'No new users' : numberFormat(userMonthlyDataList[x].total) + ' new user' + ((userMonthlyDataList[x].total) > 0 ? 's' : '')
					userMonthlyDataList[x].kycString = numberFormat(userMonthlyDataList[x].kyc)
					userMonthlyDataList[x].lendboxString = numberFormat(userMonthlyDataList[x].lendbox)
					userMonthlyDataList[x].activeString = numberFormat(userMonthlyDataList[x].active)
					userMonthlyDataList[x].totalValue = userMonthlyDataList[x].total
					userMonthlyDataList[x].kycValue = userMonthlyDataList[x].kyc
					userMonthlyDataList[x].lendboxValue = userMonthlyDataList[x].lendbox
					userMonthlyDataList[x].activeValue = userMonthlyDataList[x].active
					totalTotal += userMonthlyDataList[x].total
					kycTotal += userMonthlyDataList[x].kyc
					lendboxTotal += userMonthlyDataList[x].lendbox
					userMonthlyDataList[x].total = totalTotal
					userMonthlyDataList[x].kyc = kycTotal
					userMonthlyDataList[x].lendbox = lendboxTotal
				}
				setUserMonthlyData(userMonthlyDataList)
			}
		)
	}
	const getUserLendboxGraphData = useRef(() => {
		// 
	})

	getUserLendboxGraphData.current = async () => {
		const usersLendboxGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(props.startDate.toDate(), endDate, statsController.getUserLendboxGraphData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersLendboxGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0
			})
		})
		let total = 0
		for (let i = 0; i < usersLendboxGraphData.length; i++) {
			if (usersLendboxGraphData[i].total === 0) {
				usersLendboxGraphData[i].totalString = 'No new KYC Verifications complete'
			}
			else {
				usersLendboxGraphData[i].totalString = `${numberFormat(usersLendboxGraphData[i].total)} new KYC verification${usersLendboxGraphData[i].total > 1 ? 's' : ''} complete`
			}
			usersLendboxGraphData[i].totalValue = usersLendboxGraphData[i].total
			total += usersLendboxGraphData[i]?.total ?? 0
			usersLendboxGraphData[i].total = total
		}
		setUsersLendboxGraphData(usersLendboxGraphData)
	}

	const getUserTotalGraphData = useRef(() => {
		// 
	})

	getUserTotalGraphData.current = async () => {
		const usersTotalGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(props.startDate.toDate(), endDate, statsController.getUserTotalStatsData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersTotalGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0
			})
		})
		let total = 0
		for (let i = 0; i < usersTotalGraphData.length; i++) {
			if (usersTotalGraphData[i].total === 0) {
				usersTotalGraphData[i].totalString = 'No new users'
			}
			else {
				usersTotalGraphData[i].totalString = `${numberFormat(usersTotalGraphData[i].total)} new user${usersTotalGraphData[i].total > 1 ? 's' : ''}`
			}
			usersTotalGraphData[i].totalValue = usersTotalGraphData[i].total
			total += usersTotalGraphData[i]?.total ?? 0
			usersTotalGraphData[i].total = total
		}
		setUsersTotalGraphData(usersTotalGraphData)
	}
	const getUserPausedGraphData = useRef(() => {
		// 
	})

	getUserPausedGraphData.current = async () => {
		const usersPausedGraphData: any[] = []
		await statsDataHandler(props.startDate.toDate(), props.endDate.toDate(), statsController.getUserPausedStatsData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersPausedGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0,
				lendbox: (data[day]?.lendbox) ?? 0,
				normalLendbox: (data[day]?.normalLendbox) ?? (((data[day]?.thresholdLendbox) === null) ? 0 : ((data[day]?.lendbox) ?? 0)),
				thresholdLendbox: (data[day]?.thresholdLendbox) ?? 0,
				yesbankMandatePaused: (data[day]?.yesbankMandatePaused) ?? 0,
			})
		})
		let minValue = null
		for (let i = 0; i < usersPausedGraphData.length; i++) {
			if (i === 0) {
				minValue = usersPausedGraphData[i].total
			} else if (usersPausedGraphData[i].total < minValue) {
				minValue = usersPausedGraphData[i].total
			}
		}
		for (let i = 0; i < usersPausedGraphData.length; i++) {
			if (usersPausedGraphData[i].total === 0) {
				usersPausedGraphData[i].totalString = 'No Paused Users'
			}
			else {
				usersPausedGraphData[i].totalString = `${numberFormat(usersPausedGraphData[i].total)} Paused User${usersPausedGraphData[i].total > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].totalValue = usersPausedGraphData[i].total
			if (usersPausedGraphData[i].lendbox === 0) {
				usersPausedGraphData[i].lendboxString = 'No Paused Users'
			}
			else {
				usersPausedGraphData[i].lendboxString = `${numberFormat(usersPausedGraphData[i].lendbox)} Paused User${usersPausedGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].lendboxValue = usersPausedGraphData[i].lendbox
			if (usersPausedGraphData[i].thresholdLendbox === 0) {
				usersPausedGraphData[i].thresholdLendboxString = 'No Roundup and Daily Deposit Paused Users'
			}
			else {
				usersPausedGraphData[i].thresholdLendboxString = `${numberFormat(usersPausedGraphData[i].thresholdLendbox)} Roundup and Daily Deposit Paused User${usersPausedGraphData[i].thresholdLendbox > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].thresholdLendboxValue = usersPausedGraphData[i].thresholdLendbox
			if (usersPausedGraphData[i].normalLendbox === 0) {
				usersPausedGraphData[i].normalLendboxString = 'No Normal Paused Users'
			}
			else {
				usersPausedGraphData[i].normalLendboxString = `${numberFormat(usersPausedGraphData[i].normalLendbox)} Normal Paused User${usersPausedGraphData[i].normalLendbox > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].normalLendboxValue = usersPausedGraphData[i].normalLendbox
			if (usersPausedGraphData[i].yesbankMandatePaused === 0) {
				usersPausedGraphData[i].yesbankMandatePausedString = 'No Mandate Paused Users'
			}
			else {
				usersPausedGraphData[i].yesbankMandatePausedString = `${numberFormat(usersPausedGraphData[i].yesbankMandatePaused)} Mandate Paused User${usersPausedGraphData[i].yesbankMandatePaused > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].yesbankMandatePausedValue = usersPausedGraphData[i].yesbankMandatePaused
			// usersPausedGraphData[i].lendbox -= usersPausedGraphData[i].normalLendbox + usersPausedGraphData[i].thresholdLendbox
		}
		setUsersPausedGraphData(usersPausedGraphData)
	}

	const getUserOnboardedGraphData = useRef(() => {
		// 
	})

	getUserOnboardedGraphData.current = async () => {
		const usersOnboardedGraphData: any[] = []
		await statsDataHandler(props.startDate.toDate(), props.endDate.toDate(), statsController.getUserOnboardedStatsData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersOnboardedGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0,
				lendbox: (data[day]?.lendbox) ?? 0,
			})
		})
		let minValue = null
		for (let i = 0; i < usersOnboardedGraphData.length; i++) {
			if (i === 0) {
				minValue = usersOnboardedGraphData[i].total
			} else if (usersOnboardedGraphData[i].total < minValue) {
				minValue = usersOnboardedGraphData[i].total

			}
		}
		for (let i = 0; i < usersOnboardedGraphData.length; i++) {
			if (usersOnboardedGraphData[i].total === 0) {
				usersOnboardedGraphData[i].totalString = 'No Onboarded Users'
			}
			else {
				usersOnboardedGraphData[i].totalString = `${numberFormat(usersOnboardedGraphData[i].total)} Onboarded User${usersOnboardedGraphData[i].total > 1 ? 's' : ''}`
			}
			if (usersOnboardedGraphData[i].lendbox === 0) {
				usersOnboardedGraphData[i].lendboxString = 'No Onboarded Users'
			}
			else {
				usersOnboardedGraphData[i].lendboxString = `${numberFormat(usersOnboardedGraphData[i].lendbox)} Onboarded User${usersOnboardedGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersOnboardedGraphData[i].totalValue = usersOnboardedGraphData[i].total
		}
		setUsersOnboardedGraphData(usersOnboardedGraphData)
	}

	const getUserPreDebitNotificationsGraphData = useRef(() => {
		// 
	})

	getUserPreDebitNotificationsGraphData.current = async () => {
		const usersPreDebitNotificationsGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(props.startDate.toDate(), endDate, statsController.getUserPreDebitNotificationsStatsData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersPreDebitNotificationsGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0,
			})
		})
		let minValue = null
		for (let i = 0; i < usersPreDebitNotificationsGraphData.length; i++) {
			if (i === 0) {
				minValue = usersPreDebitNotificationsGraphData[i].total
			} else if (usersPreDebitNotificationsGraphData[i].total < minValue) {
				minValue = usersPreDebitNotificationsGraphData[i].total
			}
		}
		for (let i = 0; i < usersPreDebitNotificationsGraphData.length; i++) {
			if (usersPreDebitNotificationsGraphData[i].total === 0) {
				usersPreDebitNotificationsGraphData[i].totalString = 'No Pre Debit Notifications'
			}
			else {
				usersPreDebitNotificationsGraphData[i].totalString = `${numberFormat(usersPreDebitNotificationsGraphData[i].total)} Pre Debit Notification${usersPreDebitNotificationsGraphData[i].total > 1 ? 's' : ''}`
			}
			usersPreDebitNotificationsGraphData[i].totalValue = usersPreDebitNotificationsGraphData[i].total
		}
		setUsersPreDebitNotificationsGraphData(usersPreDebitNotificationsGraphData)
	}

	const getUserPreDebitNotificationsAmountGraphData = useRef(() => {
		// 
	})

	getUserPreDebitNotificationsAmountGraphData.current = async () => {
		const usersPreDebitNotificationsAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(props.startDate.toDate(), endDate, statsController.getUserPreDebitNotificationsAmountStatsData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			usersPreDebitNotificationsAmountGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0,
			})
		})
		let minValue = null
		for (let i = 0; i < usersPreDebitNotificationsAmountGraphData.length; i++) {
			if (i === 0) {
				minValue = usersPreDebitNotificationsAmountGraphData[i].total
			} else if (usersPreDebitNotificationsAmountGraphData[i].total < minValue) {
				minValue = usersPreDebitNotificationsAmountGraphData[i].total
			}
		}
		for (let i = 0; i < usersPreDebitNotificationsAmountGraphData.length; i++) {
			usersPreDebitNotificationsAmountGraphData[i].totalString = currencyFormat(usersPreDebitNotificationsAmountGraphData[i].total ?? 0)
			usersPreDebitNotificationsAmountGraphData[i].totalValue = usersPreDebitNotificationsAmountGraphData[i].total
		}
		setUsersPreDebitNotificationsAmountGraphData(usersPreDebitNotificationsAmountGraphData)
	}

	const getJuspayFirstExecutionsAmountGraphData = useRef(() => {
		// 
	})

	getJuspayFirstExecutionsAmountGraphData.current = async () => {
		const JuspayFirstExecutionAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(props.startDate.toDate(), endDate, statsController.getJuspayFirstExecutionsAmountGraphData, (data: any, date: Date) => {
			const day = String(date.getDate())
			const month = String(date.getMonth() + 1)
			const year = String(date.getFullYear())
			JuspayFirstExecutionAmountGraphData.push({
				date: day + '/' + month + '/' + year,
				showDate: day + '/' + month + '/' + year,
				total: (data[day]?.total) ?? 0,
				PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
				PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
			})
		})
		for (let i = 0; i < JuspayFirstExecutionAmountGraphData.length; i++) {
			JuspayFirstExecutionAmountGraphData[i].totalString = `Total: ${currencyFormat(JuspayFirstExecutionAmountGraphData[i].total)}`
			JuspayFirstExecutionAmountGraphData[i].totalValue = JuspayFirstExecutionAmountGraphData[i].total
			JuspayFirstExecutionAmountGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${currencyFormat(JuspayFirstExecutionAmountGraphData[i].PLF_EHGPG)}`
			JuspayFirstExecutionAmountGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${currencyFormat(JuspayFirstExecutionAmountGraphData[i].PLF_MCGPG)}`
		}
		setJuspayFirstExecutionAmountGraphData(JuspayFirstExecutionAmountGraphData)
	}

	const getJuspayFirstExecutionGraphData = useRef(() => {
		// 
	})

	getJuspayFirstExecutionGraphData.current = async () => {
		const JuspayFirstExecutionGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayFirstExecutionGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayFirstExecutionGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayFirstExecutionGraphData.length; i++) {
			JuspayFirstExecutionGraphData[i].totalString = `Total: ${numberFormat(JuspayFirstExecutionGraphData[i].total)}`
			JuspayFirstExecutionGraphData[i].totalValue = JuspayFirstExecutionGraphData[i].total
			JuspayFirstExecutionGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${numberFormat(JuspayFirstExecutionGraphData[i].PLF_EHGPG)}`
			JuspayFirstExecutionGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${numberFormat(JuspayFirstExecutionGraphData[i].PLF_MCGPG)}`
		}
		setJuspayFirstExecutionGraphData(JuspayFirstExecutionGraphData)
	}

	const getJuspayMandateExecutionGraphData = useRef(() => {
		// 
	})

	getJuspayMandateExecutionGraphData.current = async () => {
		const JuspayMandateExecutionGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayMandateExecutionGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayMandateExecutionGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayMandateExecutionGraphData.length; i++) {
			JuspayMandateExecutionGraphData[i].totalString = `Total: ${numberFormat(JuspayMandateExecutionGraphData[i].total)}`
			JuspayMandateExecutionGraphData[i].totalValue = JuspayMandateExecutionGraphData[i].total
			JuspayMandateExecutionGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${numberFormat(JuspayMandateExecutionGraphData[i].PLF_EHGPG)}`
			JuspayMandateExecutionGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${numberFormat(JuspayMandateExecutionGraphData[i].PLF_MCGPG)}`
		}
		setJuspayMandateExecutionGraphData(JuspayMandateExecutionGraphData)
	}
	const getJuspayMandateExecutionAmountGraphData = useRef(() => {
		// 
	})

	getJuspayMandateExecutionAmountGraphData.current = async () => {
		const JuspayMandateExecutionAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayMandateExecutionAmountGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayMandateExecutionAmountGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayMandateExecutionAmountGraphData.length; i++) {
			JuspayMandateExecutionAmountGraphData[i].totalString = `Total: ${currencyFormat(JuspayMandateExecutionAmountGraphData[i].total)}`
			JuspayMandateExecutionAmountGraphData[i].totalValue = JuspayMandateExecutionAmountGraphData[i].total
			JuspayMandateExecutionAmountGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${currencyFormat(JuspayMandateExecutionAmountGraphData[i].PLF_EHGPG)}`
			JuspayMandateExecutionAmountGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${currencyFormat(JuspayMandateExecutionAmountGraphData[i].PLF_MCGPG)}`
		}
		setJuspayMandateExecutionAmountGraphData(JuspayMandateExecutionAmountGraphData)
	}

	const getJuspayNotificationGraphData = useRef(() => {
		// 
	})

	getJuspayNotificationGraphData.current = async () => {
		const JuspayNotificationGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayNotificationGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayNotificationGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayNotificationGraphData.length; i++) {
			JuspayNotificationGraphData[i].totalString = `Total: ${numberFormat(JuspayNotificationGraphData[i].total)}`
			JuspayNotificationGraphData[i].totalValue = JuspayNotificationGraphData[i].total
			JuspayNotificationGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${numberFormat(JuspayNotificationGraphData[i].PLF_EHGPG)}`
			JuspayNotificationGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${numberFormat(JuspayNotificationGraphData[i].PLF_MCGPG)}`
		}
		setJuspayNotificationGraphData(JuspayNotificationGraphData)
	}
	const getJuspayNotificationAmountGraphData = useRef(() => {
		// 
	})

	getJuspayNotificationAmountGraphData.current = async () => {
		const JuspayNotificationAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayNotificationAmountGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayNotificationAmountGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayNotificationAmountGraphData.length; i++) {
			JuspayNotificationAmountGraphData[i].totalString = `Total: ${currencyFormat(JuspayNotificationAmountGraphData[i].total)}`
			JuspayNotificationAmountGraphData[i].totalValue = JuspayNotificationAmountGraphData[i].total
			JuspayNotificationAmountGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${currencyFormat(JuspayNotificationAmountGraphData[i].PLF_EHGPG)}`
			JuspayNotificationAmountGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${currencyFormat(JuspayNotificationAmountGraphData[i].PLF_MCGPG)}`
		}
		setJuspayNotificationAmountGraphData(JuspayNotificationAmountGraphData)
	}

	const getJuspayLumpsumGraphData = useRef(() => {
		// 
	})

	getJuspayLumpsumGraphData.current = async () => {
		const JuspayLumpsumGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayLumpsumGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayLumpsumGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayLumpsumGraphData.length; i++) {
			JuspayLumpsumGraphData[i].totalString = `Total: ${numberFormat(JuspayLumpsumGraphData[i].total)}`
			JuspayLumpsumGraphData[i].totalValue = JuspayLumpsumGraphData[i].total
			JuspayLumpsumGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${numberFormat(JuspayLumpsumGraphData[i].PLF_EHGPG)}`
			JuspayLumpsumGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${numberFormat(JuspayLumpsumGraphData[i].PLF_MCGPG)}`
		}
		setJuspayLumpsumGraphData(JuspayLumpsumGraphData)
	}

	const getJuspayLumpsumAmountGraphData = useRef(() => {
		// 
	})

	getJuspayLumpsumAmountGraphData.current = async () => {
		const JuspayLumpsumAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getJuspayLumpsumAmountGraphData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				JuspayLumpsumAmountGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					PLF_EHGPG: (data[day]?.funds?.['PLF-EHGPG']) ?? 0,
					PLF_MCGPG: (data[day]?.funds?.['PLF-MCGPG']) ?? 0,
				})
			})
		for (let i = 0; i < JuspayLumpsumAmountGraphData.length; i++) {
			JuspayLumpsumAmountGraphData[i].totalString = `Total: ${currencyFormat(JuspayLumpsumAmountGraphData[i].total)}`
			JuspayLumpsumAmountGraphData[i].totalValue = JuspayLumpsumAmountGraphData[i].total
			JuspayLumpsumAmountGraphData[i].PLF_EHGPG_String = `Navi Equity Hybrid Fund: ${currencyFormat(JuspayLumpsumAmountGraphData[i].PLF_EHGPG)}`
			JuspayLumpsumAmountGraphData[i].PLF_MCGPG_String = `Navi Large & Midcap Fund: ${currencyFormat(JuspayLumpsumAmountGraphData[i].PLF_MCGPG)}`
		}
		setJuspayLumpsumAmountGraphData(JuspayLumpsumAmountGraphData)
	}

	const getUserMandateExecutionsGraphData = useRef(() => {
		// 
	})

	getUserMandateExecutionsGraphData.current = async () => {
		const usersMandateExecutionsGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getUserMandateExecutionsStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersMandateExecutionsGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersMandateExecutionsGraphData.length; i++) {
			if (i === 0) {
				minValue = usersMandateExecutionsGraphData[i].total
			} else if (usersMandateExecutionsGraphData[i].total < minValue) {
				minValue = usersMandateExecutionsGraphData[i].total
			}
		}
		for (let i = 0; i < usersMandateExecutionsGraphData.length; i++) {
			if (usersMandateExecutionsGraphData[i].total === 0) {
				usersMandateExecutionsGraphData[i].totalString = 'No Mandate Executions'
			}
			else {
				usersMandateExecutionsGraphData[i].totalString = `${numberFormat(usersMandateExecutionsGraphData[i].total)} Mandate Execution${usersMandateExecutionsGraphData[i].total > 1 ? 's' : ''}`
			}
			usersMandateExecutionsGraphData[i].totalValue = usersMandateExecutionsGraphData[i].total
		}
		setUsersMandateExecutionsGraphData(usersMandateExecutionsGraphData)
	}

	const getUserMandateExecutionsAmountGraphData = useRef(() => {
		// 
	})

	getUserMandateExecutionsAmountGraphData.current = async () => {
		const usersMandateExecutionsAmountGraphData: any[] = []
		let endDate = props.endDate.toDate()
		const yesterday = new Date(Date.now())
		yesterday.setDate(yesterday.getDate() - 1)
		if (endDate.getFullYear() === yesterday.getFullYear() && endDate.getMonth() === yesterday.getMonth() && endDate.getDate() === yesterday.getDate()) {
			endDate = new Date(Date.now())
		}
		await statsDataHandler(
			props.startDate.toDate(),
			endDate,
			statsController.getUserMandateExecutionsAmountStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersMandateExecutionsAmountGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersMandateExecutionsAmountGraphData.length; i++) {
			if (i === 0) {
				minValue = usersMandateExecutionsAmountGraphData[i].total
			} else if (usersMandateExecutionsAmountGraphData[i].total < minValue) {
				minValue = usersMandateExecutionsAmountGraphData[i].total
			}
		}
		for (let i = 0; i < usersMandateExecutionsAmountGraphData.length; i++) {
			usersMandateExecutionsAmountGraphData[i].totalString = currencyFormat(usersMandateExecutionsAmountGraphData[i].total ?? 0)
			usersMandateExecutionsAmountGraphData[i].totalValue = usersMandateExecutionsAmountGraphData[i].total
		}
		setUsersMandateExecutionsAmountGraphData(usersMandateExecutionsAmountGraphData)
	}

	const getUserActiveGraphData = useRef(() => {
		// 
	})

	getUserActiveGraphData.current = async () => {
		const usersActiveGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getUserActiveStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersActiveGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersActiveGraphData.length; i++) {
			if (i === 0) {
				minValue = usersActiveGraphData[i].total
			} else if (usersActiveGraphData[i].total < minValue) {
				minValue = usersActiveGraphData[i].total
			}
		}
		for (let i = 0; i < usersActiveGraphData.length; i++) {
			if (usersActiveGraphData[i].total === 0) {
				usersActiveGraphData[i].totalString = 'No Active Users'
			}
			else {
				usersActiveGraphData[i].totalString = `${numberFormat(usersActiveGraphData[i].total)} Active User${usersActiveGraphData[i].total > 1 ? 's' : ''}`
			}
			usersActiveGraphData[i].totalValue = usersActiveGraphData[i].total
		}
		setUsersActiveGraphData(usersActiveGraphData)
	}

	const getUserRoundupGraphData = useRef(() => {
		// 
	})

	getUserRoundupGraphData.current = async () => {
		const usersRoundUpGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getUserRoundUpStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersRoundUpGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					lendbox: (data[day]?.lendbox) ?? 0,
					lendboxValues: (data[day]?.lendboxValues) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersRoundUpGraphData.length; i++) {
			if (i === 0) {
				minValue = usersRoundUpGraphData[i].total
			} else if (usersRoundUpGraphData[i].total < minValue) {
				minValue = usersRoundUpGraphData[i].total
			}
		}
		for (let i = 0; i < usersRoundUpGraphData.length; i++) {
			if (usersRoundUpGraphData[i].total === 0) {
				usersRoundUpGraphData[i].totalString = 'No Active Round-Up Users'
			}
			else {
				usersRoundUpGraphData[i].totalString = `${numberFormat(usersRoundUpGraphData[i].total)} Active Round-Up User${usersRoundUpGraphData[i].total > 1 ? 's' : ''}`
			}
			usersRoundUpGraphData[i].totalValue = usersRoundUpGraphData[i].total
			if (usersRoundUpGraphData[i].lendbox === 0) {
				usersRoundUpGraphData[i].lendboxString = 'No Active Round-Up Users'
			}
			else {
				usersRoundUpGraphData[i].lendboxString = `${numberFormat(usersRoundUpGraphData[i].lendbox)} Active Round-Up User${usersRoundUpGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersRoundUpGraphData[i].lendboxValue = usersRoundUpGraphData[i].lendbox
		}
		setUsersRoundUpGraphData(usersRoundUpGraphData)
	}

	const getUserDailyDepositGraphData = useRef(() => {
		// 
	})

	getUserDailyDepositGraphData.current = async () => {
		const usersDailyDepositGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getUserDailyDepositStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersDailyDepositGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					lendbox: (data[day]?.lendbox) ?? 0,
					lendboxValues: (data[day]?.lendboxValues) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersDailyDepositGraphData.length; i++) {
			if (i === 0) {
				minValue = usersDailyDepositGraphData[i].total
			} else if (usersDailyDepositGraphData[i].total < minValue) {
				minValue = usersDailyDepositGraphData[i].total
			}
		}
		for (let i = 0; i < usersDailyDepositGraphData.length; i++) {
			if (usersDailyDepositGraphData[i].total === 0) {
				usersDailyDepositGraphData[i].totalString = 'No Active Daily Deposit Users'
			}
			else {
				usersDailyDepositGraphData[i].totalString = `${numberFormat(usersDailyDepositGraphData[i].total)} Active Daily Deposit User${usersDailyDepositGraphData[i].total > 1 ? 's' : ''}`
			}
			usersDailyDepositGraphData[i].totalValue = usersDailyDepositGraphData[i].total
			if (usersDailyDepositGraphData[i].lendbox === 0) {
				usersDailyDepositGraphData[i].lendboxString = 'No Active Daily Deposit Users'
			}
			else {
				usersDailyDepositGraphData[i].lendboxString = `${numberFormat(usersDailyDepositGraphData[i].lendbox)} Active Daily Deposit User${usersDailyDepositGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersDailyDepositGraphData[i].lendboxValue = usersDailyDepositGraphData[i].lendbox
		}
		setUsersDailyDepositGraphData(usersDailyDepositGraphData)
	}

	const getUserContactGraphData = useRef(() => {
		// 
	})

	getUserContactGraphData.current = async () => {
		const usersContactGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getUserContactStatsData,
			(data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				usersContactGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
					email: (data[day]?.email) ?? 0,
					phone: (data[day]?.phone) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < usersContactGraphData.length; i++) {
			if (i === 0) {
				minValue = usersContactGraphData[i].total
			} else if (usersContactGraphData[i].total < minValue) {
				minValue = usersContactGraphData[i].total
			}
		}
		for (let i = 0; i < usersContactGraphData.length; i++) {
			if (usersContactGraphData[i].total === 0) {
				usersContactGraphData[i].totalString = 'No Users'
			}
			else {
				usersContactGraphData[i].totalString = `${numberFormat(usersContactGraphData[i].total)} User${usersContactGraphData[i].total > 1 ? 's' : ''} with both Email and Phone`
			}
			usersContactGraphData[i].totalValue = usersContactGraphData[i].total
			if (usersContactGraphData[i].email === 0) {
				usersContactGraphData[i].emailString = 'No Users with Phone'
			}
			else {
				usersContactGraphData[i].emailString = `${numberFormat(usersContactGraphData[i].email)} User${usersContactGraphData[i].email > 1 ? 's' : ''} with Email`
			}
			usersContactGraphData[i].emailValue = usersContactGraphData[i].email
			if (usersContactGraphData[i].phone === 0) {
				usersContactGraphData[i].phoneString = 'No Users with Phone'
			}
			else {
				usersContactGraphData[i].phoneString = `${numberFormat(usersContactGraphData[i].phone)} User${usersContactGraphData[i].phone > 1 ? 's' : ''} with Phone`
			}
			usersContactGraphData[i].phoneValue = usersContactGraphData[i].phone
		}
		setUsersContactGraphData(usersContactGraphData)
	}
	function copyIconAction(cardHeaderClass: SxProps, copyValue: string) {
		return (< ButtonGroup variant="text" size="large" >
			<Button
				sx={cardHeaderClass}
				onClick={() => copyToClipboard(copyValue)}>
				<Icon>
					<FileCopyIcon />
				</Icon>
			</Button>
		</ButtonGroup>)
	}

	return (<>

		<Grid item xs={12} sm={11} direction="row-reverse">
		</Grid>
		<Grid item xs={12} sm={1} direction="row-reverse">
			<IconButton onClick={getData} size="large">
				<RefreshIcon />
			</IconButton>
		</Grid>
		{/* <Grid item xs={12} sm={12}>
		<Typography variant="h4" component="h4" gutterBottom>
  			Total Users
		</Typography>
		</Grid> */}

		<Grid item xs={12} sm={12}>

			<Card elevation={0} sx={classes.mainCard} >
				<Button
					component={Link}
					to={'/stats/search/total'}
					style={{ textDecoration: 'none', textTransform: 'none' }}
				>
					<SearchRounded sx={classes.total} />

					<CardHeader
						sx={classes.total}
						subheader="Total Users With Authentication Success"
						title={numberFormat(usersStats?.total ?? 0) + ' (+' + numberFormat(usersTotalGraphData?.[usersTotalGraphData.length - 1]?.totalValue ?? 0) + ' today)'}
					/>
				</Button>
			</Card>

		</Grid>

		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card} >
				<CardHeader
					subheader="Daily (Last 30 days)"
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={classes.total}
								onClick={() => userTotalGraphRef.current.setLineGraph()}>
								<Icon>
									<ShowChartIcon />
								</Icon>
							</Button>
							<Button
								sx={classes.total}
								onClick={() => userTotalGraphRef.current.setBarGraph()}
							>
								<Icon >
									<BarChartIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<UserDailyGraph ref={userTotalGraphRef} data={usersTotalGraphData} color={Colors.secondary.mint400} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}								>
				<CardHeader
					subheader="Monthly"
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={classes.total}
								onClick={() => userTotalMonthlyGraphRef.current.setLineGraph()}>
								<Icon>
									<ShowChartIcon />
								</Icon>
							</Button>
							<Button
								sx={classes.total}
								onClick={() => userTotalMonthlyGraphRef.current.setBarGraph()}
							>
								<Icon >
									<BarChartIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<UserStatGraph ref={userTotalMonthlyGraphRef} data={userMonthlyData} dataKey='total' color={Colors.secondary.mint400} />
				</CardContent>
			</Card>
		</Grid>

		<Grid item xs={12} sm={12}>
			<Card elevation={0} sx={classes.mainCard} >
				<Button
					component={Link}
					to={'/stats/search/lendbox'}
					style={{ textDecoration: 'none', textTransform: 'none' }}
				>
					<SearchRounded sx={classes.kyc} />
					<CardHeader
						sx={classes.kyc}
						subheader="Lendbox - KYC Success"
						title={numberFormat(usersStats?.lendbox ?? 0) + ' (+' + numberFormat(usersLendboxGraphData?.[usersLendboxGraphData.length - 1]?.totalValue ?? 0) + ' today)'}
					/>
					<CardContent>
					</CardContent>
				</Button>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card} >
				<CardHeader
					subheader="Daily (Last 30 days)"
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={classes.kyc}
								onClick={() => userLendboxGraphRef.current.setLineGraph()}>
								<Icon>
									<ShowChartIcon />
								</Icon>
							</Button>
							<Button
								sx={classes.kyc}
								onClick={() => userLendboxGraphRef.current.setBarGraph()}
							>
								<Icon >
									<BarChartIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<UserDailyGraph ref={userLendboxGraphRef} data={usersLendboxGraphData} color={Colors.secondary.orange300} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}								>
				<CardHeader
					subheader="Monthly"
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={classes.kyc}
								onClick={() => userLendboxMonthlyGraphRef.current.setLineGraph()}>
								<Icon>
									<ShowChartIcon />
								</Icon>
							</Button>
							<Button
								sx={classes.kyc}
								onClick={() => userLendboxMonthlyGraphRef.current.setBarGraph()}
							>
								<Icon >
									<BarChartIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<UserStatGraph ref={userLendboxMonthlyGraphRef} data={userMonthlyData} dataKey='lendbox' color={Colors.secondary.orange300} />
				</CardContent>
			</Card>
		</Grid>
		<StatsAreaGraphCard
			data={usersActiveGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.active?.total ?? 0)}
			subheader="Weekly Active User Count (User who has executed a mandate successfully in last 4 cycles / 8 days)" />
		<StatsAreaGraphCard
			data={usersOnboardedGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.onboarded?.lendbox ?? 0)}
			subheader="Lendbox - Onboarded Users Count KYC (KYC Success + AMS Success)"
			dataKey="lendbox" />
		<LendboxPausedStats
			data={usersPausedGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={numberFormat(usersStats?.paused?.lendbox ?? 0)}
			subheader="Lendbox Paused User Count (List of Onboarded Users who have Paused their account)" />

		<Grid item xs={12} sm={6} />

		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}>
				<CardHeader
					sx={classes.userStats}
					subheader="Yesbank Pre Debit Notifications"
					title={numberFormat(usersStats?.yesbankPreDebitNotification ?? 0)}
					action={copyIconAction(classes.userStats, `${usersStats?.yesbankPreDebitNotification ?? 0}`)}
				/>
				<CardContent sx={classes.cardContent}>
					< StatsBarGraph data={usersPreDebitNotificationsGraphData} color={Colors.primary.lime400} dataKey={'total'} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}>
				<CardHeader
					sx={classes.userStats}
					subheader="Yesbank Pre Debit Notification Amount"
					title={currencyFormat(usersStats?.yesbankPreDebitNotificationAmount ?? 0)}
					action={copyIconAction(classes.userStats, `${usersStats?.yesbankPreDebitNotificationAmount ?? 0}`)}
				/>
				<CardContent sx={classes.cardContent}>
					< StatsBarGraph data={usersPreDebitNotificationsAmountGraphData} color={Colors.primary.lime400} dataKey={'total'} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}>
				<CardHeader
					sx={classes.userStats}
					subheader="Yesbank Mandates Executed"
					title={numberFormat(usersStats?.yesbankMandateExecution ?? 0)}
					action={copyIconAction(classes.userStats, `${usersStats?.yesbankMandateExecution ?? 0}`)}
				/>
				<CardContent sx={classes.cardContent}>
					< StatsBarGraph data={usersMandateExecutionsGraphData} color={Colors.primary.lime400} dataKey={'total'} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={classes.card}>
				<CardHeader
					sx={classes.userStats}
					subheader="Yesbank Mandates Executed Amount"
					title={currencyFormat(usersStats?.yesbankMandateExecutionAmount ?? 0)}
					action={copyIconAction(classes.userStats, `${usersStats?.yesbankMandateExecutionAmount ?? 0}`)}
				/>
				<CardContent sx={classes.cardContent}>
					< StatsBarGraph data={usersMandateExecutionsAmountGraphData} color={Colors.primary.lime400} dataKey={'total'} />
				</CardContent>
			</Card>
		</Grid>

		<JuspayUserStats
			data={JuspayNotificationGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${usersStats?.juspayNotification?.total ?? 0}`}
			subheader="Juspay Notifications"
		/>
		<JuspayUserStats
			data={JuspayNotificationAmountGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${currencyFormat(usersStats?.juspayNotificationAmount?.total ?? 0)}`}
			subheader="Juspay Notifications Amount"
		/>
		<JuspayUserStats
			data={JuspayMandateExecutionGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${usersStats?.juspayMandateExecution?.total ?? 0}`}
			subheader="Juspay Mandates Executed"
		/>
		<JuspayUserStats
			data={JuspayMandateExecutionAmountGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${currencyFormat(usersStats?.juspayMandateExecutionAmount?.total ?? 0)}`}
			subheader="Juspay Mandates Executed Amount"
		/>
		<JuspayUserStats
			data={JuspayFirstExecutionGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${usersStats?.juspayFirstExecution?.total ?? 0}`}
			subheader="Juspay First Execution "
		/>
		<JuspayUserStats
			data={JuspayFirstExecutionAmountGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${currencyFormat(usersStats?.juspayFirstExecutionAmount?.total ?? 0)}`}
			subheader="Juspay First Execution Amount"
		/>
		<JuspayUserStats
			data={JuspayLumpsumGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${usersStats?.juspayLumpsum?.total ?? 0}`}
			subheader="Juspay Lumpsum"
		/>
		<JuspayUserStats
			data={JuspayLumpsumAmountGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			title={`${currencyFormat(usersStats?.juspayLumpsumAmount?.total ?? 0)}`}
			subheader="Juspay Lumpsum Amount"
		/>
		<StatsAreaGraphCard
			data={usersRoundUpGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.roundup?.lendbox ?? 0)}
			subheader="Active Round-Up (Number of lendbox onboarding completed users with round-up set)"
			dataKey="lendbox"
			actions={<Button
				sx={classes.userStats}
				onClick={() => copyToClipboard('copyValue')}>
				<Icon>
					<ArrowForwardIosIcon />
				</Icon>
			</Button>} />
		<StatsAreaGraphCard
			data={usersDailyDepositGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.dailyDeposit?.lendbox ?? 0)}
			subheader="Active Daily Deposit (Number of lendbox onboarding completed users with daily deposit set)"
			dataKey="lendbox" />
		<StatsAreaGraphCard
			data={usersContactGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.contact?.email ?? 0)}
			subheader="Total Users with Email"
			dataKey="email" />
		<StatsAreaGraphCard
			data={usersContactGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.contact?.phone ?? 0)}
			subheader="Total Users with Phone"
			dataKey="phone" />
		<StatsAreaGraphCard
			data={usersContactGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.userStats}
			color={Colors.primary.lime400}
			title={numberFormat(usersStats?.contact?.total ?? 0)}
			subheader="Total Users with both Email and Phone"
			dataKey="total" />
	</>)
}

export default memo(UsersStats)
