import { doc, onSnapshot } from 'firebase/firestore'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { db } from 'services/firebase'

const FundDataContext = createContext<any>(null)

export function useFundData() {
	return useContext(FundDataContext)
}

export function FundDataProvider(props: { children: any }) {
	const [fundData, setFundData] = useState<FundData | null | undefined>(undefined)

	useEffect(() => {
		const fetchData = () => {
			getFundDataRef.current()
		}
		return fetchData()
	}, [])

	const getFundDataRef = useRef(() => {
		// 
	})

	getFundDataRef.current = () => {
		onSnapshot(doc(db, 'keys/funds'), (snapshot: any) => {
			const snapshotData: any = snapshot.data()
			const data: FundData = {}
			Object.keys(snapshotData).map((key) => {
				const fundData = snapshotData[key]
				data[fundData.fundType] = {
					nav: fundData.nav ?? 0,
					name: fundData.scheme.name,
					shortName: fundData.scheme.shortName
				}
			})
			setFundData(data)
		}, (_error) => {
			setFundData(null)
		})
	}

	const value = {
		fundData
	}

	return (
		<FundDataContext.Provider value={value}>
			{props.children}
		</FundDataContext.Provider>
	)
}

export type FundData = {
	[key: string]: {
		nav: number
		name: string
		shortName: string
	}
}

