import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'services/firebase'

import APIController from './APIController'
import { FundType } from './TransactionController'


export interface OnboardingData {
	lendbox?: boolean,
	kyc?: boolean,
	ckyc?: boolean,
	fatca?: boolean,
	nominee?: boolean,
	yesbankMandateCompleted?: boolean,
	yesbankMandatePaused?: boolean,
	juspayMandate?: boolean,
	pennyDrop?: boolean,
	lendboxBankDetailsAdded: boolean,
	lendboxInvestmentPreferenceUpdated: boolean,
	kraStatus?: boolean,
	lendboxFixedForced?: boolean,
}

export interface Balance {
	folios: any,
	balance?: number,
	invested?: number,
	totalSaved?: number,
	totalWithdrawal?: number,
	inProgress?: number,
	inProcess?: number,
	pendingInvestment?: number,
	withdrawalInProgress?: number,
	roundup?: number,
	roundupSpends?: number,
	dailyDeposit?: number,
	dailyDepositDays?: number,
	netAUMGrowth?: number,
	directInvested?: number,
	totalInterestEarning?: number,
	availableToWithdraw?: number,
	currentInvestment?: number,
	interestPerDay?: number,
	amountWithdrawn?: number,
	runRate?: number,
	totalMandates?: number,
	totalTransactions?: number,
	units?: number,
	xirr?: number,
}



/**
 * Interface for UserData
 */


export interface UserData {
	uid: string,
	phone: string,
	email: string,
	gender?: string,
	age?: number,
	firstName?: string,
	lastName?: string,
	uuid?: string,
	dob?: number,
	roundup?: number,
	recurring?: number,
	lendboxBalance?: number,
	lendboxInvested?: number,
	lendboxTotalInterestEarning?: number,
	lendboxInterestPerDay?: number,
	lendboxTotalWithdrawal?: number,
	lendboxAvailableToWithdraw?: number,
	lendboxAmounWithdrawn?: number,
	lendboxInProgress?: number,
	lendboxTotalSaved?: number,
	lendboxWithdrawalInProgress?: number,
	lendboxRunRate?: number,
	lendboxTotalMandates?: number,
	totalRewardEarned?: number,
	activeDays: number,
	isAdmin?: boolean,
	onboardingIciciKycInProgress?: boolean,
	onboardingIcici?: boolean,
	onboardingIciciFirstTime?: boolean,
	onboardingMandate?: boolean,
	onboardingMandateCompleted?: boolean,
	onboardingYesbankMandateCompleted?: boolean,
	onboardingLendbox?: boolean,
	onboardingJuspayMandateCompleted?: boolean,
	accountPaused?: boolean,
	accountDeactivated?: boolean,
	accountDisabled?: boolean,
	reward?: number,
	rewardClaim?: boolean,
	rewardsCounter?: boolean,
	rewardsCounterRoundup?: number,
	active7DaysLogic?: number,
	smsPermission?: boolean,
	os?: string,
	installVersion?: string,
	version?: string,
	onboarding?: OnboardingData,
	createdAt?: any,
	updatedAt?: any,
	fundType?: string,
	balance?: {
		[key: string]: Balance | null | undefined
	},
	mandateLimit?: any,
	totalInvested?: number,
	totalWithdrawn?: number,
	totalRoundUps?: number,
	totalRoundUpSpends?: number,
	totalBalance?: number,
	totalMandates?: number,
	totalWithdrawalInProgress?: number,
	totalAvailableToWithdraw?: number,
	lendboxAppInvested?: number,
	lendboxAppTotalInterest?: number,
	lendboxInvestmentPreference: {
		timestamp: any
	}
	kraStatusCode?: string,
	batch: {
		yesbank?: number,
		juspay?: number,
		juspayExclude?: number,
		juspayInclude?: number,
		lendbox?: number
	}
}


export default class UsersController {
	processSnapshot(snapshot: any) {
		let usersList: UserData[] = snapshot.docs.map((doc: any) => {
			const data = doc.data()
			return this.processData(data, doc.id)
		})
		// Remove test users
		usersList = usersList.filter((value: UserData) => !(['+911111111111', '+918888888888'].includes(value?.phone ?? '')))

		return usersList
	}
	/**
* Get all mutualFunds of user with uid
* @param uid user id
* @param observer Handle changes on changes
*/
	getUsers(observer: (userData: UserData[]) => void) {
		onSnapshot(collection(db, 'users'), (snapshot: any) => {
			observer(this.processSnapshot(snapshot))
		})
	}

	getUsersByQuery(observer: (userData: UserData[]) => void, queries: any[]) {
		let reference: any = query(collection(db, 'users'))
		for (const i in queries) {
			reference = query(reference, where(queries[i].field, queries[i].operator, queries[i].value))
		}
		onSnapshot(reference, (snapshot: any) => {
			observer(this.processSnapshot(snapshot))
		})
	}
	async getUidFromLendboxUserId(lendboxUserId: string) {
		const data: any = (await getDoc(doc(db, `lbuserId/${lendboxUserId}`))).data()
		return data.uid
	}

	async getUidFromPan(pan: string) {
		const data: any = (await getDoc(doc(db, `ckyc/${pan.toUpperCase()}`))).data()
		return data.uid
	}

	async getUidFromFolio(folio: string) {
		for (const scheme of ['MCGPG', 'EHGPG']) {
			try {
				const data: any = (await getDoc(doc(db, `folios/PLF/${scheme}/${folio}`))).data()
				const uid = data.uid
				if (uid != null && uid != undefined) {
					return uid
				}
			} catch (e) {
				// continue
			}
		}
		return null
	}

	processData(data: any, uid: string) {

		data.id = uid
		data.uid = uid
		if (data.recurring == null) {
			data.recurring = 0
		}
		if (data.roundup == null) {
			data.roundup = 0
		}
		if (data.totalRewardEarned == null) {
			data.totalRewardEarned = 0
		}
		if (data.mutualFundBalance == null) {
			data.mutualFundBalance = 0
		}
		if (data.invested == null) {
			data.invested = 0
		}
		if (data.investmentValue == null) {
			data.investmentValue = 0
		}
		if (data.totalSavedSavvy == null) {
			data.totalSavedSavvy = 0
		}
		if (data.totalWithdrawnSavvy == null) {
			data.totalWithdrawnSavvy = 0
		}
		if (data.activeDays == null) {
			data.activeDays = 0
		}
		if (data.os == null) {
			data.os = 'Unknown'
		} else if (data.os === 'android') {
			data.os = 'Android'
		} else if (data.os === 'ios') {
			data.os = 'iOS'
		}
		// try{
		// 	data.gender = (data?.gender)
		// }
		// catch(e){
		// 	data.gender = "Unknown"
		// }
		try {
			data.onboardingLendbox = (data?.onboarding['lendbox'])
		} catch (e) {
			data.onboardingLendbox = false
		}
		try {
			data.onboardingIciciKycInProgress = (data?.onboarding['iciciKycInProgress'])
		} catch (e) {
			data.onboardingIciciKycInProgress = false
		}
		try {
			data.onboardingIcici = (data?.onboarding['icici'])
		} catch (e) {
			data.onboardingIcici = false
		}
		try {
			data.onboardingIciciFirstTime = (data?.onboarding['iciciFirstTime'])
		} catch (e) {
			data.onboardingIciciFirstTime = false
		}
		try {
			data.onboardingIciciFirstTimeCompleted = (data?.onboarding['iciciFirstTimeCompleted'])
		} catch (e) {
			data.onboardingIciciFirstTimeCompleted = false
		}
		try {
			data.onboardingMandate = (data?.onboarding['iciciMandate'])
		} catch (e) {
			data.onboardingMandate = false
		}
		try {
			data.onboardingMandateCompleted = (data?.onboarding['iciciMandateCompleted'])
		} catch (e) {
			data.onboardingMandateCompleted = false
		}
		try {
			data.onboardingJuspayMandateCompleted = (data?.onboarding['juspayMandate'] ?? false)
		}
		catch (e) {
			data.onboardingJuspayMandateCompleted = false
		}

		// Lendbox 
		try {
			data.lendboxBalance = (data?.balance['lendbox']['balance'])
		} catch (e) {
			data.lendboxBalance = 0
		}
		try {
			data.lendboxAvailableToWithdraw = (data?.balance['lendbox']['availableToWithdraw'])
		} catch (e) {
			data.lendboxAvailableToWithdraw = 0
		}
		try {
			data.lendboxAmountWithdrawn = (data?.balance['lendbox']['amountWithdrawn'])
		} catch (e) {
			data.lendboxAmountWithdrawn = 0
		}
		try {
			data.lendboxInProgress = (data?.balance['lendbox']['inProgress'])
		} catch (e) {
			data.lendboxInProgress = 0
		}
		try {
			data.lendboxTotalSaved = (data?.balance['lendbox']['totalSaved'])
		} catch (e) {
			data.lendboxTotalSaved = 0
		}
		try {
			data.lendboxWithdrawalInProgress = (data?.balance['lendbox']['withdrawalInProgress'])
		} catch (e) {
			data.lendboxWithdrawalInProgress = 0
		}
		try {
			data.lendboxDailyDeposit = (data?.balance['lendbox']['dailyDeposit'])
		} catch (e) {
			data.lendboxDailyDeposit = 0
		}
		try {
			data.lendboxDailyDepositDays = (data?.balance['lendbox']['dailyDepositDays'])
		} catch (e) {
			data.lendboxDailyDepositDays = 0
		}
		try {
			data.lendboxDirectInvested = (data?.balance['lendbox']['directInvested'])
		} catch (e) {
			data.lendboxDirectInvested = 0
		}
		try {
			data.lendboxInterestPerDay = (data?.balance['lendbox']['interestPerDay'])
		} catch (e) {
			data.lendboxInterestPerDay = 0
		}
		try {
			data.lendboxInvested = (data?.balance['lendbox']['invested'])
		} catch (e) {
			data.lendboxInvested = 0
		}


		try {
			data.lendboxRoundup = (data?.balance['lendbox']['roundup'])
		} catch (e) {
			data.lendboxRoundup = 0
		}
		try {
			data.lendboxRoundupSpends = (data?.balance['lendbox']['roundupSpends'])
		} catch (e) {
			data.lendboxRoundupSpends = 0
		}
		try {
			data.lendboxTotalInterestEarning = (data?.balance['lendbox']['totalInterestEarning'])
		} catch (e) {
			data.lendboxTotalInterestEarning = 0
		}
		try {
			data.lendboxTotalWithdrawal = (data?.balance['lendbox']['totalWithdrawal'])
		} catch (e) {
			data.lendboxTotalWithdrawal = 0
		}
		try {
			data.lendboxRunRate = (data?.balance['lendbox']['runRate'])
		} catch (e) {
			data.lendboxRunRate = 0
		}
		try {
			data.lendboxTotalMandates = (data?.balance['lendbox']['totalMandates'])
		} catch (e) {
			data.lendboxTotalMandates = 0
		}
		try {
			data.onboardingYesbankMandateCompleted = (data?.onboarding['yesbankMandateCompleted'])
		} catch (e) {
			data.onboardingYesbankMandateCompleted = false
		}
		if (data.onboardingLendbox) {
			data.onboardingLendbox = 'Completed'
		} else {
			data.onboardingLendbox = 'Not started'
		}
		if (data.onboardingIcici) {
			data.onboardingIcici = 'Completed'
		} else if (data.onboardingIciciKycInProgress ?? false) {
			data.onboardingIcici = 'In Progress'
		} else {
			data.onboardingIcici = 'Not started'
		}
		if (data.onboardingIciciFirstTime) {
			if (data.onboardingIciciFirstTimeCompleted) {
				data.onboardingIciciFirstTime = 'Completed'
			} else {
				data.onboardingIciciFirstTime = 'In Progress'
			}
		} else {
			data.onboardingIciciFirstTime = 'Not started'
		}
		if (data.onboardingYesbankMandateCompleted) {
			data.onboardingMandate = 'Completed'
			data.mandateType = 'UPI'
		} else {
			data.onboardingMandate = 'Not Completed'
		}
		if (data.accountPaused === undefined) {
			data.accountPaused = false
		}
		if ((data.roundup ?? 0) === 0 && (data.recurring ?? 0) === 0) {
			data.accountPaused = true
		}
		if (data.createdAt != null) {
			data.createdAt = (new Date(data.createdAt.toDate().getTime() + 330 * 60000)).toISOString()
		}
		if (data.updatedAt != null) {
			data.updatedAt = (new Date(data.updatedAt.toDate().getTime() + 330 * 60000)).toISOString()
		}
		if (data?.lendboxInvestmentPreference?.timestamp != null) {
			data.lendboxInvestmentPreference.timestamp = (new Date(data.lendboxInvestmentPreference.timestamp.toDate().getTime() + 330 * 60000)).toISOString()
		}
		const lendboxTempInvested = data.balance?.lendbox?.invested ?? 0
		const lendboxTotalWithdrawal = data.balance?.lendbox?.totalWithdrawal ?? 0
		let lendboxAppInvested = 0
		let lendboxAppTotalInterest = 0
		if (lendboxTotalWithdrawal > lendboxTempInvested) {
			lendboxAppInvested = 0
			lendboxAppTotalInterest = (data?.balance?.lendbox?.balance ?? 0)
		} else {
			lendboxAppInvested = lendboxTempInvested - lendboxTotalWithdrawal
			lendboxAppTotalInterest = (data?.balance?.lendbox?.balance ?? 0) - lendboxAppInvested
		}
		data.lendboxAppInvested = lendboxAppInvested
		data.lendboxAppTotalInterest = lendboxAppTotalInterest
		return data
	}
	getUserData(uid: string, observer: (userData: UserData) => void) {
		onSnapshot(doc(db, `users/${uid}`), (snapshot: any) => {
			const data: any = snapshot.data()
			observer(this.processData(data, uid))
		})
	}

	async balanceReconciliation(uid: string) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/balance-reconciliation'
		await APIController.post(url, { uid: uid })
	}

	async lendboxPortfolioUpdate(uid: string) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/lendbox/portfolio/update'
		await APIController.post(url, { uid: uid })
	}

	async refreshKRAStatus(uid: string) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/kra/status/refresh'
		await APIController.post(url, { uid: uid })
	}

	async getUserId(uid: string) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/data/id/fetch'
		const response = await APIController.post(url, { uid: uid })
		const id = response.data.data.id
		return id
	}

	async getFolios(id: number, fundType: FundType) {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/folios/fetch'
		const response = await APIController.post(url, { userId: id, fundCode: fundType })
		return response.data.data.folios ?? []
	}

}



