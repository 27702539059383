export default async function statsDataHandler(startDate: Date, endDate: Date, monthDataFunction: any, dayHandler: any) {
	const date = startDate
	const data: any = {}
	// let count=
	while (date <= endDate) {
		const year = String(date.getFullYear())
		const month = String(date.getMonth() + 1)
		data[year] ??= {}
		data[year][month] ??= await monthDataFunction(month, year)
		dayHandler(data[year][month], date)
		date.setDate(date.getDate() + 1)
	}
}