import axios from 'axios'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from 'services/firebase'
import getMonth from 'services/util/getMonth'
import monthConvert from 'services/util/monthConvert'

export interface PortfolioTransactionMonth {
	id: string,
	monthCode: string,
	month: string,
}
export interface PortfolioTransaction {
	id: string,
	timestamp: string,
	amount: number,
	status: string,
	type: string,
	transactionId: string,
	custRefNo: string,
	uninvestedAmount: number,
	errorCode: string,
	responseCode: string,
	ip: string,
	utr: string,
	remarks: string,
	orderId: string,
	units: number,
	nav: number,
	folioNumber: string
}
export default class PortfolioController {
	getPortfolioMonths(uid: string, fundType: string, observer: (transactionMonths: PortfolioTransactionMonth[]) => void) {
		onSnapshot(collection(db, `users/${uid}/portfolio/${fundType}/transactions`), (snapshot: any) => {
			const transactionMonths: PortfolioTransactionMonth[] = snapshot.docs.map((doc: any) => {
				const data = doc.data()
				data.id = doc.id
				data.monthCode = monthConvert(doc.id)
				data.month = getMonth(data.monthCode)
				return data
			})
			transactionMonths.sort((a: PortfolioTransactionMonth, b: PortfolioTransactionMonth) => {
				if (a.monthCode > b.monthCode) return -1
				if (a.monthCode < b.monthCode) return 1
				return 0
			})
			observer(transactionMonths)
		})

	}

	getPortfolio(uid: string, fundType: string, month: string, observer: (transactions: PortfolioTransaction[]) => void) {

		onSnapshot(doc(db, `users/${uid}/portfolio/${fundType}/transactions/${month}`), (snapshot: any) => {
			const data: any = snapshot.data()
			const transactions: PortfolioTransaction[] = []
			Object.keys(data).sort().reverse().forEach((key: string) => {
				const timestamp = key.substring(0, 4) + '-' + key.substring(4, 6) + '-' + key.substring(6, 8) + 'T' + key.substring(8, 10) + ':' + key.substring(10, 12) + ':' + key.substring(12, 14)
				transactions.push({
					id: key,
					timestamp: timestamp,
					type: data[key].type,
					amount: data[key].amount,
					status: data[key].status,
					transactionId: data[key].transactionId,
					errorCode: data[key].errorCode,
					responseCode: data[key].responseCode,
					ip: data[key].ip,
					utr: data[key].utr,
					remarks: data[key].remarks,
					orderId: data[key].orderId,
					custRefNo: data[key].custRefNo,
					uninvestedAmount: data[key].uninvestedAmount,
					units: data[key].units,
					nav: data[key].nav,
					folioNumber: data[key].folioNumber
				})
			})
			observer(transactions)
		})

	}
	async refreshLendboxTransaction(transactionId: string) {
		const transactionType = transactionId.substring(0, 1)
		const data: any = { id: transactionId }
		if (transactionType === 'z' || transactionType === 'v') {
			await axios.post('https://asia-south1-deciml-prod.cloudfunctions.net/lendbox_transaction_status', data)
		} else if (transactionType === 'r') {
			await axios.post('https://asia-south1-deciml-prod.cloudfunctions.net/lendbox_redemption_transaction_status', data)
		}
	}
	async refreshJuspayTransaction(transactionId: string) {
		const transactionType = transactionId.substring(0, 1)
		const data: any = { id: transactionId }
		if (['b', 'c', 'f'].includes(transactionType)) {
			await axios.post('https://asia-south1-deciml-prod.cloudfunctions.net/juspay_transaction_status_refresh', data)
		}
	}
}