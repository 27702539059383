import { Refresh as RefreshIcon } from '@mui/icons-material'
import { Grid, IconButton, SxProps } from '@mui/material'
import StatsStackedAreaGraph from 'components/stats/StatsStackedAreaGraph'
import { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import StatsController from 'services/controllers/StatsController'
import currencyFormat from 'services/util/currencyFormat'
import numberFormat from 'services/util/numberFormat'
import statsDataHandler from 'services/util/statsDataHandler'

const statsController = new StatsController()

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		height: 400,
		background: Colors.neutrals.lead100,
		borderRadius: 2
	},
	mainCard: {
		backgroundColor: Colors.neutrals.lead100,
		height: 90,
		borderRadius: 2
	},
	cardContent: {
		alignContent: 'center',
		textAlign: 'center'
	},
	gridList: {
		height: 360,
	},
	total: {
		color: Colors.secondary.mint400
	},
	kyc: {
		color: Colors.secondary.orange300
	},
	investments: {
		color: Colors.primary.violet400
	},
	userStats: {
		color: Colors.primary.lime400
	},
	rewards: {
		color: Colors.primary.lime400
	}
}
)

export default function InvestmentStats(props: { startDate: Dayjs, endDate: Dayjs }) {
	const classes = useStyles()
	const [investmentStatsData, setInvestmentStatsData] = useState<any>({})
	const [investmentGraphData, setInvestmentGraphData] = useState<any>({})
	const [daywiseWithdrawal, setDaywiseWithdrawal] = useState<number>(0)
	const [netAUMGrowth, setNetAUMGrowth] = useState<number>(0)
	useEffect(() => {
		const fetchData = () => {
			getInvestmentStatsData.current()
		}
		return fetchData()
	}, [])

	useEffect(() => {
		const fetchData = () => {
			getData()
		}
		return fetchData()
	}, [props.startDate, props.endDate])


	const getData = async () => {
		await getInvestmentGraphData.current()
	}

	const getInvestmentStatsData = useRef(() => {
		// 
	})

	getInvestmentStatsData.current = () => {
		statsController.getInvestmentStatsData((data: any) => {
			setInvestmentStatsData(data)
		})
	}

	const getInvestmentGraphData = useRef(() => {
		// 
	})

	getInvestmentGraphData.current = async () => {
		const graphData: Record<string, any[]> = {}
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getInvestmentGraph, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				const defaultDayGraphData: any = {
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year
				}
				for (const type of ['balance', 'invested', 'inProgress', 'roundup', 'roundupSpends', 'dailyDeposit', 'dailyDepositDays', 'directInvested', 'totalInterestEarning', 'totalSaved', 'totalWithdrawal', 'withdrawalInProgress', 'interestPerDay', 'totalMandates', 'netAUMGrowth', 'daywiseWithdrawal']) {
					graphData[type] ??= []
					const dayGraphData: any = {
						...defaultDayGraphData,
					}
					for (const fundType of ['total', 'lendbox', 'lendbox-fixed60', 'PLF-EHGPG', 'PLF-MCGPG']) {
						if (type === 'netAUMGrowth') {
							if (graphData['balance'].length >= 2) {
								// Calculated netAUMGrowth as the difference between today's balance and previous day's balance
								const previousBalance = graphData['balance'][graphData['balance'].length - 2]?.[fundType] ?? 0
								const todayBalance = graphData['balance'][graphData['balance'].length - 1]?.[fundType] ?? 0
								dayGraphData[fundType] = todayBalance - previousBalance
								setNetAUMGrowth(todayBalance - previousBalance)
							}
						}
						else if (type === 'daywiseWithdrawal') {
							if (graphData['totalWithdrawal'].length >= 2) {
								const previousWithdrawal = graphData['totalWithdrawal'][graphData['totalWithdrawal'].length - 2]?.[fundType] ?? 0
								const todayWithdrawal = graphData['totalWithdrawal'][graphData['totalWithdrawal'].length - 1]?.[fundType] ?? 0
								dayGraphData[fundType] = todayWithdrawal - previousWithdrawal
								setDaywiseWithdrawal(todayWithdrawal - previousWithdrawal)
							}
						}
						else {

							dayGraphData[fundType] = data[day]?.[fundType]?.[type] ?? 0
						}
					}
					graphData[type].push(dayGraphData)
				}

			}
		)
		setInvestmentGraphData(graphData)
	}
	return (<>
		<Grid item xs={12} sm={11} direction="row-reverse" />
		<Grid item xs={12} sm={1} direction="row-reverse">
			<IconButton onClick={getData} size="large">
				<RefreshIcon />
			</IconButton>
		</Grid>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.balance ?? 0)}`}
			subheader={'Assets Under Management (Total Balance i.e total portfolio value of all users)'}
			type={'balance'}
			currency={true}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.invested ?? 0)}`}
			subheader={'Invested (Total money invested till date)'}
			type={'invested'}
			currency={true}
		/>
		{/* <StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.inProgress ?? 0)}`}
			subheader={'In Progress'}
			type={'inProgress'}
			currency={true}
		/> */}
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(netAUMGrowth)}`}
			subheader={'Net AUM Growth'}
			type={'netAUMGrowth'}
			currency={true}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.totalInterestEarning ?? 0)}`}
			subheader={'Total Interest'}
			type={'totalInterestEarning'}
			currency={true}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.interestPerDay ?? 0)}`}
			subheader={'Interest per day'}
			type={'interestPerDay'}
			currency={true}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(daywiseWithdrawal)}`}
			subheader={'Day Wise Withdrawal'}
			type={'daywiseWithdrawal'}
			currency={true}
		/>

		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.totalWithdrawal ?? 0)}`}
			subheader={'Total Withdrawal'}
			type={'totalWithdrawal'}
			currency={true}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.directInvested ?? 0)}`}
			subheader={'Directly Invested'}
			type={'directInvested'}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.roundup ?? 0)}`}
			subheader={'Roundup'}
			type={'roundup'}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${currencyFormat(investmentStatsData?.total?.dailyDeposit ?? 0)}`}
			subheader={'Daily Deposit'}
			type={'dailyDeposit'}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${numberFormat(investmentStatsData?.total?.roundupSpends ?? 0)}`}
			subheader={'Roundup Spends'}
			type={'roundupSpends'}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${numberFormat(investmentStatsData?.total?.dailyDepositDays ?? 0)}`}
			subheader={'Daily Deposit Days'}
			type={'dailyDepositDays'}
		/>
		<StatsStackedAreaGraph
			data={investmentGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.investments}
			title={`${numberFormat(investmentStatsData?.total?.totalMandates ?? 0)}`}
			subheader={'Total Mandates Executed'}
			type={'totalMandates'}
		/>
	</>)
}