import AdminDataLoading from 'components/AdminDataLoading'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAdminUserData } from 'services/contexts/AdminUserDataContext'
import { useAuth } from 'services/contexts/AuthContext'

const ExternalUserRoute = () => {
	const { currentUser } = useAuth()
	const { adminUserData } = useAdminUserData()
	if (currentUser) {
		if ((adminUserData?.isAdmin ?? false) && (adminUserData?.isDashboardAccess ?? false)) {
			return <Outlet />
		} else {
			return <AdminDataLoading />
		}
	}
	else {
		return <Navigate to="/login" />
	}
}
export default ExternalUserRoute