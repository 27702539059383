import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import MutualFundController, { MutualFund } from 'services/controllers/MutualFundController'

const CustomToolbar = (props: any) => {
	const { goBackFuncttion } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFuncttion} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

export default function MutualFunds() {
	const [mutualFundList, setMutualFundList] = useState<MutualFund[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const navigate = useNavigate()
	const mutualFundController = new MutualFundController()
	const { uid } = useParams()


	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 300 },
		{ field: 'value', headerName: 'Value', width: 200 },


	]

	const getMutualFund = useRef(() => {
		// 
	})

	useEffect(() => {
		const fetchData = () => {
			getMutualFund.current()
		}
		return fetchData()
	}, [])

	getMutualFund.current = () => {
		if (uid != null && uid != undefined) {
			mutualFundController.getMutualFund(uid, (data) => {
				setMutualFundList(data)
				if (loading)
					setLoading(false)
			})
		}
	}
	return (
		<Layout id={'users'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					width: '100%'
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar,
					}}
					componentsProps={{
						toolbar: {
							goBackFuncttion: () => {
								navigate('/users')
							},
						}
					}}
					rows={mutualFundList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
