import { Cancel as CancelIcon, Person as PersonIcon } from '@mui/icons-material'
import { Button, Card, CssBaseline, IconButton, SxProps, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Center from 'components/Center'
import Layout from 'components/Layout'
import dayjs, { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import StatsController from 'services/controllers/StatsController'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})



export default function StatsSearch() {
	const { type } = useParams()
	const classes = useStyles()
	const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(Date.now()))
	const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(Date.now()))
	const [loading, setLoading] = useState<boolean>(false)
	const [data, setData] = useState<any>([])
	const navigate = useNavigate()
	const handleSubmit = useCallback(
		async function (event: any) {
			event.preventDefault()
			if (startDate?.isBefore(endDate?.add(1, 'day'))) {
				setLoading(true)
				const statsController = new StatsController()
				let data: any[] = []
				if (startDate != null && endDate != null) {
					switch (type) {
						case 'total':
							data = await statsController.getTotalUsers(startDate, endDate)
							break
						case 'lendbox':
							data = await statsController.getLendboxUsers(startDate, endDate)
							break
						default:
							data = []
					}
					setData(data)
					setLoading(false)
				} else {
					setLoading(false)
					alert('Set Valid Date')
				}
			}
		}
		, [startDate, endDate, type]
	)
	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 100,
		},
		{
			field: 'uid',
			headerName: 'User ID',
			width: 300,
		},
		{
			field: 'date',
			headerName: 'Date',
			width: 150,
		},
		{
			field: 'z_actions',
			headerName: 'Actions',
			width: 200,
			renderCell: (params: any) => {
				const onClick = () => {
					navigate(`/users/${params.row.uid}`)
				}
				return (
					<div>
						<IconButton onClick={onClick}>
							<PersonIcon />
						</IconButton>
					</div>
				)
			}
		}]

	const CustomToolbar = (props: any) => {
		const { cancelFunction } = props
		return (
			<GridToolbarContainer>
				<IconButton onClick={cancelFunction} size="large">
					<CancelIcon style={{ color: Colors.primary.lime400 }} />
				</IconButton>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
			</GridToolbarContainer>
		)
	}

	function getTitle() {
		switch (type) {
			case 'total':
				return 'User Installs'
			case 'lendbox':
				return 'Lendbox KYC'
			default:
				return 'Stats'
		}
	}

	return (
		<Layout id={'stats'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{data.length === 0 && !loading ?

					<Center>
						<div>
							<div
								style={{
									backgroundColor: Colors.neutrals.lead200,
									padding: 20,
									textAlign: 'center',
								}}
							>
								<CssBaseline />
								<Card elevation={0} sx={classes.card}>
									<Typography variant="h5">{getTitle()}</Typography>
									<form onSubmit={handleSubmit}>
										<div style={{ padding: 10 }} />
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												label="Start Date"
												views={['month', 'year', 'day']}
												value={startDate}
												onChange={(newValue: any) => {
													setStartDate(newValue)
												}}
												format="DD/MM/YYYY"
											/>
										</LocalizationProvider>
										<div style={{ padding: 10 }} />
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												label="End Date"
												views={['month', 'year', 'day']}
												value={endDate}
												onChange={(newValue: any) => {
													setEndDate(newValue)
												}}
												format="DD/MM/YYYY"
											/>
										</LocalizationProvider>
										<div style={{ padding: 10 }} />
										<Button disabled={false} type="submit" fullWidth={true}>
											Search
										</Button>
									</form>
								</Card>
							</div>
						</div>
					</Center>
					:
					<DataGrid
						components={{
							Toolbar: CustomToolbar,
						}}
						componentsProps={{
							toolbar: {
								cancelFunction: () => {
									setData([])
								},
							}
						}}
						rows={data}
						columns={columns}
						loading={loading} />

				}
			</div>
		</Layout >
	)
}

