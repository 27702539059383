import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { db, functions } from 'services/firebase'

/**
 * Interface for MutualFund
 */
export interface Lendbox {
	id: string,
	value: any,
}

/**
 * This class is controller for interfacting withs mutualFunds from firestore
 */
export default class LendboxController {

	/**
	 * Get all mutualFunds of user with uid
	 * @param uid user id
	 * @param observer Handle changes on changes
	 */
	async getLendbox(uid: string, observer: (lendbox: Lendbox[]) => void) {

		/**
		 * Reference for mutualFund document
		 */
		const snapshot = await getDoc(doc(db, `users/${uid}/data/lendbox`))

		const data: any = snapshot.data()
		const lendbox: Lendbox[] = []
		Object.keys(data ?? {}).sort().forEach((key: string) => {
			lendbox.push({ id: key, value: data[key] })
		})
		observer(lendbox)
	}

	async refreshLendbox(uid: string) {
		const lendboxBalanceReconciliation = httpsCallable(functions, 'lendbox_balance_reconciliation')
		lendboxBalanceReconciliation({ uid: uid })
		await httpsCallable(functions, 'lendbox_portfolio_update')({ uid: uid })
	}
} 