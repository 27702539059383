import './styles.css'

import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import { useAdminUserData } from 'services/contexts/AdminUserDataContext'
import { useAuth } from 'services/contexts/AuthContext'
import ManageUsersController from 'services/controllers/ManageUsersController'

export default function ManageUsers() {
	const { currentUser } = useAuth()
	const [loading, setLoading] = useState<boolean>(true)
	const [data, setData] = useState<any>([])
	const { adminUserData } = useAdminUserData()

	useEffect(() => {
		const fetchData = () => {
			getUsers.current()
		}
		return fetchData()
	}, [])

	const getUsers = useRef(() => {
		// 
	})

	getUsers.current = () => {
		new ManageUsersController().getUsers((data) => {
			setData(data)
			if (loading) setLoading(false)
		})
	}

	function columns() {
		const data: any[] = [
			{ field: 'id', headerName: 'UID', width: 300 },
			{ field: 'email', headerName: 'Email', width: 300 },
		]
		let columnData: { [key: string]: string } = {}
		if (adminUserData?.isFullManageUsersAccess) {
			columnData = {
				...columnData, ...{
					'isAdmin': 'Admin',
					'isManageUsersAccess': 'Manage Users',
					'isFullManageUsersAccess': 'Manage Full Users',
					'isInternalUser': 'Internal',
					'isAgent': 'Agent',
					'isDashboardAccess': 'Dashboard',
					'isStatsAccess': 'Stats',
				}
			}
		}
		columnData = {
			...columnData, ...{
				'isUsersAccess': 'Users',
			}
		}

		if (adminUserData?.isFullManageUsersAccess) {
			columnData = {
				...columnData, ...{
					'isFullUsersAccess': 'Full Users',
					'isDeveloper': 'Developer',
					'isUserRequestsAccess': 'User Requests',
				}
			}
		}
		for (const key of Object.keys(columnData)) {
			data.push({
				field: key,
				headerName: columnData[key],
				width: 150,
				renderCell: (params: any) => {
					return <IconButton
						onClick={async () => {
							if (currentUser?.uid === params?.id) {
								alert('You cannot change your own permissions')
							} else {
								await new ManageUsersController().updateUserPermission(params?.row.id, key, !params?.row[key])
								alert('Permission updated successfully!')
							}
						}}>
						{params?.row[key] ?? false ?
							<CheckIcon style={{ color: Colors.system.positiveGreen400 }} />
							:
							<CloseIcon style={{ color: Colors.system.negativeRed400 }} />
						}
					</IconButton>
				}
			})
		}
		return data
	}

	function filteredData() {
		if (adminUserData?.isFullManageUsersAccess) {
			return data
		} else if (adminUserData?.isManageUsersAccess) {
			return data.filter((user: any) => (user.isAdmin ?? false) && (user.isAgent ?? false))
		} else {
			return []
		}
	}

	return (
		<Layout id={'manage-users'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<DataGrid
					rows={filteredData()}
					columns={columns()}
					loading={loading} />
			</div>
		</Layout>
	)
}
