import axios from 'axios'

import StorageController from './StorageController'

export default class CamsController {
	async handleWbr1(file: any) {
		const storageController = new StorageController()
		const data = await storageController.uploadFile(file, 'cams/wbr1/')
		let responseData = {}
		try {
			const response = await axios.post('https://asia-south1-deciml-prod.cloudfunctions.net/handle_cams_report_wbr1', {
				data: {
					url: data.url
				}
			})
			responseData = response.data
		} catch (e) {
			// console.log(e)
		}
		await storageController.deleteFile(data.path)
		return responseData
	}
	async handleWbr2(file: any) {
		const storageController = new StorageController()
		const data = await storageController.uploadFile(file, 'cams/wbr2/')
		let responseData = {}
		try {
			const response = await axios.post('http://localhost:8080/', {
				data: {
					url: data.url
				}
			})
			responseData = response.data
		} catch (e) {
			// console.log(e)
		}
		await storageController.deleteFile(data.path)
		return responseData
	}
}