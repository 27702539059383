import './styles.css'
import './styles.css'

import {
	Card,
	CardActionArea,
	CardHeader,
	Grid,
	SxProps,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import { useNavigate } from 'react-router'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		borderRadius: 8,
	},
	cardAction: {
		display: 'block',
		textAlign: 'initial',
		width: '100',
	}

})

const Title = (props: { text: string }) => {
	return (
		<Grid item xs={12} sm={12}>
			<Typography variant="h6">{props.text}</Typography>
		</Grid>
	)
}

const DataCard = (props: {
	title: string,
	classes: any,
	color?: string,
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
	actions?: any
}) => {
	const className = props.classes.card
	const color = props.color ?? Colors.primary.lime400
	return (
		<Grid item xs={12} sm={4}>
			<Card
				elevation={0}
				sx={className}
				style={{ backgroundColor: color + '1A', width: '100' }}
			>
				<CardActionArea onClick={props.onClick}>
					<CardHeader
						title={
							<Typography variant="body1" color={Colors.type.type100}>
								{props.title}
							</Typography>
						}
						action={<>{props.actions}</>}
					/>
				</CardActionArea>
			</Card>
		</Grid >
	)
}

export default function UserRequests() {
	const classes = useStyles()
	const navigate = useNavigate()

	return (
		<Layout id={'UserRequests'}>
			<Grid container spacing={3}>
				<Title text={'Bank Account Change'} />
				<DataCard
					classes={classes}
					title={'Lendbox'}
					onClick={() => navigate('/user-requests/bank-account/lendbox')}
				/>
				<DataCard
					classes={classes}
					title={'Mutual Fund'}
					onClick={() => navigate('/user-requests/bank-account/mutual-fund')}
				/>
			</Grid>
		</Layout>
	)
}
