import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import TransactionController, { TransactionMonth } from 'services/controllers/TransactionController'

export default function TransactionsMonth(props: { onClickView: any }) {
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const [transactionMonthList, setTransactionMonthList] = useState<TransactionMonth[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const transactionController = new TransactionController()

	useEffect(() => {
		const fetchData = () => {
			getTransactionMonths.current()
		}
		return fetchData()
	}, [])

	const columns: GridColDef[] = [
		{ field: 'month', headerName: 'Month', width: 300 },
		{
			field: 'View',
			headerName: 'View',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					props.onClickView(`${params.id}`)
				}
				return (
					<IconButton onClick={onClick} title={'View more'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
	]

	const getTransactionMonths = useRef(() => {
		// 
	})

	getTransactionMonths.current = () => {
		if (uid !== undefined && uid !== null) {
			transactionController.getTransactionMonths(uid, (data) => {
				setTransactionMonthList(data)
				if (loading)
					setLoading(false)
			})
		}
	}

	return (
		<div
			style={{
				height: 'calc(100vh - 160px)',
				width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
			}}>
			<DataGrid
				components={{
					Toolbar: GridToolbar
				}}
				rows={transactionMonthList}
				columns={columns}
				loading={loading} />
		</div>
	)
}
