import React, { memo } from 'react'

function Center(props: { children: any }) {
	return (
		<>
			<div
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)'
				}}
			>
				<div style={{ maxWidth: '400px' }}>
					{props.children}
				</div>
			</div>
		</>
	)
}

export default memo(Center)
