import App from 'components/App'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
const container = document.getElementById('root')
if (container != null && container != undefined) {
	const root = createRoot(container)
	root.render(
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
		>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</GoogleReCaptchaProvider>,
	)
} else {
	throw new Error('Root element not found')
}