import './styles.css'

import {
	ArrowBackIos as ArrowBackIosIcon, FileCopy as FileCopyIcon, History as HistoryIcon, Person as PersonIcon, Refresh as RefreshIcon
} from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material'
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import UserRequestController from 'services/controllers/UserRequestController'
import copyToClipboard from 'services/util/copyToClipboard'


const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

function getProviderValue(provider: string | undefined): 'LENDBOX' | 'CAMS' {
	switch (provider) {
		case 'lendbox':
			return 'LENDBOX'
		case 'mutual-fund':
			return 'CAMS'
	}
	return 'LENDBOX'

}

export default function UserBankAccountChangeRequests() {
	const { provider } = useParams()
	const [loading, setLoading] = useState<boolean>(true)
	const [data, setData] = useState<any>([])
	const [history, setHistory] = useState<any>([])
	const [updateData, setUpdateData] = useState<any>()
	const providerValue: 'LENDBOX' | 'CAMS' = getProviderValue(provider)

	useEffect(() => {
		const fetchData = () => {
			getBankChangeRequests.current()
		}
		return fetchData()
	}, [])

	const getBankChangeRequests = useRef(() => {
		// 
	})

	getBankChangeRequests.current = async () => {
		const data = await new UserRequestController().getBankChangeRequests(providerValue)
		setData(data)
		if (loading) setLoading(false)
	}

	function columns() {
		let columnData: any[] = [
			{ field: 'id', headerName: 'ID', width: 50 },
			{
				field: 'openUserData', headerName: '', width: 70,
				renderCell: (params: any) => {
					const onClick = () => {
						window.open(`/users/${params.row.userUid}`, '_blank')
					}
					return (
						<IconButton onClick={onClick} title={'Open User Data'} size="large">
							<PersonIcon />
						</IconButton>
					)
				}
			},
			{ field: 'userUid', headerName: 'User UID', width: 250 },
			{
				field: 'copyUserUid', headerName: '', width: 70,
				renderCell: (params: any) => {
					const onClick = () => {
						copyToClipboard(params.row.userUid)
					}
					return (
						<IconButton onClick={onClick} title={'Copy User UID'} size="large">
							<FileCopyIcon />
						</IconButton>
					)
				}
			},
			{ field: 'userName', headerName: 'User Name', width: 250 },
		]
		switch (providerValue) {
			case 'LENDBOX':
				columnData = [...columnData, { field: 'lendboxUserId', headerName: 'Lendbox User ID', width: 150 }]
				break
			case 'CAMS':
				columnData = [...columnData, { field: 'folioNumber', headerName: 'Folio Number', width: 150 }]
				break
		}
		columnData = [
			...columnData,
			{
				field: 'copyLendboxUserUid', headerName: '', width: 70,
				renderCell: (params: any) => {
					const onClick = () => {
						copyToClipboard(params.row.lendboxUserId)
					}
					return (
						<IconButton onClick={onClick} title={'Copy Lendbox UID'} size="large">
							<FileCopyIcon />
						</IconButton>
					)
				}
			},
			{ field: 'provider', headerName: 'Provider', width: 100 },
			{ field: 'fundType', headerName: 'Fund Type', width: 200 },
			{ field: 'bankAccountId', headerName: 'Account Id', width: 100 },
			{ field: 'bankAccountNumber', headerName: 'Account Number', width: 150 },
			{ field: 'bankAccountHolderName', headerName: 'Holder Name', width: 250 },
			{ field: 'bankAccountIfsc', headerName: 'IFSC', width: 125 },
			{ field: 'bankAccountAccountType', headerName: 'Account Type', width: 125 },
			{ field: 'statusString', headerName: 'Status', width: 200 },
			{
				field: 'statusUpdate', headerName: 'Status Update', width: 200,
				renderCell: (params: any) => {
					const onClick = () => {
						setUpdateData(params.row)
						// new UserRequestController().updateBankChangeRequestStatus(params.row.id, params.row.nextStatus)
					}
					return (
						<Button disabled={params.row.nextStatus == null} onClick={onClick} title={'Update Status'} size="large">
							Update Status
						</Button>
					)
				}
			},
			{ field: 'updatedAt', headerName: 'Last Updated At', width: 200 },
			{
				field: 'history', headerName: '', width: 70,
				renderCell: (params: any) => {
					const onClick = () => {
						setHistory(params.row.history)
					}
					return (
						<IconButton onClick={onClick} title={'View History'} size="large">
							<HistoryIcon />
						</IconButton>
					)
				}
			},
			{ field: 'createdAt', headerName: 'Created At', width: 200 },
		]
		return columnData
	}


	function historyColumns() {
		const columnData: any[] = [
			{ field: 'id', headerName: 'ID', width: 50 },
			{ field: 'statusString', headerName: 'Status', width: 200 },
			{ field: 'userName', headerName: 'User Name', width: 250 },
			{ field: 'isAdmin', headerName: 'Is Admin', width: 250 },
			{ field: 'createdAt', headerName: 'Timestamp', width: 300 },
		]
		return columnData
	}

	function HistoryDialog() {
		return <>
			<Dialog
				fullScreen
				open={history.length > 0}
				onClose={() => setHistory([])}
			>
				<DataGrid
					rowSelection={false}
					rows={history}
					columns={historyColumns()}
					loading={loading} />
			</Dialog>
		</>
	}

	function UpdateDialog() {
		const handleClose = () => {
			setUpdateData(null)
			if (loading) setLoading(false)
		}
		return <>
			<Dialog
				open={updateData != null}
				onClose={handleClose}
			>
				<DialogTitle>
					{'Status Update'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you want to update the status of the following request?
					</DialogContentText>
					<DialogContentText>
						{`ID: ${updateData?.id}`}
					</DialogContentText>
					<DialogContentText>
						{`Current Status: ${updateData?.statusString}`}
					</DialogContentText>
					<DialogContentText>
						{`New Status: ${updateData?.nextStatusString}`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						No
					</Button>
					<Button onClick={async (_props: any) => {
						setLoading(true)
						await new UserRequestController().updateBankChangeRequestStatus(updateData.id, updateData.nextStatus)
						handleClose()
						await getBankChangeRequests.current()
					}}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}

	return (
		<Layout id={'UserRequests'}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={11} />
				<Grid item xs={12} sm={1} direction="row-reverse">

					<IconButton onClick={async () => { await getBankChangeRequests.current() }} size="large">
						<RefreshIcon />
					</IconButton>
				</Grid>
				<div
					style={{
						height: 'calc(100vh - 170px)',
						display: 'flex',
						alignItems: 'center',
						width: 'calc(100vw - 120px)',
					}}
				>
					<DataGrid
						components={{
							Toolbar: CustomToolbar,
						}}
						componentsProps={
							{
								toolbar: {
									goBackFunction: () => window.history.back()
								}
							}
						}
						rowSelection={false}
						rows={data}
						columns={columns()}
						loading={loading} />
				</div>
			</Grid>
			<HistoryDialog />
			<UpdateDialog />
		</Layout>
	)
}
