import { FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, ButtonGroup, Card, CardContent, CardHeader, CircularProgress, Grid, Icon, SxProps } from '@mui/material'
import { Colors } from 'models/Colors'
import React, { memo } from 'react'
import { Area, AreaChart, Tooltip, XAxis } from 'recharts'
import copyToClipboard from 'services/util/copyToClipboard'
import currencyFormat from 'services/util/currencyFormat'
import numberFormat from 'services/util/numberFormat'


const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any, currency?: boolean }) => {
	const active = props?.active ?? false
	const currency = props?.currency ?? false
	if (active) {
		const payload = props?.payload[0]?.payload
		const children: any[] = [
			<p className="label">{`${payload?.showDate ?? ''}`}</p>
		]
		for (const fundType of ['total', 'lendbox', 'lendbox-fixed60', 'PLF-EHGPG', 'PLF-MCGPG']) {
			let value = ((payload ?? {})[fundType]) ?? 0
			if (currency) {
				value = currencyFormat(value)
			} else {
				value = numberFormat(value)
			}
			let fundTypeLabel = fundType
			switch (fundType) {
				case 'total':
					fundTypeLabel = 'Total'
					break
				case 'lendbox':
					fundTypeLabel = 'Lendbox'
					break
				case 'lendbox-fixed60':
					fundTypeLabel = 'Lendbox Fixed'
					break
				case 'PLF-EHGPG':
					fundTypeLabel = 'Navi Aggressive Hybrid Fund'
					break
				case 'PLF-MCGPG':
					fundTypeLabel = 'Navi Large & Midcap Fund'
					break
			}
			children.push(<p className="intro">{`${fundTypeLabel}: ${value}`}</p>)
		}
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.neutrals.lead200 }} >
				{children}
			</div>
		)
	}
	return null
}

function StatsStackedAreaGraph(props: { data: any, cardClass: SxProps, cardHeaderClass: SxProps, title: string, subheader: string, type: string, currency?: boolean }) {
	const type = props.type
	const data = props.data[type]
	const cardClass = props.cardClass
	const cardHeaderClass = props.cardHeaderClass
	const title = props.title
	const subheader = props.subheader
	const currency = props.currency ?? false

	return (
		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={cardClass}>
				<CardHeader
					sx={cardHeaderClass}
					title={props.title}
					subheader={subheader}
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={cardHeaderClass}
								onClick={() => copyToClipboard(title)}>
								<Icon>
									<FileCopyIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{(() => {
							if ((data ?? []).length === 0) {
								return <CircularProgress />
							} else {
								return (
									<AreaChart width={500} height={260} data={data}>

										<Area
											stackId={'1'}
											type="monotone"
											dataKey="PLF-MCGPG"
											stroke={Colors.secondary.orange400}
											fill={Colors.secondary.orange400}
										/>
										<Area
											stackId={'1'}
											type="monotone"
											dataKey="PLF-EHGPG"
											stroke={Colors.primary.violet400}
											fill={Colors.primary.violet400}
										/>
										<Area
											stackId={'1'}
											type="monotone"
											dataKey="lendbox-fixed60"
											stroke={Colors.secondary.red500}
											fill={Colors.secondary.red500}
										/>
										<Area
											stackId={'1'}
											type="monotone"
											dataKey="lendbox"
											stroke={Colors.primary.lime400}
											fill={Colors.primary.lime400}
										/>
										<XAxis dataKey="date" />
										<Tooltip content={<CustomTooltipContent currency={currency} />} cursor={{ fill: Colors.tooltipOverlay }} />
									</AreaChart>
								)
							}
						})()}
					</div>
				</CardContent>
			</Card>
		</Grid>
	)
}

export default memo(StatsStackedAreaGraph)
