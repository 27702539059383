import { FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, ButtonGroup, Card, CardContent, CardHeader, CircularProgress, Grid, Icon, SxProps } from '@mui/material'
import { Colors } from 'models/Colors'
import { memo } from 'react'
import { Bar, BarChart, Tooltip, XAxis } from 'recharts'
import copyToClipboard from 'services/util/copyToClipboard'

const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any }) => {
	const active = props?.active ?? false
	if (active) {
		const payload = props?.payload[0]?.payload
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.neutrals.lead200 }} >
				<p className="label">{`${payload?.showDate}`}</p>
				<p className="intro">{`${payload?.totalString ?? ''}`}</p>
				<p className="intro">{`${payload?.PLF_EHGPG_String ?? ''}`}</p>
				<p className="intro">{`${payload?.PLF_MCGPG_String ?? ''}`}</p>
			</div>
		)
	}
	return null
}

function JuspayUserStats(props: { data: any[], cardClass: SxProps, cardHeaderClass: SxProps, title: string, subheader: string }) {
	const cardClass = props.cardClass
	const cardHeaderClass = props.cardHeaderClass
	const title = props.title
	const subheader = props.subheader
	const data = props.data
	return (

		<Grid item xs={12} sm={6}>
			<Card elevation={0} sx={cardClass}>
				<CardHeader
					sx={cardHeaderClass}
					title={props.title}
					subheader={subheader}
					action={
						< ButtonGroup variant="text" size="large" >
							<Button
								sx={cardHeaderClass}
								onClick={() => copyToClipboard(title)}>
								<Icon>
									<FileCopyIcon />
								</Icon>
							</Button>
						</ButtonGroup>
					}
				/>
				<CardContent>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{(() => {
							if (data.length === 0) {
								return (
									<CircularProgress />
								)
							} else {
								return (
									<BarChart
										width={500}
										height={260}
										data={data}>

										<Bar
											stackId={'1'}
											type="monotone"
											dataKey="PLF_EHGPG"
											stroke={Colors.secondary.yellow400}
											fill={Colors.secondary.yellow400}
											radius={[0, 0, 25, 25]} />
										<Bar
											stackId={'1'}
											type="monotone"
											dataKey="PLF_MCGPG"
											stroke={Colors.primary.violet400}
											fill={Colors.primary.violet400}
											radius={[25, 25, 0, 0]} />
										<XAxis dataKey="date" />
										<Tooltip content={<CustomTooltipContent />} cursor={{ fill: Colors.tooltipOverlay }} />
									</BarChart>
								)
							}
						})()
						}
					</div>
				</CardContent>
			</Card>
		</Grid>
	)
}
export default memo(JuspayUserStats)