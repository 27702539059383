import { Button, Card, CssBaseline, SxProps } from '@mui/material'
import logo from 'assets/logo.png'
import Center from 'components/Center'
import { Colors } from 'models/Colors'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'services/contexts/AuthContext'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})

export default function Login() {
	const { signInWithGoogle } = useAuth()
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const classes = useStyles()
	const handleSubmit = useCallback(
		async function (event: any) {
			event.preventDefault()
			try {
				setError('')
				setLoading(true)
				await signInWithGoogle()
				navigate('/')
			} catch (e) {
				setError('Failed to log in')
				alert(error)
			}

			setLoading(false)
		}, [error, navigate, signInWithGoogle]
	)

	return (
		<div style={{
			backgroundColor: Colors.neutrals.lead200,
			width: '100vw',
			height: '100vh',
			textAlign: 'center',
		}}>
			<CssBaseline />
			<Center>
				<Card elevation={0} sx={classes.card} style={{
					width: 400,
					padding: 20
				}} >
					<img src={logo} alt="Logo" height={400} />
					<form onSubmit={handleSubmit}>
						<div style={{
							padding: 10
						}} />
						<Button disabled={loading} type="submit" fullWidth={true}>Sign In with Google</Button >
					</form>
				</Card>
			</Center>
		</div>
	)
}
