import { Add as AddIcon, ArrowBackIos as ArrowBackIosIcon, Delete as DeleteIcon, FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useKeys } from 'services/contexts/KeysContext'

const CustomToolbar = (props: any) => {
	const { addFunction, goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
			<IconButton onClick={addFunction} size="large">
				<AddIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
		</GridToolbarContainer>
	)
}

export default function KeysView() {
	const { getKeys, deletekeyFromDB, setKeyInDB } = useKeys()
	const [loading, setLoading] = useState(true)
	const [addKeyDialogOpen, setAddKeyDialogOpen] = useState(false)
	const [deleteKeyDialogOpen, setDeleteKeyDialogOpen] = useState(false)
	const [key, setKey] = useState('')
	const [value, setValue] = useState('')
	const { id } = useParams()
	const navigate = useNavigate()

	const getValueFromParams = (params: any, id: string) => {
		const api = params.api
		const fields = api
			.getAllColumns()
			.map((c: any) => c.field)
			.filter((c: any) => c !== '__check__' && !!c)
		const thisRow: any = {}
		fields.forEach((f: any) => {
			thisRow[f] = params.getValue(f)
		})
		return thisRow[id]
	}

	const copyToClipboard = (params: any, id: string) => {
		const text = getValueFromParams(params, id)
		navigator.clipboard.writeText(text)
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 50 },
		{ field: 'key', headerName: 'key', width: 350 },
		{
			field: 'Copy Key',
			headerName: 'Copy Key',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					copyToClipboard(params, 'key')
				}
				return (
					<IconButton onClick={onClick} size="large">
						<FileCopyIcon />
					</IconButton>
				)
			}
		},
		{ field: 'value', headerName: 'Value', width: 350 },
		{
			field: 'Copy Value',
			headerName: 'Copy Value',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					copyToClipboard(params, 'value')
				}
				return (
					<IconButton onClick={onClick} size="large">
						<FileCopyIcon />
					</IconButton>
				)
			}
		},
		// {
		// 	field: 'Edit',
		// 	headerName: 'Edit',
		// 	renderCell: (params: any) => {
		// 		const onClick = () => {

		// 		}
		// 		return (
		// 			<IconButton onClick={onClick} size="large">
		// 				<EditIcon />
		// 			</IconButton>
		// 		)
		// 	}
		// },
		{
			field: 'Delete',
			headerName: 'Delete',
			renderCell: (params: any) => {
				const onClick = () => {
					setKey(getValueFromParams(params, 'key'))
					handleDeleteKeyClickOpen()

				}
				return (
					<IconButton onClick={onClick} size="large">
						<DeleteIcon />
					</IconButton>
				)
			}
		}
	]

	const deleteKey = async () => {
		handleDeleteKeyClose()
		setLoading(true)
		await deletekeyFromDB(key, id)
		setKey('')
	}

	const handleAddKeyClickOpen = () => {
		setKey('')
		setAddKeyDialogOpen(true)
	}

	const handleAddKeyClose = () => {
		setAddKeyDialogOpen(false)
	}

	const handleDeleteKeyClickOpen = () => {
		setDeleteKeyDialogOpen(true)
	}

	const handleDeleteKeyClose = () => {
		setDeleteKeyDialogOpen(false)
	}

	const addKey = async () => {
		handleAddKeyClose()
		setLoading(true)
		await setKeyInDB(key, value, id)
		setKey('')
		setValue('')
	}

	const handleKeyChange = (e: any) => {
		setKey(e.target.value)
	}

	const handleValueChange = (e: any) => {
		setValue(e.target.value)
	}

	return (
		<Layout id={'services'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					width: '100%'
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar
					}}
					componentsProps={{
						toolbar: {
							addFunction: handleAddKeyClickOpen,
							goBackFunction: () => {
								navigate('/developer')
							},
						}
					}}
					rows={getKeys(id)}
					columns={columns}
					loading={loading && getKeys(id).length === 0} />
			</div>
			<Dialog
				open={addKeyDialogOpen}
				onClose={handleAddKeyClose}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					Add Key
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="key"
						label="Key"
						value={key}
						onChange={handleKeyChange}
						fullWidth />
					<TextField
						margin="dense"
						id="value"
						label="Value"
						value={value}
						onChange={handleValueChange}
						fullWidth />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleAddKeyClose}
						color="primary">
						Cancel
					</Button>
					<Button
						onClick={addKey}
						color="primary">
						Add
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={deleteKeyDialogOpen}
				onClose={handleDeleteKeyClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">
					{'Delete Key?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{'Are you sure you want to delete key ' + key}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleDeleteKeyClose}
						color="primary">
						No
					</Button>
					<Button
						onClick={deleteKey}
						color="primary"
						autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Layout >
	)
}

