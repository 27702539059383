import { Dayjs } from 'dayjs'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { db } from 'services/firebase'

import APIController from './APIController'

/**
 * This class is controller for get stats from firestore
 */
export default class StatsController {

	/**
	 * Reference for user stats document
	 */
	usersStatsRef = doc(db, 'stats/users')

	// /**
	//  * Reference for goals stats documnet
	//  */
	goalsStatsRef = doc(db, 'stats/goals')

	// /**
	//  * Reference for investment stats document
	//  */
	investmentStatsRef = doc(db, 'stats/investment')
	rewardStatsRef = doc(db, 'stats/rewards')

	/**
	 * Reference for user stats document
	 */
	DailyDepositsStatsRef = doc(db, 'stats/users')

	/**
	 * @param observer Handle changes on changes
	 * @returns Bsic stats of users (totals)
	 */
	getUserStatsData = (observer: any) => {
		onSnapshot(this.usersStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		)
	}


	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Total user stats for graph
	 */
	async getUserTotalStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/total/${docId}`))
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserKycVerificationGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/kyc/${docId}`))
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserLendboxGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/lendbox/${docId}`))
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserActiveStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/active/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserOnboardedStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/onboarded/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserContactStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/contact/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserPreDebitNotificationsStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/yesbankPreDebitNotification/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserPreDebitNotificationsAmountStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/yesbankPreDebitNotificationAmount/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserMandateExecutionsStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/yesbankMandateExecution/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserMandateExecutionsAmountStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/yesbankMandateExecutionAmount/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayMandateExecutionAmountGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayMandateExecutionAmount/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayFirstExecutionsAmountGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayFirstExecutionAmount/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayFirstExecutionGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayFirstExecution/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayMandateExecutionGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayMandateExecution/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayNotificationGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayNotification/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayNotificationAmountGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayNotificationAmount/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayLumpsumGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayLumpsum/${docId}`))
		return snapshot.data() ?? {}
	}
	async getJuspayLumpsumAmountGraphData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/juspayLumpsumAmount/${docId}`))
		return snapshot.data() ?? {}
	}


	async getUserLongKycStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/longKyc/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUsershortKycStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/shortKyc/${docId}`))
		return snapshot.data() ?? {}
	}
	async getUserPausedStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/paused/${docId}`))
		return snapshot.data() ?? {}
	}
	async getUserDailyDepositStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/dailyDeposit/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserRoundUpStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/roundup/${docId}`))
		return snapshot.data() ?? {}
	}

	async getUserYesbankMandateStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/yesbankMandate/${docId}`))
		return snapshot.data() ?? {}
	}
	async getUsers100StepStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/users/mandates/data/rs100Step/${docId}`))
		return snapshot.data() ?? {}
	}
	/**
	 * @param observer Handle changes on changes
	 * @returns Goal stats (Category wise and totals)
	 */
	getGoalsStatsData(observer: any) {
		onSnapshot(this.goalsStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		)
	}

	/**
	 * @param observer Handle changes on changes
	 * @returns Investment stats (Wallet, Invested and total)
	 */
	getInvestmentStatsData(observer: any) {
		onSnapshot(this.investmentStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		)
	}
	/**
	 * @param observer Handle changes on changes
	 * @returns Investment stats (Wallet, Invested and total)
	 */
	async getInvestmentGraph(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/investment/data/${docId}`))
		return snapshot.data() ?? {}
	}
	/**
	 * @param observer Handle changes on changes
	 * @returns Investment stats (Wallet, Invested and total)
	 */
	getRewardStatsData(observer: any) {
		onSnapshot(this.rewardStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		)
	}

	async getRewardReferralStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/referral/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardReferralClaimStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/referralClaim/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardReferrerStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/referrer/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardReferrerClaimStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/referrerClaim/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardRewardStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/reward/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardRewardClaimStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/rewardClaim/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardRewardClaimValueStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/rewardClaimValue/${docId}`))
		return snapshot.data() ?? {}
	}

	async getRewardRewardCounterStatsData(month: string, year: string) {
		const docId = year + month
		const snapshot = await getDoc(doc(db, `stats/rewards/rewardCounter/${docId}`))
		return snapshot.data() ?? {}
	}

	async getTotalUsers(startDate: Dayjs, endDate: Dayjs) {
		const data = []
		let count = 1
		while (startDate.isBefore(endDate.add(1, 'day'))) {
			const docId = startDate.year().toString() + (startDate.month() + 1).toString()
			const snapshotData = await (await getDoc(doc(db, `stats/users/total/${docId}/data/${startDate.date()}`))).data()
			for (const uid of snapshotData?.users ?? []) {
				data.push({
					id: count++,
					uid: uid,
					date: startDate.format('DD/MM/YYYY')
				})
			}
			startDate = startDate.add(1, 'day')
		}
		return data
	}

	async getLendboxUsers(startDate: Dayjs, endDate: Dayjs) {
		const data = []
		let count = 1
		while (startDate.isBefore(endDate.add(1, 'day'))) {
			const docId = startDate.year().toString() + (startDate.month() + 1).toString()
			const snapshotData = await (await getDoc(doc(db, `stats/users/lendbox/${docId}/data/${startDate.date()}`))).data()
			for (const uid of snapshotData?.users ?? []) {
				data.push({
					id: count++,
					uid: uid,
					date: startDate.format('DD/MM/YYYY')
				})
			}
			startDate = startDate.add(1, 'day')
		}
		return data
	}

	async getDashboardStats() {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/stats/dashboard'
		const response = await APIController.get(url)
		return response.data.data.stats
	}
}