import { Assessment as AssessmentIcon, Dashboard as DashboardIcon, Label as LabelIcon, ManageAccounts as ManageAccountsIcon, MiscellaneousServices as MiscellaneousServicesIcon, Notifications as NotificationsIcon, PersonSearch as PersonSearchIcon } from '@mui/icons-material'
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, SxProps, Theme, Toolbar, Typography } from '@mui/material'
import logo from 'assets/logo.png'
import { Colors } from 'models/Colors'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdminUserData } from 'services/contexts/AdminUserDataContext'
import { useAuth } from 'services/contexts/AuthContext'
import { useLayout } from 'services/contexts/LayoutContext'

import HeadwayWidget from './HeadwayWidget'
import theme from './theme'
const drawerWidth = 240

const useStyles = (theme: Theme): { [key: string]: SxProps } => ({
	root: {
		display: 'flex',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	paper: {
		backgroundColor: Colors.neutrals.lead200
	},
	title: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: theme.palette.primary.main
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		backgroundColor: Colors.neutrals.lead200
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: Colors.neutrals.lead200
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 75,
		backgroundColor: Colors.neutrals.lead200
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 0),
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},

	toolbarIcon: {
		padding: theme.spacing(0, 2.5, 0, 0.0)
	},

	listPadding: {
		padding: theme.spacing(1, 0, 1, 2.9)
	}
})

/**
 * Layout of the app
 * @param children children components
 * @param id id for current color of list item on drawer
 * @returns Layout consisting of AppBar, Drawer and the children param
 */
export default function Layout(props: { children: any, id: string }) {
	const [error, setError] = useState('')
	const { currentUser, logout } = useAuth()
	const { drawerOpen, permanentDrawerOpen: permanentDrawerOpen, openDrawer, closeDrawer } = useLayout()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()
	const classes = useStyles(theme)
	const { adminUserData } = useAdminUserData()

	async function handleLogout() {
		setError('')
		try {
			await logout()
			navigate('/login')
		} catch {
			setError('Failed to log out')
			alert(error)
		}
	}

	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const listData = []
	if (adminUserData.isDashboardAccess) {
		listData.push(
			{
				id: 'dashboard',
				name: 'Dashboard',
				path: '/dashboard',
				icon: DashboardIcon,
			}
		)
	}
	if (adminUserData.isStatsAccess) {
		listData.push({
			id: 'stats',
			name: 'Stats',
			path: '/stats',
			icon: AssessmentIcon,
		})
	}
	if (adminUserData.isInternalUser && !adminUserData.isAgent) {
		listData.push({
			id: 'services',
			name: 'Services',
			path: '/services',
			icon: MiscellaneousServicesIcon,
		})
	}
	if (adminUserData.isUsersAccess) {
		listData.push({
			id: 'users',
			name: 'Users',
			path: '/users',
			icon: PersonSearchIcon,
		})
	}
	if (adminUserData.isUserRequestsAccess) {
		listData.push({
			id: 'UserRequests',
			name: 'User Requests',
			path: '/user-requests',
			icon: LabelIcon,
		})
	}
	if (adminUserData.isManageUsersAccess) {
		listData.push({
			id: 'manage-users',
			name: 'Manage',
			path: '/manage-users',
			icon: ManageAccountsIcon,
		})
	}

	return (
		<Box sx={classes.root}>
			<CssBaseline />
			<AppBar
				style={{ background: Colors.neutrals.lead200, boxShadow: 'none' }}
				position="fixed"
				sx={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" sx={classes.title}>Deciml Admin</Typography>
					<IconButton
						sx={classes.menuButton}
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
						size="large">
						<img src={currentUser?.photoURL ?? logo} alt="Logo" style={{
							height: 50,
							paddingLeft: 5,
							borderRadius: 10,
						}} />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={open}
						onClose={handleClose}>
						<MenuItem >{currentUser.displayName}</MenuItem>
						<MenuItem >{`v${process.env.REACT_APP_VERSION}`}</MenuItem>
						<MenuItem >{currentUser.email}</MenuItem>
						<MenuItem onClick={handleLogout}>Log Out</MenuItem>
					</Menu>
					<HeadwayWidget account="ypr3g7" badgePosition={'top-right'}>
						<NotificationsIcon />
					</HeadwayWidget>
				</Toolbar>
			</AppBar>
			<Drawer
				onMouseEnter={() => { openDrawer() }}
				onMouseLeave={() => { closeDrawer() }}
				variant="permanent"
				sx={(permanentDrawerOpen || drawerOpen) ? classes.drawerOpen : classes.drawerClose}
				PaperProps={{
					sx: (permanentDrawerOpen || drawerOpen) ? classes.drawerOpen : classes.drawerClose
				}
				}
			>
				<Box sx={classes.toolbar} />
				<Divider />
				<List >
					{listData.map((item) => (
						<ListItem button
							key={item.id}
							onClick={() => { navigate(item.path) }}
							sx={classes.listPadding}>
							<ListItemIcon ><item.icon style={{ color: item.id == props.id ? Colors.primary.lime400 : 'fff' }} /></ListItemIcon>
							<ListItemText primary={item.name} style={{ color: item.id == props.id ? Colors.primary.lime400 : 'fff' }} />
						</ListItem>
					))}
				</List>
			</Drawer>
			<main style={{}}>
				<Box sx={{
					flexGrow: 1,
					padding: theme.spacing(3),
				}}>
					<Box sx={classes.toolbar} />
					{props.children}
				</Box>
			</main>
		</Box >
	)
}
