import { createTheme } from '@mui/material/styles'
import { Colors } from 'models/Colors'
// import "@mui/styles"
// declare module '@mui/styles' {
// 	interface DefaultTheme extends Theme { }
// }


const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: Colors.primary.lime400,
			dark: Colors.primary.lime400,
		},
		background: {
			default: Colors.neutrals.lead200,
		},
	}
})
export default theme