import { CircularProgress } from '@mui/material'
import { Colors } from 'models/Colors'
import React, { memo } from 'react'
import { Area, AreaChart, Tooltip, XAxis } from 'recharts'

const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any }) => {
	const active = props?.active ?? false
	if (active) {
		const payload = props?.payload[0]?.payload
		const dataKey = props?.payload[0]?.dataKey
		const viewString = payload[dataKey + 'String'] ?? ''
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.neutrals.lead200 }} >
				<p className="label">{`${payload?.showDate}`}</p>
				<p className="intro">{`${viewString}`}</p>
			</div>
		)
	}
	return null
}

function StatsAreaGraph(props: { data: any[], color: string, dataKey: string }) {
	const data = props.data
	const color = props.color
	const dataKey = props.dataKey
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			{(() => {
				if (data.length === 0) {
					return (
						<CircularProgress />
					)
				} else {
					return (
						<AreaChart
							width={500}
							height={260}
							data={data}>
							<Area
								type="monotone"
								dataKey={dataKey}
								stroke={color}
								fill={color} />
							<XAxis dataKey="date" />
							<Tooltip content={<CustomTooltipContent />} cursor={{ fill: Colors.tooltipOverlay }} />
						</AreaChart>
					)
				}
			})()
			}
		</div>
	)
}
export default memo(StatsAreaGraph)