import './styles.css'

import {
	Card,
	CardActionArea,
	CardHeader,
	CircularProgress,
	Container,
	Grid,
	SxProps
} from '@mui/material'
import Typography from '@mui/material/Typography'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import CamsController from 'services/controllers/CamsController'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		borderRadius: 8,
	},
	cardAction: {
		display: 'block',
		textAlign: 'initial',
		width: '100',
	}

})

const Title = (props: { text: string }) => {
	return (
		<Grid item xs={12} sm={12}>
			<Typography variant="h6">{props.text}</Typography>
		</Grid>
	)
}

const DataCard = (props: {
	title: string,
	classes: any,
	color?: string,
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
	actions?: any
}) => {
	const className = props.classes.card
	const color = props.color ?? Colors.primary.lime400
	return (
		<Grid item xs={12} sm={3}>
			<Card
				elevation={0}
				sx={className}
				style={{ backgroundColor: color + '1A', width: '100' }}
			>
				<CardActionArea onClick={props.onClick}>
					<CardHeader
						title={
							<Typography variant="body1" color={Colors.type.type100}>
								{props.title}
							</Typography>
						}
						action={<>{props.actions}</>}
					/>
				</CardActionArea>
			</Card>
		</Grid >
	)
}

export default function Services() {
	const classes = useStyles()
	const navigate = useNavigate()
	const wbr1InputFileRef = useRef<any>(null)
	const [wbr1Loading, setWbr1Loading] = useState(false)
	const wbr2InputFileRef = useRef<any>(null)
	const [wbr2Loading, setWbr2Loading] = useState(false)
	const camsController = new CamsController()

	return (
		<Layout id={'services'}>
			<Grid container spacing={3}>
				<Title text={'User'} />
				<DataCard
					classes={classes}
					title={'User Search'}
					onClick={() => navigate('/users')}
				/>
				<DataCard
					classes={classes}
					title={'Transactions Search'}
					onClick={() => navigate('/transactions')}
				/>
				{/* <DataCard
					classes={classes}
					title={'Lendbox Statement'}
					onClick={() => navigate('/statement-generation')}
				/> */}
				<Title text={'CAMS'} />
				<input
					type='file'
					style={{ display: 'none' }}
					ref={wbr1InputFileRef}
					onChange={async (event) => {
						const fileObj = event.target.files && event.target.files[0]
						if (!fileObj) {
							return
						}
						setWbr1Loading(true)
						await camsController.handleWbr1(fileObj)
						setWbr1Loading(false)
						event.target.value = ''
					}}
				/>
				<DataCard
					classes={classes}
					title={'WBR1'}
					onClick={(_event) => {
						if (wbr1InputFileRef != null && !wbr1Loading) {
							try {
								wbr1InputFileRef.current?.click()
							} catch (e) {
								// console.log(e)
							}
						}
					}}
					actions={
						wbr1Loading ?
							<CircularProgress size={'25px'} /> : <Container />
					}
				/>
				<input
					type='file'
					style={{ display: 'none' }}
					ref={wbr2InputFileRef}
					onChange={async (event) => {
						const fileObj = event.target.files && event.target.files[0]
						if (!fileObj) {
							return
						}
						setWbr2Loading(true)
						await camsController.handleWbr2(fileObj)
						setWbr2Loading(false)
						event.target.value = ''
					}}
				/>
				<DataCard
					classes={classes}
					title={'WBR2'}
					onClick={(_event) => {
						if (wbr2InputFileRef != null && !wbr2Loading) {
							try {
								wbr2InputFileRef.current?.click()
							} catch (e) {
								// console.log(e)
							}
						}
					}}
					actions={
						wbr2Loading ?
							<CircularProgress size={'25px'} /> : <Container />
					}
				/>
			</Grid>
		</Layout>
	)
}
