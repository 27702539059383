import React, { useContext, useState } from 'react'

const LayoutContext = React.createContext<any>(null)

export function useLayout() {
	return useContext(LayoutContext)
}

export function LayoutProvider(props: { children: any }) {

	const [permanentDrawerOpen, setpermanentDrawerOpen] = useState(false)
	const [drawerOpen, setDrawerOpen] = useState(false)

	function handlePermenantDrawer() {
		setpermanentDrawerOpen(!permanentDrawerOpen)
	}
	function openDrawer() {
		setDrawerOpen(true)
	}
	function closeDrawer() {
		setDrawerOpen(false)
	}

	const value = {
		drawerOpen, permanentDrawerOpen, handlePermenantDrawer, openDrawer, closeDrawer
	}

	return (
		<LayoutContext.Provider value={value}>
			{props.children}
		</LayoutContext.Provider>
	)
}