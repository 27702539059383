import { Cancel as CancelIcon } from '@mui/icons-material'
import { Button, Card, CssBaseline, IconButton, SxProps, TextField, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import Center from 'components/Center'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import TransactionController, { TransactionDetails } from 'services/controllers/TransactionController'

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead200,
	},
})


const CustomToolbar = (props: any) => {
	const { cancelFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={cancelFunction} size="large">
				<CancelIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}



export default function TransactionsSearch() {
	const [TransactionsList, setTransactionsList] = useState<TransactionDetails[]>([])
	const [loading] = useState<boolean>(false)
	const classes = useStyles()
	const navigate = useNavigate()

	const columns: GridColDef[] = [
		{ field: 'custRefNo', headerName: 'Customer Reference ID', width: 125 },
	]

	const handleSubmit = useCallback(
		async function (event: any) {
			event.preventDefault()
			const { yesbankcustRefNo } = event.target.elements

			const transactionsController = new TransactionController()

			const data = await transactionsController.getTransactionsForCustRefNo(yesbankcustRefNo.value)


			if (data === null) {
				alert('No Transactions Found')
				navigate('/transactions')
			}
			else {
				setTransactionsList([data])
				navigate(`/transactions/${yesbankcustRefNo.value}`)
			}
		}
		, [navigate]
	)


	return (
		<Layout id={'services'}>
			<div
				style={{
					height: 'calc(100vh - 170px)',
					width: '100%'
				}}>
				{TransactionsList.length === 0 && !loading ?

					<div style={{
						backgroundColor: Colors.neutrals.lead200,
						width: '100vw',
						height: '100vh',
						textAlign: 'center',
					}}>
						<CssBaseline />
						<Center>
							<Card elevation={0} sx={classes.card} style={{
								width: 400,
								padding: 20
							}} >
								<Typography variant="h5">
									Transactions Search
								</Typography>
								<form onSubmit={handleSubmit}>
									<div style={{
										padding: 10
									}} />
									<TextField id="yesbankcustRefNo" type="s" label="Customer Reference ID" fullWidth />
									<div style={{
										padding: 10
									}} />
									<Button disabled={loading} type="submit" fullWidth={true}>Submit</Button >
								</form>
							</Card>
						</Center>
					</div>
					:
					<DataGrid
						components={{
							Toolbar: CustomToolbar,
						}}
						componentsProps={{
							toolbar: {
								cancelFunction: () => {
									setTransactionsList([])
								},
							}
						}}
						rows={TransactionsList}
						columns={columns}
						loading={loading} />
				}
			</div>
		</Layout>
	)

}