import { FileCopy as FileCopyIcon } from '@mui/icons-material'
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Icon, SxProps } from '@mui/material'
import { memo } from 'react'
import copyToClipboard from 'services/util/copyToClipboard'

import StatsAreaGraph from './mini-components/StatsAreaGraph'

function StatsAreaGraphCard(props: { data: any[], dataKey?: string, cardClass: SxProps, cardHeaderClass: SxProps, color: string, title: string, subheader: string, copyValue?: string, actions?: any }) {
	const cardClass = props.cardClass
	const cardHeaderClass = props.cardHeaderClass
	const color = props.color
	const title = props.title
	const subheader = props.subheader
	const data = props.data
	const dataKey = props.dataKey ?? 'total'
	const copyValue = props.copyValue ?? title
	const actions = props.actions ?? []

	return (
		<>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} sx={cardClass}>
					<CardHeader
						sx={cardHeaderClass}
						title={title}
						subheader={subheader}
						action={
							< ButtonGroup variant="text" size="large" >
								{actions}
								<Button
									sx={cardHeaderClass}
									onClick={() => copyToClipboard(copyValue)}>
									<Icon>
										<FileCopyIcon />
									</Icon>
								</Button>
							</ButtonGroup>
						}
					/>
					<CardContent>
						<StatsAreaGraph color={color} data={data} dataKey={dataKey} />
					</CardContent></Card>
			</Grid>
		</>
	)
}

export default memo(StatsAreaGraphCard)
