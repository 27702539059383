import {
	ArrowBackIos as ArrowBackIosIcon,
	Refresh as RefreshIcon,
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
	DataGrid,
	GridColDef,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import PortfolioController, {
	PortfolioTransaction,
} from 'services/controllers/PortfolioController'

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary.lime400 }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}
export default function Portfolio(props: {
	goBackFunction: any,
	month: string,
	fundType: string,
}) {
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const month = props.month
	const fundType = props.fundType ?? 'lendbox'
	const [portfolioList, setPortfolioList] = useState<PortfolioTransaction[]>(
		[]
	)
	const [loading, setLoading] = useState<boolean>(true)
	const portfolioController = new PortfolioController()

	useEffect(() => {
		const fetchData = () => {
			getPortfolio.current()
		}
		return fetchData()
	}, [])

	function columns() {
		const data: GridColDef[] = []
		// if (fundType === 'lendbox') {
		data.push({
			field: 'refresh',
			headerName: 'Refresh',
			width: 80,
			renderCell: (params: any) => {
				const onClick = async () => {
					if (fundType.startsWith('lendbox')) {
						await portfolioController.refreshLendboxTransaction(
							params.row.transactionId
						)
					} else if (!['pending_investment', 'error', 'completed'].includes(params.row.status)) {
						await portfolioController.refreshJuspayTransaction(
							params.row.transactionId
						)
					}
					// window.open(`/users/${params.transactionId}`, '_blank')
				}
				return (
					<IconButton
						onClick={onClick}
						title={'Refresh Lendbox Balances'}
						size="large"
					>
						<RefreshIcon />
					</IconButton>
				)
			},
		})
		// }
		data.push(...[
			{ field: 'timestamp', headerName: 'Timestamp', width: 200 },
			{ field: 'id', headerName: 'Timestamp', width: 150 },
			{ field: 'transactionId', headerName: 'Transaction Id', width: 400 },
			{ field: 'amount', headerName: 'Amount', width: 150 },
			{ field: (fundType.startsWith('lendbox')) ? 'custRefNo' : 'referenceId', headerName: 'Reference No', width: 150 },
			{ field: 'uninvestedAmount', headerName: 'Uninvested Amount', width: 150 },
			{ field: 'status', headerName: 'Status', width: 150 },
			{ field: 'type', headerName: 'Type', width: 150 },
			{ field: 'errorCode', headerName: 'Error Code', width: 150 },
			{ field: 'responseCode', headerName: 'Response Code', width: 150 },
			{ field: 'remarks', headerName: 'Remarks', width: 500 },
			{ field: 'ip', headerName: 'IP', width: 150 },
			{ field: 'utr', headerName: 'UTR', width: 150 },
			{ field: 'orderId', headerName: 'Order Id', width: 150 },
		])
		if (fundType != 'lendbox') {
			data.push(...[
				{ field: 'folioNumber', headerName: 'Folio Number', width: 150 },
				{ field: 'units', headerName: 'Units', width: 150 },
				{ field: 'nav', headerName: 'NAV', width: 150 },
			])
		}
		return data
	}
	const getPortfolio = useRef(() => {
		// 
	})

	getPortfolio.current = () => {
		if (uid != null && uid != undefined) {
			portfolioController.getPortfolio(uid, fundType, month, (data) => {
				setPortfolioList(data)
				if (loading) setLoading(false)
			})
		}
	}

	return (
		<div
			style={{
				height: 'calc(100vh - 232px)',
				width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
			}}
		>
			<DataGrid
				components={{
					Toolbar: CustomToolbar,
				}}
				componentsProps={{
					toolbar: {
						goBackFunction: props.goBackFunction,
					},
				}}

				rows={portfolioList}

				columns={columns()}
				loading={loading}
			/>
		</div>
	)
}
