export const Colors = {
	'tooltipOverlay': '#0C1E3433',

	type: {
		type100: '#F9F8F6',
		type90: '#F9F8F6E6',
		type80: '#F9F8F6CC',
		type70: '#F9F8F6B3',
		type60: '#F9F8F699',
		type50: '#F9F8F680',
		type40: '#F9F8F666',
		type30: '#F9F8F64D',
		type20: '#F9F8F633',
		type10: '#F9F8F61A',
	},
	neutrals: {
		cloud25: '#FFFFFF',
		cloud50: '#CECDCD',
		cloud75: '#AFB0B6',
		cloud100: '#87888C',
		cloud200: '#6D6E73',
		cloud300: '#53565C',
		cloud400: '#3B3E45',
		lead25: '#2C2E34',
		lead50: '#222428',
		lead75: '#1D1F24',
		lead100: '#1A1B1F',
		lead200: '#141518',
		lead300: '#121214',
		lead400: '#FF000000',
	},
	system: {
		negativeRed400: '#FF6868',
		positiveGreen400: '#21D664',
		warning400: '#FDE049',
		info400: '#4CB4EF',
	},
	secondary: {
		yellow100: '#FFF9EB',
		yellow200: '#FFF1B5',
		yellow300: '#FFDD8A',
		yellow400: '#F3DC62',
		yellow500: '#E0BD41',

		orange100: '#FFF2ED',
		orange200: '#FCCEBB',
		orange300: '#FF9C64',
		orange400: '#F96D3E',
		orange500: '#F06334',

		mint100: '#EDFFF1',
		mint200: '#D6FFDF',
		mint300: '#BAF8C9',
		mint400: '#ABEDBB',
		mint500: '#97C9A3',

		red100: '#FFF2F2',
		red200: '#FFC7C7',
		red300: '#FF908F',
		red400: '#FF5554',
		red500: '#D53E3D',

		pink100: '#FFF1F9',
		pink200: '#FFC9E9',
		pink300: '#FF98D5',
		pink400: '#F36EBD',
		pink500: '#E650A9',

		purple100: '#F9F5FF',
		purple200: '#EBDEFF',
		purple300: '#CFB1FF',
		purple400: '#B98EFF',
		purple500: '#A066FF',
	},
	primary: {
		lime100: '#F9FFEE',
		lime200: '#EDFFC7',
		lime300: '#E4FFAB',
		lime400: '#C2F447',
		lime500: '#A9C76B',
		lime600: '#768A4C',
		lime700: '#465130',
		lime800: '#1C1E15',

		violet100: '#D9DCF7',
		violet200: '#BCC1F7',
		violet300: '#A3ACFF',
		violet400: '#838EF7',
		violet500: '#737EF1',
		violet600: '#4B54A9',
		violet700: '#323872',
		violet800: '#1B1D2E',
	}
}