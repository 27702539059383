import { CircularProgress, Typography } from '@mui/material'
import Center from 'components/Center'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import BalanceController, { UserBalance, UserBalanceDashboardFundData, UserBalancePortfolioFundData, UserBalancePortfolioFundDataValueData } from 'services/controllers/BalanceController'


export default function BalanceView() {
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const [loading, setLoading] = useState<boolean>(true)
	const [data, setData] = useState<UserBalance | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			if (uid != null && uid != undefined) {
				const data = await new BalanceController().getBalance(uid)
				setData(data)
				setLoading(false)
			}
		}
		fetchData()
	}, [])

	return (<div
		style={{
			height: 'calc(100vh - 160px)',
			width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
		}}>
		{loading ? <>
			<Center>
				<CircularProgress />
			</Center> </> :
			<>
				<div style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
					<ul>
						<li>
							<Typography variant='h4'>
								Dashboard
							</Typography>
							<ul>
								{
									data?.dashboard?.map(
										(item: UserBalanceDashboardFundData) => (
											<li>
												<Typography variant='h5' paddingTop={2}>
													{item.fundName}
												</Typography>
												<ul>
													<li>
														<Typography variant='h6'>
															Title: {item.title}
														</Typography>
													</li>
													<li>
														<Typography variant='h6'>
															Amount: {item.amount.toFixed(2)}
														</Typography>
													</li>
													<li>
														<Typography variant='h6'>
															{'Interest Rate: '}
															<span style={item.interestRateColor ? { color: item.interestRateColor } : undefined}>
																{item.interestRateText}
															</span>
														</Typography>
													</li>
												</ul>
											</li>
										)
									)
								}
							</ul>
						</li>
						<li>
							<Typography variant='h4' paddingTop={4}>
								Portfolio
							</Typography>
							<ul>
								<li>
									<Typography variant='h5' paddingTop={2}>
										Total Invested: {data?.portfolio?.total?.invested?.toFixed(2)}
									</Typography>
								</li>
								<li>
									<Typography variant='h5' paddingTop={2}>
										Total Balance: {data?.portfolio?.total?.balance?.toFixed(2)}
									</Typography>
								</li>
								<li>
									<Typography variant='h5' paddingTop={2}>
										Uninvested Amount: {data?.portfolio?.total?.uninvestedAmount?.toFixed(2)}
									</Typography>
								</li>
								<li>
									<Typography variant='h5' paddingTop={2}>
										Total Mandates: {data?.portfolio?.total?.totalMandates ?? 0}
									</Typography>
								</li>
								<li>
									<Typography variant='h5' paddingTop={2}>
										Funds
									</Typography>
									<ul>
										{
											data?.portfolio?.funds.map((fund: UserBalancePortfolioFundData) =>
												<li>
													<Typography variant='h6' paddingTop={2}>
														{fund.fundName}
													</Typography>
													<ul>
														<li>
															<Typography variant='body1'>
																{'Background Color: '}
																<span style={fund.backgroundColor ? { backgroundColor: fund.backgroundColor } : undefined}>
																	{fund.backgroundColor}
																</span>
															</Typography>
														</li>
														<li>

															<Typography variant='body1'>
																Values:
															</Typography>
															<ul>
																{fund.values.map((value: UserBalancePortfolioFundDataValueData) =>
																	<li>
																		<Typography variant='body2'>
																			{value.title + ': '}
																			<span style={value.color ? { color: value.color } : undefined}>
																				{value.value}
																			</span>
																		</Typography>
																	</li>
																)}
															</ul>
														</li>
														<li>
															<Typography variant='body1'>
																Via Round-Ups: ₹{fund.roundup?.roundup} from {fund.roundup?.roundupSpends ?? 0} spends
															</Typography>
														</li>
														<li>
															<Typography variant='body1'>
																Via Daily Deposits: ₹{fund.dailyDeposit?.dailyDeposit} over {fund.dailyDeposit?.dailyDepositDays ?? 0} days
															</Typography>
														</li>
														<li>
															<Typography variant='body1'>
																Via Lump-Sum: ₹{fund?.directInvested?.directInvested ?? 0}
															</Typography>
														</li>
														{
															fund.uninvestedAmount.show &&
															<li>
																<Typography variant='body1'>
																	Uninvested Amount: ₹{fund.uninvestedAmount.uninvestedAmount}
																</Typography>
															</li>
														}
													</ul>
												</li>
											)
										}
									</ul>

								</li>
							</ul>
						</li>
						<li>
							<Typography variant='h4' paddingTop={4}>
								Withdrawal
							</Typography>
							<ul>
								{data?.withdrawal?.fundList?.map((fundType) => {
									const fundData = data?.withdrawal?.fundData?.[fundType]
									return <li>
										<Typography variant='h5' paddingTop={2}>
											Fund Name: {fundData?.fundName}
										</Typography>
										<ul>
											<li>
												<Typography variant='h6'>
													Balance: ₹{fundData?.balance}
												</Typography>
											</li>
											<li>
												<Typography variant='h6'>
													AvailableToWithdraw: ₹{fundData?.availableToWithdraw}
												</Typography>
											</li>
											{
												(fundData?.folioList ?? []).length > 0 &&
												<li>
													<Typography variant='h6'>
														Folios:
													</Typography>
													<ul>
														<li>
															{fundData?.folioList.map((folio) => {
																const folioData = fundData?.folioData?.[folio]
																return <li>
																	<Typography variant='body1'>
																		Folio: {folio}
																	</Typography>
																	<ul>
																		<li>
																			<Typography variant='body2'>
																				Balance: ₹{folioData?.balance}
																			</Typography>
																		</li>
																		<li>
																			<Typography variant='body2'>
																				AvailableToWithdraw: ₹{folioData?.availableToWithdraw}
																			</Typography>
																		</li>

																	</ul>
																</li>
															})}
														</li>
													</ul>
												</li>
											}
										</ul>
									</li>
								}
								)}
							</ul>

						</li>
					</ul>
				</div >
			</>
		}
	</div >
	)
}
