import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { db } from 'services/firebase'

export default class ManageUsersController {
	getUsers(observer: (data: any[]) => void) {
		onSnapshot(collection(db, 'adminUsers'), (snapshot: any) => {
			const data = snapshot.docs.map((doc: any) => {
				return { id: doc.id, ...doc.data() }
			})
			observer(data)
		})
	}

	async updateUserPermission(uid: string, permission: string, value: boolean) {
		await setDoc(doc(db, `adminUsers/${uid}`), { [permission]: value }, { merge: true })
	}
}