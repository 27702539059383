import { CircularProgress } from '@mui/material'
import { Colors } from 'models/Colors'
import React, { memo } from 'react'
import { Bar, BarChart, Tooltip, XAxis } from 'recharts'

const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any }) => {
	const active = props?.active
	if (active) {
		const payload = props?.payload[0]?.payload
		const dataKey = props?.payload[0]?.dataKey
		const viewString = payload[dataKey + 'String'] ?? ''
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.neutrals.lead200 }} >
				<p className="label">{`${payload?.label}`}</p>
				<p className="intro">{`${viewString}`}</p>
			</div>
		)
	}
	return null
}

function StatsBarGraphV2(props: { data: any[], color: string, dataKey: string }) {
	const data = props.data
	const color = props.color
	const dataKey = props.dataKey
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			{(() => {
				if (data.length === 0) {
					return (
						<CircularProgress />
					)
				} else {
					let hasData = false
					for (const i in data) {
						if ((data[i]?.[dataKey] ?? 0) !== 0)
							hasData = true
					}
					if (hasData) {
						return (
							<BarChart
								width={500}
								height={260}
								data={data}>
								<Bar
									dataKey={dataKey}
									fill={color}
									radius={25} />
								<XAxis dataKey="label" />
								<Tooltip content={<CustomTooltipContent />} cursor={{ fill: Colors.tooltipOverlay }} />
							</BarChart>
						)
					}
					else {
						return (
							<div>
								No Data
							</div>
						)
					}
				}
			})()
			}
		</div>
	)
}
export default memo(StatsBarGraphV2)