import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { getValue } from 'firebase/remote-config'
import { db, rc } from 'services/firebase'
import getMonth from 'services/util/getMonth'
import monthConvert from 'services/util/monthConvert'

import APIController from './APIController'

export interface TransactionMonth {
	id: string,
	monthCode: string,
	month: string,
}

export interface TransactionDetails {
	id: string,
	yesbankcustRefNo: string,
	UPITxnId: string,
	pspRefNo: string,
	transactionId: string,
}
export interface Transaction {
	id: string,
	amount: number,
	total: number,
	type: string,
	status: string,
	txnType: string,
	bankName: string,
	accountType: string,
	due: string,
}
export function getTransactionType(type: number | null | undefined, category: string | null | undefined, txnCategory: string | null | undefined, merchant: string | null | undefined, details: string | null | undefined): string {
	switch (type) {
		case 0: {
			switch (category) {
				case 'UNIFIED_PAYMENT':
					return 'UPI Transaction'
				case 'IMPS':
					return 'IMPS'
				default: {
					const transactionTypeClassificationString: string = getValue(rc, 'transactionTypeClassification').asString()
					const transactionTypeClassification: any = JSON.parse(transactionTypeClassificationString)
					let priority = Object.keys(transactionTypeClassification)
					priority.sort()
					priority = priority.reverse()
					let ix
					let p
					let px
					let j
					if (merchant != null) {
						for (p in priority) {
							px = priority[p]
							for (const i in Object.keys(transactionTypeClassification[px])) {
								ix = Object.keys(transactionTypeClassification[px])[i]
								for (j in transactionTypeClassification[px][ix]) {
									if (merchant
										?.toLowerCase()
										.includes(transactionTypeClassification[px][ix][j].toLowerCase()) ??
										false) return ix
								}
							}
						}
					}
					if (details != null)
						for (p in priority) {
							px = priority[p]
							for (const i in Object.keys(transactionTypeClassification[px])) {
								ix = Object.keys(transactionTypeClassification[px])[i]
								for (j in transactionTypeClassification[px][ix]) {
									if (details
										?.toLowerCase()
										.includes(transactionTypeClassification[px][ix][j].toLowerCase()) ??
										false) return ix
								}
							}
						}
					return 'Debit'
				}
			}
		}
		case 1:
			return 'Credit'
		case 2:
			return 'Withdrawal'
		case 3:
			return 'Daily Deposit'
		case 4:
			return 'One Time Investment'
		case 5:
			return 'Mandate Investment'
		case 6:
			return 'First Investment'
		case 7:
			return 'Goal One Time Investment'
		case 8:
			return 'Goal Withdrawal'
		case 9:
			return 'Switch'
		case 10:
			return details ?? 'Reward'
		case 11:
			return 'Reward Counter Transaction'
		default:
			return 'NA'
	}
}



export enum FundType {
	LENDBOX = 'lendbox',
	PLF_EHGPG = 'PLF-EHGPG',
	PLF_MCGPG = 'PLF-MCGPG'
}

export enum TransactionStatus {
	InProgress,
	Pending,
	PendingInvestment,
	Completed,
	Error,
	PendingTransaction //For withdrawal only
}

export enum TransactionType {
	Debit,
	Credit,
	Withdrawal,
	DailyDeposit,
	OneTimeInvestment,
	MandateInvestment,
	LumpSum,
	FirstInvestment,
	GoalOneTimeInvestment,
	GoalWithdrawal,
	Switch,
	Reward,
	RewardCounterTransaction,
	UninvestedAmount,
	NA
}

export interface TransactionData {
	cardType: 'mandate' | 'transaction'
	amount: number
	uninvestedAmount: number
	roundupAmount: number
	mandateErrorCode?: string
	notificationErrorCode?: string
	notificationResponseCode?: string
	mandateResponseCode?: string
	dailyDepositAmount: number
	transactions: TransactionData[]
	total?: number
	category?: string
	status?: TransactionStatus
	type?: TransactionType
	due: Date
	timestamp: Date
	fundType?: FundType
	merchant?: string
	txnCategory?: string
	details?: string
	title: string
	titleColored: boolean
	color: string
	statusText: string
}


export interface TransactionMonthAPIInterface {
	month: number,
	year: number,
}

export default class TransactionController {

	getTransactionMonths(uid: string, observer: (transactionMonths: TransactionMonth[]) => void) {
		onSnapshot(collection(db, `users/${uid}/transactions`), (snapshot: any) => {
			const transactionMonths: TransactionMonth[] = snapshot.docs.map((doc: any) => {
				const data = doc.data()
				data.id = doc.id
				data.monthCode = monthConvert(doc.id)
				data.month = getMonth(data.monthCode)
				return data
			})
			transactionMonths.sort((a: TransactionMonth, b: TransactionMonth) => {
				if (a.monthCode > b.monthCode) return -1
				if (a.monthCode < b.monthCode) return 1
				return 0
			})
			observer(transactionMonths)
		})
	}
	getTransactions(uid: string, month: string, observer: (transactions: Transaction[]) => void) {
		onSnapshot(doc(db, `users/${uid}/transactions/${month}`), (snapshot: any) => {
			const data: any = snapshot.data()
			const transactions: Transaction[] = []
			Object.keys(data).sort().reverse().forEach((key: string) => {
				transactions.push({ id: key, type: getTransactionType(data[key].type, data[key].category, data[key].txnCategory, data[key].merchant, data[key].details), amount: data[key].amount, total: data[key].total, status: data[key].status, txnType: data[key].txnType, bankName: data[key].bankName, accountType: data[key].accountType, due: data[key].due })
			})
			observer(transactions)
		})
	}


	async getTransactionsForCustRefNo(custRefNo: string) {
		let data: any = (await getDoc(doc(db, `yesbankCustRefNo/${custRefNo}`)))
		data = data.data()
		if (data === undefined) {
			return null
		}

		return data
	}

	async getTransactionData(uid: string, start: TransactionMonthAPIInterface, end: TransactionMonthAPIInterface): Promise<TransactionData[] | null> {
		const url = process.env.REACT_APP_API_URL + '/v1/admin/users/transactions'
		const transactions: TransactionData[] = (await APIController.post(url, { uid, start, end })).data.data.transactions as TransactionData[]
		return transactions
	}
}

