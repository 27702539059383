import { createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, User } from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth } from 'services/firebase'

const AuthContext = createContext<any>(null)

export function useAuth() {
	return useContext(AuthContext)
}

export function AuthProvider(props: { children: any }) {
	const [currentUser, setCurrentUser] = useState<User | null>()
	const [loading, setLoading] = useState<boolean>(true)

	async function signup(email: string, password: string) {
		await createUserWithEmailAndPassword(auth, email, password)
	}

	async function login(email: string, password: string) {
		await signInWithEmailAndPassword(auth, email, password)
	}
	async function signInWithGoogle() {
		const googleAuthProvider = new GoogleAuthProvider()
		await signInWithPopup(auth, googleAuthProvider)
	}

	async function logout() {
		await signOut(auth)
	}

	async function resetPassword(email: string) {
		await sendPasswordResetEmail(auth, email)
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setCurrentUser(user)
			setLoading(false)
		})
		return unsubscribe
	}, [])


	const value = {
		currentUser,
		login,
		signup,
		logout,
		resetPassword,
		signInWithGoogle,
	}

	return (
		<AuthContext.Provider value={value}>
			{!loading && props.children}
		</AuthContext.Provider>
	)
}