import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useLayout } from 'services/contexts/LayoutContext'
import PortfolioController, { PortfolioTransactionMonth } from 'services/controllers/PortfolioController'

export default function PortfolioMonth(props: { onClickView: any, fundType: string }) {
	const { drawerOpen, permanentDrawerOpen } = useLayout()
	const { uid } = useParams()
	const fundType = props.fundType
	const [portfolioMonthList, setPortfolioMonthList] = useState<PortfolioTransactionMonth[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const portfolioController = new PortfolioController()

	useEffect(() => {
		const fetchData = () => {
			getPortfolioMonths.current()
		}
		return fetchData()
	}, [props.fundType])

	const columns: GridColDef[] = [
		{ field: 'month', headerName: 'Month', width: 300 },
		{
			field: 'View',
			headerName: 'View',
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					props.onClickView(`${params.id}`)
				}
				return (
					<IconButton onClick={onClick} title={'Goals'} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				)
			}
		},
	]

	const getPortfolioMonths = useRef(() => {
		// 
	})

	getPortfolioMonths.current = () => {
		if (uid != null && uid != undefined) {
			portfolioController.getPortfolioMonths(uid, fundType, (data) => {
				setPortfolioMonthList(data)
				if (loading)
					setLoading(false)
			})
		}
	}
	return (
		<div
			style={{
				height: 'calc(100vh - 232px)',
				width: 'calc(100vw - ' + ((permanentDrawerOpen || drawerOpen) ? '250' : '85') + 'px)',
			}}>
			<DataGrid
				components={{
					Toolbar: GridToolbar
				}}
				rows={portfolioMonthList}
				columns={columns}
				loading={loading} />
		</div>
	)
}
