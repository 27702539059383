import { Refresh as RefreshIcon } from '@mui/icons-material'
import { Grid, IconButton, SxProps } from '@mui/material'
import { Dayjs } from 'dayjs'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import StatsController from 'services/controllers/StatsController'
import currencyFormat from 'services/util/currencyFormat'
import numberFormat from 'services/util/numberFormat'
import statsDataHandler from 'services/util/statsDataHandler'

import StatsAreaGraphCard from '../StatsAreaGraphCard'

const statsController = new StatsController()

const useStyles = (): { [key: string]: SxProps } => ({
	card: {
		backgroundColor: Colors.neutrals.lead100,
		height: 400,
		background: Colors.neutrals.lead100,
		borderRadius: 2
	},
	mainCard: {
		backgroundColor: Colors.neutrals.lead100,
		height: 90,
		borderRadius: 2
	},
	cardContent: {
		alignContent: 'center',
		textAlign: 'center'
	},
	gridList: {
		height: 360,
	},
	total: {
		color: Colors.secondary.mint400
	},
	kyc: {
		color: Colors.secondary.orange300
	},
	investments: {
		color: Colors.primary.violet400
	},
	userStats: {
		color: Colors.primary.lime400
	},
	rewards: {
		color: Colors.secondary.yellow400
	}
}
)

export default function RewardStats(props: { startDate: Dayjs, endDate: Dayjs }) {
	const classes = useStyles()
	const [rewardStats, setRewardStats] = useState<any>()
	const [rewardReferralGraphData, setRewardReferralGraphData] = useState<any[]>([])
	const [rewardReferralClaimGraphData, setRewardReferralClaimGraphData] = useState<any[]>([])
	const [rewardReferrerGraphData, setRewardReferrerGraphData] = useState<any[]>([])
	const [rewardReferrerClaimGraphData, setRewardReferrerClaimGraphData] = useState<any[]>([])
	const [rewardRewardGraphData, setRewardRewardGraphData] = useState<any[]>([])
	const [rewardRewardClaimGraphData, setRewardRewardClaimGraphData] = useState<any[]>([])
	const [rewardRewardClaimValueGraphData, setRewardRewardClaimValueGraphData] = useState<any[]>([])
	const [rewardRewardCounterGraphData, setRewardRewardCounterGraphData] = useState<any[]>([])

	useEffect(() => {
		const fetchData = () => {
			getRewardStatsData.current()
		}
		return fetchData()
	}, [])

	useEffect(() => {
		const fetchData = () => {
			getData()
		}
		return fetchData()
	}, [props.startDate, props.endDate])

	const getData = async () => {
		await getRewardReferralGraphData.current()
		await getRewardReferralClaimGraphData.current()
		await getRewardReferrerGraphData.current()
		await getRewardReferrerClaimGraphData.current()
		await getRewardRewardGraphData.current()
		await getRewardRewardClaimGraphData.current()
		await getRewardRewardClaimValueGraphData.current()
		await getRewardRewardCounterGraphData.current()
	}

	const getRewardStatsData = useRef(() => {
		// 
	})

	getRewardStatsData.current = () => {
		statsController.getRewardStatsData((data: any) => {
			setRewardStats(data)
		})
	}

	const getRewardReferralGraphData = useRef(() => {
		// 
	})

	getRewardReferralGraphData.current = async () => {
		const rewardReferralGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardReferralStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardReferralGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardReferralGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardReferralGraphData[i].total
			} else if (rewardReferralGraphData[i].total < minValue) {
				minValue = rewardReferralGraphData[i].total
			}
		}
		for (let i = 0; i < rewardReferralGraphData.length; i++) {
			if (rewardReferralGraphData[i].total === 0) {
				rewardReferralGraphData[i].totalString = 'No Users'
			}
			else {
				rewardReferralGraphData[i].totalString = `${numberFormat(rewardReferralGraphData[i].total)} User${rewardReferralGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardReferralGraphData[i].totalValue = rewardReferralGraphData[i].total
		}
		setRewardReferralGraphData(rewardReferralGraphData)
	}

	const getRewardReferralClaimGraphData = useRef(() => {
		// 
	})

	getRewardReferralClaimGraphData.current = async () => {
		const rewardReferralClaimGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardReferralClaimStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardReferralClaimGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardReferralClaimGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardReferralClaimGraphData[i].total
			} else if (rewardReferralClaimGraphData[i].total < minValue) {
				minValue = rewardReferralClaimGraphData[i].total

			}
		}
		for (let i = 0; i < rewardReferralClaimGraphData.length; i++) {
			if (rewardReferralClaimGraphData[i].total === 0) {
				rewardReferralClaimGraphData[i].totalString = 'No Users'
			}
			else {
				rewardReferralClaimGraphData[i].totalString = `${numberFormat(rewardReferralClaimGraphData[i].total)} User${rewardReferralClaimGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardReferralClaimGraphData[i].totalValue = rewardReferralClaimGraphData[i].total
		}
		setRewardReferralClaimGraphData(rewardReferralClaimGraphData)
	}

	const getRewardReferrerGraphData = useRef(() => {
		// 
	})

	getRewardReferrerGraphData.current = async () => {
		const rewardReferrerGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardReferrerStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardReferrerGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardReferrerGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardReferrerGraphData[i].total
			} else if (rewardReferrerGraphData[i].total < minValue) {
				minValue = rewardReferrerGraphData[i].total

			}
		}
		for (let i = 0; i < rewardReferrerGraphData.length; i++) {
			if (rewardReferrerGraphData[i].total === 0) {
				rewardReferrerGraphData[i].totalString = 'No Users'
			}
			else {
				rewardReferrerGraphData[i].totalString = `${numberFormat(rewardReferrerGraphData[i].total)} User${rewardReferrerGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardReferrerGraphData[i].totalValue = rewardReferrerGraphData[i].total
		}
		setRewardReferrerGraphData(rewardReferrerGraphData)
	}


	const getRewardReferrerClaimGraphData = useRef(() => {
		// 
	})

	getRewardReferrerClaimGraphData.current = async () => {
		const rewardReferrerClaimGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardReferrerClaimStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardReferrerClaimGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardReferrerClaimGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardReferrerClaimGraphData[i].total
			} else if (rewardReferrerClaimGraphData[i].total < minValue) {
				minValue = rewardReferrerClaimGraphData[i].total
			}
		}
		for (let i = 0; i < rewardReferrerClaimGraphData.length; i++) {
			if (rewardReferrerClaimGraphData[i].total === 0) {
				rewardReferrerClaimGraphData[i].totalString = 'No Users'
			}
			else {
				rewardReferrerClaimGraphData[i].totalString = `${numberFormat(rewardReferrerClaimGraphData[i].total)} User${rewardReferrerClaimGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardReferrerClaimGraphData[i].totalValue = rewardReferrerClaimGraphData[i].total
		}
		setRewardReferrerClaimGraphData(rewardReferrerClaimGraphData)
	}

	const getRewardRewardGraphData = useRef(() => {
		// 
	})

	getRewardRewardGraphData.current = async () => {
		const rewardRewardGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardRewardStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardRewardGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardRewardGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardRewardGraphData[i].total
			} else if (rewardRewardGraphData[i].total < minValue) {
				minValue = rewardRewardGraphData[i].total
			}
		}
		for (let i = 0; i < rewardRewardGraphData.length; i++) {
			if (rewardRewardGraphData[i].total === 0) {
				rewardRewardGraphData[i].totalString = currencyFormat(0)
			}
			else {
				rewardRewardGraphData[i].totalString = `${currencyFormat(rewardRewardGraphData[i]?.total ?? 0)}}`
			}
			rewardRewardGraphData[i].totalValue = rewardRewardGraphData[i].total
		}
		setRewardRewardGraphData(rewardRewardGraphData)
	}

	const getRewardRewardClaimGraphData = useRef(() => {
		// 
	})

	getRewardRewardClaimGraphData.current = async () => {
		const rewardRewardClaimGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardRewardClaimStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardRewardClaimGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardRewardClaimGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardRewardClaimGraphData[i].total
			} else if (rewardRewardClaimGraphData[i].total < minValue) {
				minValue = rewardRewardClaimGraphData[i].total
			}
		}
		for (let i = 0; i < rewardRewardClaimGraphData.length; i++) {
			if (rewardRewardClaimGraphData[i].total === 0) {
				rewardRewardClaimGraphData[i].totalString = 'No Users'
			}
			else {
				rewardRewardClaimGraphData[i].totalString = `${numberFormat(rewardRewardClaimGraphData[i].total)} User${rewardRewardClaimGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardRewardClaimGraphData[i].totalValue = rewardRewardClaimGraphData[i].total
		}
		setRewardRewardClaimGraphData(rewardRewardClaimGraphData)
	}

	const getRewardRewardClaimValueGraphData = useRef(() => {
		// 
	})

	getRewardRewardClaimValueGraphData.current = async () => {
		const rewardRewardClaimValueGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardRewardClaimValueStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardRewardClaimValueGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardRewardClaimValueGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardRewardClaimValueGraphData[i].total
			} else if (rewardRewardClaimValueGraphData[i].total < minValue) {
				minValue = rewardRewardClaimValueGraphData[i].total

			}
		}
		for (let i = 0; i < rewardRewardClaimValueGraphData.length; i++) {
			if (rewardRewardClaimValueGraphData[i].total === 0) {
				rewardRewardClaimValueGraphData[i].totalString = currencyFormat(0)
			}
			else {
				rewardRewardClaimValueGraphData[i].totalString = `${currencyFormat(rewardRewardClaimValueGraphData[i].total)}}`
			}
			rewardRewardClaimValueGraphData[i].totalValue = rewardRewardClaimValueGraphData[i].total
		}
		setRewardRewardClaimValueGraphData(rewardRewardClaimValueGraphData)
	}

	const getRewardRewardCounterGraphData = useRef(() => {
		// 
	})

	getRewardRewardCounterGraphData.current = async () => {
		const rewardRewardCounterGraphData: any[] = []
		await statsDataHandler(
			props.startDate.toDate(),
			props.endDate.toDate(),
			statsController.getRewardRewardCounterStatsData, (data: any, date: Date) => {
				const day = String(date.getDate())
				const month = String(date.getMonth() + 1)
				const year = String(date.getFullYear())
				rewardRewardCounterGraphData.push({
					date: day + '/' + month + '/' + year,
					showDate: day + '/' + month + '/' + year,
					total: (data[day]?.total) ?? 0,
				})
			})
		let minValue = null
		for (let i = 0; i < rewardRewardCounterGraphData.length; i++) {
			if (i === 0) {
				minValue = rewardRewardCounterGraphData[i].total
			} else if (rewardRewardCounterGraphData[i].total < minValue) {
				minValue = rewardRewardCounterGraphData[i].total
			}
		}
		for (let i = 0; i < rewardRewardCounterGraphData.length; i++) {
			if (rewardRewardCounterGraphData[i].total === 0) {
				rewardRewardCounterGraphData[i].totalString = 'No Users'
			}
			else {
				rewardRewardCounterGraphData[i].totalString = `${numberFormat(rewardRewardCounterGraphData[i].total)} User${rewardRewardCounterGraphData[i].total > 1 ? 's' : ''}`
			}
			rewardRewardCounterGraphData[i].totalValue = rewardRewardCounterGraphData[i].total
		}
		setRewardRewardCounterGraphData(rewardRewardCounterGraphData)
	}

	return (<>

		<Grid item xs={12} sm={11} direction="row-reverse">
		</Grid>
		<Grid item xs={12} sm={1} direction="row-reverse">
			<IconButton onClick={getData} size="large">
				<RefreshIcon />
			</IconButton>
		</Grid>
		{/* <Grid item xs={12} sm={12}>
			<Typography variant="h4" component="h4" gutterBottom >
				Reward Stats
			</Typography>
		</Grid> */}
		<StatsAreaGraphCard
			data={rewardReferralGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.referral)}`}
			subheader="Total Referrals" />
		<StatsAreaGraphCard
			data={rewardReferralClaimGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.referralClaim)}`}
			subheader="Total claimed referrals" />
		<StatsAreaGraphCard
			data={rewardReferrerGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.referrer)}`}
			subheader="Users who have referred" />
		<StatsAreaGraphCard
			data={rewardReferrerClaimGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.referrerClaim)}`}
			subheader="Users who have claimed referrals" />
		<StatsAreaGraphCard
			data={rewardRewardCounterGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.rewardCounter)}`}
			subheader="Reward Counter completed" />
		<StatsAreaGraphCard
			data={rewardRewardGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${currencyFormat(rewardStats?.reward ?? 0)}`}
			subheader="Reward"
			copyValue={(rewardStats?.reward ?? 0).toFixed(2)} />
		<StatsAreaGraphCard
			data={rewardRewardClaimGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${numberFormat(rewardStats?.rewardClaim)}`}
			subheader="Reward Claims" />
		<StatsAreaGraphCard
			data={rewardRewardClaimValueGraphData}
			cardClass={classes.card}
			cardHeaderClass={classes.rewards}
			color={Colors.secondary.yellow400}
			title={`${currencyFormat(rewardStats?.rewardClaimValue ?? 0)}`}
			subheader="Reward Claimed"
			copyValue={(rewardStats?.rewardClaimValue ?? 0).toFixed(2)} />
	</>)
}